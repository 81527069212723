.sanofi_redbox {
	display: flex;
	flex-direction: row;
	padding: 1.5em 0 2em;
	text-align: center;
	border-radius: 10px;
}

.sanofi_redbox_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 40%;

	@include bp($point_5) {
		width: 100%;
	}
}

.sanofi_redbox_title {
	background-color: #e76e4e;
	color: white;
	width: 100%;
	height: 5rem;
	font-size: 1.3em;
	padding: .2em 1.2em;

	&.border_mod_left {
		border-top-left-radius: 10px;
	}
	&.border_mod_right {
		border-top-right-radius: 10px;
	}

	@include bp($point_4) {
		height: 5rem;
	}
	@include bp($point_6) {
		height: 6rem;
	}
	@include bp($point_7) {
		height: 8rem;
	}
}

.sanofi_redbox_descr {
	width: 100%;
}

.safoni_redbox_descr {
	background-color: #fdf3f5;
	color: #e66e4e;
	width: 100%;
	font-size: 1.2em;
	padding: 0.1em 2.5em;

	&.border_mod_left {
		border-bottom-left-radius: 10px;
	}
	&.border_mod_right {
		border-bottom-right-radius: 10px;
	}
	&.middle {
		font-weight: bold;
	}
}

.sanofi_btn_container {
	display: flex;
	flex-direction: row;

	@include bp($point_5) {
		flex-direction: column;
		align-items: center;
	}
}

.safoni_button_descr {
	color: #848484;
	font-size: 0.8em;
	@include bp($point_4) {
		margin-top: -0.6em;
	}
}

.sanofi_btn {
	padding-right: 2em;

	@include bp($point_5) {
		margin-bottom: 2em;
		padding-right: 0em;
	}
}

.statsbox_section {
	display: flex;
	padding: 1.5em 1em 3em 1em;

	@include bp($point_5) {
		flex-direction: column;
		align-items: center;
		padding: 1.5em 0em 3em 0em;
	}
	// @include bp($point_5) {
	// 	flex-direction: column;
	// 	margin-left: 25%;
	// }
	// @include bp($point_6) {
	// 	margin-left: 15%;
	// }
}

.statsbox_in {
	display: flex;
	padding-right: 1.5em;
	min-width: fit-content;

	&:not(:last-child) {
		border-right: 1px solid #d0d0d0;
		@include bp($point_4) {
			border-right: none;
		}
	}
	&:not(:first-child) {
		padding-left: 1.5em;
		@include bp($point_5) {
			padding-top: 1.5em;
		}
	}
	&:first-child {
		@include bp($point_4) {
			padding-left: 0em;
			margin-right: 6em;
		}
	}

	@include bp($point_5) {
		padding: 0em;
	}
}

.statsbox_text {
	display: flex;
	flex-direction: column;
}

.statsbox_image_container {
	width: 4.2em;
	margin-right: 1.5em;
}

.statsbox_image {
	width: 100%;
	margin-top: 1.3em;
}

.statsbox_stat {
	font-weight: 350;
	font-size: 5em;
	position: relative;

	@include bp($point_5) {
		text-align: left;
		padding-left: 1rem;
	}
}

.statsbox_descr {
	font-size: 2em;

	@include bp($point_4) {
		text-align: left;
	}
}

.statsbox_disclaimer {
	&::before {
		position: absolute;
		top: 0.1em;
		left: 0.3em;
		content: "*";
		font-size: 0.5em;
	}
}

.sanofi_disclaimer {
	color: #848484;
	font-size: 1.3em;
	padding: 2em 1em 0;
}
