//---------------------------------------------sections
.section {
	position: relative;

	width: 100%;
	padding: 4em 0;

	font-size: 1rem;

	z-index: 1;

	&.border_mod {
		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;

			width: 92em;
			height: 1px;
			margin-left: -46em;

			background-color: #365985;
		}
	}

	&.hero_mod {
		@extend %global_flex_block_row_nowrap_flex-start_center;
		height: 80vh;
		padding-top: 12em;
		padding-bottom: 4em;
		@include bp($point_3) {
			padding: 11em 0 6em;
		}
		@media (max-height: 850px) {
			height: auto;
		}
	}

	&.download_mod {
		padding-top: 7.8em;
		padding-bottom: 11em;
	}

	&.hero_title_mod {
		padding: 13.2em 0 5.4em;
		@include bp($point_3) {
			padding: 8.2em 0 3.2em;
		}
	}

	&.sanofi_hero_title_mod {
		padding: 13.2em 0 5.4em;
		background: url('../images/sanofi_bg.jpg') no-repeat center center;
		background-size: cover;
		@include bp($point_3) {
			padding: 8.2em 0 3.2em;
		}
	}

	&.sanofi_hero_title_mod_v2 {
		padding: 2.2em 0em 10.4em 4em;
		background: url('../images/sanofi_bg.jpg') no-repeat center center;
		background-size: cover;
		@include bp($point_3) {
			padding: 4.2em 0 3.2em;
		}
	}

	&.about_line_mod {

		@include bp($point_4, min) {
			padding-bottom: 15em;
		}

		@include bp($point_4 - 1) {
			padding-bottom: 8em;
		}

	}

	&.award_mod {
		background-color: $gray_2;
	}

	&.diseases_mod {
		height: 22.8em;

		@include bp($point_3) {
			height: auto;
		}
	}

	&.video_mod {

		@include bp($point_2, min) {
			height: 36.4em;
		}

		@include bp($point_3) {
			min-height: 36.4em;
		}

	}

	&.bg_mod {
		background-color: $white;
	}

	&.bg_v1_mod {
		background-color: $gray_2;
	}

	&.bg_v2_mod {
		background-color: $white_4;
	}

	&.bg_v3_mod {
		background-color: $gray_5;
	}

	&.info_entry_mod {
		@extend %global_flex_block_column_nowrap_flex-end;

		// height: 36.4em;
		padding-top: 0;
		padding-bottom: 0;
		overflow: hidden;
		@include bp($point_3) {
			padding-top: 4em;
		}
	}

	&.bg_accent_gradient_mod {
		background: $accent_gradient;
	}

	&.bg_accent_none_mod {
		background: transparent;
	}

	&.bg_blue_mod {
		background-image: linear-gradient(11deg, #0071be 0%, $accent_color 100%);
	}

	&.bg_blue_mod_v2 {
		background: #0071be;
	}

	&.banner_mod {
		@extend %global_flex_block_column_wrap_center_center;

		height: 36.4em;

		@include bp($point_3) {
			height: auto;
		}

	}

	&.center_level_mod {
		@extend %global_flex_block_row_wrap_flex-start_center;
	}

	&.about_healing_music_mod {
		background-color: $white_4;
	}

	&.no_offset_top {
		padding-top: 0;
	}

	&.healing_properties_mod {
		padding-bottom: 4.4em;

		&.color_mod {
			background-color: white;
		}
	}

	&.no_offset_bottom {
		padding-bottom: 0;
	}

	&.center_mod {
		display: flex;
		align-items: center;
	}

	&.map_mod {
		min-height: 46.8vw;

		@include bp($point_4) {
			min-height: 52vw;
		}

		@include bp($point_4 - 1) {
			min-height: 55vw;
		}
	}
}


.section_in {
	width: 100%;
	max-width: $page_width;
	padding: 0 $gl_indent;
	margin: 0 auto;

	&.info_entry_mod {
		overflow: hidden;
	}

	&.banner_mod {
		@extend %global_flex_block_column_nowrap_flex-start_center;
		text-align: center;
	}

	&.full_mod {
		max-width: none;
	}

	&.v2_mod {
		max-width: 98rem;
	}

	&.v3_mod {
		max-width: 98rem;
		display: flex;
		flex-direction: column;
		//justify-content: center;
		align-items: center;
	}

	&.about_healing_music_mod {
		padding: 0 5em;
		max-width: 136em;

		@include bp($point_1) {
			padding: 0 5em;
			max-width: 100%;
		}

		@include bp($point_3) {
			padding: 0 3em;
		}
	}

	&.flex_mod {
		@extend %global_flex_block_row_nowrap_flex-start_center;

		@include bp($point_3) {
			flex-flow: wrap;
		}
	}

	@include bp($point_3) {
		padding: 0 $gl_mobile_indent;
		text-align: center;
	}
}

.section_title {
	font-size: 3.4em;
	font-weight: 300;
	font-style: italic;
	letter-spacing: -.02rem;

	&.hero_mod {
		margin-bottom: (1.8em/6);

		font-size: 6em;
		line-height: 1;
		font-weight: 300;
		font-style: normal;
		color: $black_2;
		text-align: left;

		@include bp($point_3) {
			font-size: 4.8em;
		}

		@include bp($point_4 - 1) {
			font-size: 4.2em;
		}

	}

	&.hero_v2_mod {
		margin-bottom: (2em/5.5);
		font-size: 5.5em;
		line-height: 1.1;
		@include bp($point_3) {
			font-size: 4.2em;
		}
		@include bp($point_4 - 1) {
			font-size: 3.8em;
		}
	}

	&.award_mod {
		margin-bottom: (.5em/3.5);

		text-align: center;
		color: $black_2;
		font-size: 3.5em;
		font-family: $font_1;
		font-style: italic;
	}

	&.hero_title_mod {
		margin-bottom: (1.2em/6);

		font-size: 6em;
		font-weight: 300;
		line-height: (6/6);
		font-style: normal;

		@include bp($point_3) {
			font-size: 4em;
		}
	}

	&.hero_title_mod_v2 {
		margin-bottom: (1.2em/6);

		font-size: 5em;
		font-weight: 300;
		line-height: (6/6);
		font-style: normal;

		@include bp($point_3) {
			font-size: 4em;
		}
	}

	&.sanofi_hero_title_mod {
		padding: 13.2em 0 5.4em;
		background: url('../images/sanofi_bg.jpg') no-repeat center center;
		@include bp($point_3) {
			padding: 8.2em 0 3.2em;
		}
	}

	&.limit_mod {
		max-width: 68.8rem;
		margin: 0 auto;
	}

	&.color_mod {
		color: $accent_color;
	}

	&.center_mod {
		text-align: center;
	}

	&.width_mod {
		width: 50%;
		@include bp($point_4) {
			width: 100%;
    	}
	}

	&.padding_right_mod {
		padding-right: 3em;
		@include bp($point_4) {
			padding-right: 0em;
    	}
  	}

	&.center_mobile {
		@include bp($point_4) {
			text-align: center;
		}
	}

	&.center_mod {
		text-align: center;
	}

	&.banner_mod {
		margin-bottom: 0.2em;
		letter-spacing: -0.005em;
	}

	&.offset_mod {
		margin-bottom: (.6em/3.5);
	}

	&.offset_v1_mod {
		margin-bottom: (4.4em/3.5);
	}

	&.offset_v2_mod {
		margin-bottom: (1.2em/3.5);
	}

	&.offset_v3_mod {
		margin-bottom: (1em/3.5);
	}

	&.mb_mod {
		margin-bottom: (1.4em/3.5);
	}

	&.color_w_mod {
		color: $white;
	}

	&.about_healing_music_mod {
		margin-bottom: (0.8em/3.5);

		color: $accent_color_3;
		letter-spacing: 0.002em;
	}

	&.color_2_mod {
		color: $white_3;
	}

	&.color_3_mod {
		color: $black_2;
	}

	&.color_4_mod {
		color: $accent_color_4;
	}

	&.mobile_margin {
		@include bp($point_4) {
			margin-bottom: 1em;
		}
	}

	&.btn_center_mod {
		@include bp($point_4) {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
  	}

	&.title_fit {
		margin-right: -25px;
	}

	@include bp($point_3) {
		br {
			display: none;
		}
	}

	&.mobile_width_mod {
		@include bp($point_4) {
			max-width: 60vw;
		}
		@include bp($point_5) {
			max-width: unset;
		}
	}

	&.max_width_mod {
		max-width: 90%;
		@include bp($point_5) {
			max-width: 100%;
		}
	}

	&.font_xl_mod {
		font-size: 3.9em;
	}
}

.section_descr {
	font-size: 2em;
	line-height: 1.8;
	color: $black_2;

	&.center_mod {
		text-align: center;
		margin: 0 auto;
	}

	&.offset_bottom {
		margin-bottom: (1.6em/2);
	}

	&.offset_mod {
		margin-bottom: (4em/2);
	}

	&.offset_v1_mod {
		margin-bottom: (1.2em/1.6);
	}

	&.offset_v2_mod {
		margin-bottom: 0em;
	}

	&.offset_v3_mod {
		margin-bottom: (4.8em/1.6);
	}

	&.offset_v4_mod {
		margin-bottom: (2.6em/1.4);
	}

	&.offset_v5_mod {
		margin-bottom: 2em;
		@include bp($point_4) {
			margin-bottom: 4em;
		}
	}

	&.offset_v6_mod {
		margin-bottom: (0.4em);
	}

	&.sm_mod {
		font-size: 1.6em;
	}

	&.center_mobile {
		@include bp($point_4) {
			text-align: center;
		}
	}



	&.xs_mod {
		font-size: 1.4em;
	}

	&.xxs_mod {
		font-size: 0.47em;
		font-style: normal;
		font-weight: 500;
	}

	&.color_mod {
		color: $accent_color;
	}

	&.treatment_mod {
		margin-bottom: (3em/1.6);

		line-height: 2.3;

		&:last-of-type {
			margin-bottom: 0;
		}

	}

	&.limit_mod {
		max-width: (51em/2);
		margin-left: auto;
		margin-right: auto;
	}

	&.limit_v1_mod {
		max-width: (70em/1.6);
		width: 100%;
	}

	&.color_2_mod {
		color: $white_3;
	}

	&.color_3_mod {
		color: $black_2;
	}

	&.color_w_mod {
		color: $white;
	}

	&.small_mod {
		font-size: 1.6em;
	}

	&.hero_mod {
		text-align: left;
	}

	@include bp($point_3) {
		font-size: 1.6em;
	}

	&.mobile_width_mod {
		@include bp($point_4) {
			max-width: 60vw;
		}
		@include bp($point_5) {
			max-width: unset;
		}
	}

}

.section_subtitle {
	font-size: 1.6em;

	&.hero_title_mod {
		font-weight: 700;
		text-transform: uppercase;
		line-height: 2;
	}

	&.color_mod {
		color: $white;
	}

	&.color_2_mod {
		color: $black_2;
	}

	@include bp($point_3) {
		font-size: 1.4em;
	}
}

.section_head_w {
	width: 100%;

	&.hero_title_mod {
		max-width: 72.7em;

		@include bp($point_3) {
			max-width: none;
		}
	}

	&.hero_title_mod_v2 {
		max-width: 72.7em;

		@include bp($point_3) {
			max-width: 61.2em;
		}
	}

	&.about_line_mod {
		max-width: 52.2em;

		@include bp($point_3) {
			max-width: none;
		}
	}

	&.mobile_center_mod {
		@include bp($point_5) {
			text-align: center;
			align-items: center;
		}
	}

	&.offset_bottom {
		margin-bottom: 8em;
	}

	&.offset_2_bottom {
		margin-bottom: 2.4em;
	}

	&.center_mod {
		text-align: center;
		margin-left: auto;
		margin-right: auto;
	}

}

.sections_wrap {
	margin: 0;
	padding: 0;

	&.bg_accent_gradient_mod {
		background: $accent_gradient_2;
	}
}

.accent_title_el {
	color: #1f1f1f;
	display: block;
}
