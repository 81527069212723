.studies_table {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	overflow-x: auto;

	@include bp($point_4 - 1) {
		display: block;
	}
}

.studies_table_row {
	@extend %global_flex_block_row_wrap_flex-start;
	width: 100%;

	padding-top: 1.4em;
	padding-bottom: 1.4em;
	min-height: 8.2em;

	border-bottom: 1px solid #d6d6d6;

	&.heading_mod {
		width: 100%;
		align-items: center;
		padding: 1.4em 0;
		min-height: 1.6em;
		align-content: center;

		border-bottom: 1px solid $accent_color;

		@include bp($point_3) {
			// display: none;
			// .studies_table_info.mod_1,
			// .studies_table_info.mod_2,
			// .studies_table_info.mod_3,
			// .studies_table_info.mod_4,
			// .studies_table_info.mod_5,
			// .studies_table_info.mod_6 {
			// 	display: none;
			// }
		}
	}

	@include bp($point_3) {
		//flex-wrap: wrap;
	}

	@include bp($point_4 - 1) {
		flex: 1;
	}

}

$fns: 1.6;

.studies_table_info {
	color: $black;
	font-size: $fns + em;
	font-weight: 400;
	line-height: 1.7;

	.studies_table_row.heading_mod & {
		padding: 0;

		color: $gray_4;
		font-weight: 700;
	}

	&.mod_1 {
		padding-right: (3em/$fns);

		@include bp($point_2, min) {
			width: 59%;
			padding-left: (2.2em/$fns);
		}

		@include bp($point_3) {
			width: 100%;
			padding-right: 0;
			//min-width: 26rem;
		}

		.studies_table_row.heading_mod & {
			color: $black;
		}

	}

	&.mod_2 {
		padding-right: 1em;

		@include bp($point_2, min) {
			width: 18%;
		}

		@include bp($point_3) {
			width: 100%;
			padding-right: 0;
			//min-width: 14rem;
		}

	}

	&.mod_3 {

		@include bp($point_2, min) {
			width: 21%;
		}

		@include bp($point_3) {
			width: 100%;
			padding-right: 0;
			//min-width: 16rem;
		}

	}

	&.mod_4 {
		width: 15%;

		.studies_table_row & {
			color: $black;
			font-weight: 700;
		}

		.studies_table_row.heading_mod & {
			color: $gray_4;
		}

		@include bp($point_2, min) {
			padding-left: (1.6em/$fns);
		}

		@include bp($point_3) {
			width: 100%;
			//padding-right: (1.6em/$fns);
		}

		//@include bp($point_4 - 1) {
		//	min-width: (12em/$fns);
		//}

	}

	&.mod_5 {
		width: 44%;

		@include bp($point_2, min) {
			padding-right: (11.2em/$fns);
		}

		@include bp($point_3) {
			width: 100%;
			//padding-right: (2em/$fns);
		}

		//@include bp($point_4 - 1) {
		//	min-width: (34em/$fns);
		//}

	}

	&.mod_6 {
		width: 41%;

		@include bp($point_2, min) {
			padding-right: 3em;
		}

		@include bp($point_3) {
			padding-right: (2em/$fns);
		}

		@include bp($point_4 - 1) {
			min-width: (32em/$fns);
		}

	}

	@include bp($point_3) {
		position: relative;
		padding-top: .6em;

		.studies_table_row.heading_mod &:not(.nav_wrap_mod) {
			& {
				display: none;
			}

			&:first-child {
				display: block;
			}

		}

		// &.nav_wrap_mod {
		// 	display: block;
		// }

		// &:before {
		// 	position: absolute;
		// 	top: .5em;
		// 	left: 0;
		// 	font-weight: 600;
		// 	content: attr(data-title);
		// }

	}

}

.studies_table_info_w_block {
	@extend %global_flex_block_row_wrap_flex-start;

	margin-right: -(2em/$fns);

	@include bp($point_3) {
		display: block;
		margin-right: 0;
	}
}

.studies_table_info_block {
	width: 50%;
	padding-right: (2em/$fns);

	font-size: (1.3em/$fns);
	line-height: 2.2;

	@include bp($point_3) {
		width: 100%;
		padding: 0;
	}
}

.studies_table_info_link {
	color: $accent_color;
	font-weight: 700;
}

.studies_table_w_row {
	width: 100%;
}

.studies_table_nav {
	@extend %global_flex_block_row_wrap_flex-start_center;

	@include bp($point_3) {
		align-items: flex-start;
	}
}

.studies_table_nav_item {

	@include bp($point_2, min) {
		margin-right: 3.2em;

		&:last-of-type {
			margin-right: 0;
		}

	}

	@include bp($point_3) {
		width: 34%;
		padding-right: 1em;
	}

	@include bp($point_4 - 1) {
		width: 50%;
	}

}

.studies_table_tabs {
	display: none;

	&.active_state {
		display: block;
	}

}

.studies_table_nav_link {
	color: $accent_color;
	font-weight: 700;

	&.active_state {
		color: $black;
	}

	&:hover,
	&:focus {
		text-decoration: none;
	}

}
