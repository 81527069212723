.company_list_wrap {
	margin-bottom: 5.2em;
	&:last-child {
		margin-bottom: 0;
	}
}

.company_list {
	@extend %global_flex_block_row_wrap_flex-start_stretch;
	margin: 2.6em -1.2em -2em 0;

	&.lg_mod {
		margin-right: -2em;
		margin-bottom: -2em;
	}

}

.company_list_item {
	@extend %global_flex_block_column_nowrap_flex-start_stretch;
	width: 16.66%;
	padding: 0 1.2em 2em 0;

	.company_list.lg_mod & {
		width: 33.33%;
		padding: 0 2em 2em 0;

		@include bp($point_3) {
			max-width: 50em;
			margin: 0 auto;
			width: 100%;
		}
	}

	@include bp($point_3) {
		width: 33.33%;
	}
	@include bp($point_4 - 1) {
		width: 50%;
	}
	@include bp($point_7) {
		width: 100%;
	}
}

.company_list_img_wrap {
	position: relative;
	background-color: $black;
	&:after {
		content: '';
		display: block;
		padding-bottom: 78%;

		.company_list.lg_mod & {
			padding-bottom: 49.2%;
		}
	}
}

.company_list_img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

.company_list_item_content {
	padding: 1em 0 .8em;
	background-color: $white;
	flex-grow: 1;

	.company_list.lg_mod & {
		padding: 3.2em 4.2em 5.2em;
	}
}

.company_list_text {
	margin-bottom: (.4em/1.2);
	font-size: 1.2em;
	color: #222;
	text-align: center;
	line-height: 1.9;

	.company_list.lg_mod & {
		font-size: 1.6em;
		text-align: left;
	}

	.company_list.lg_mod &.lg_mod {
		margin-bottom: 0;
		font-size: 2.4em;
		font-style: italic;
		color: $accent_color;

		text-align: left;
		line-height: 1.7;
	}

	.company_list.white_mod & {
		color: $white;
	}
}
