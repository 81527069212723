.treatment_inner {
	width: 100%;
	@include bp($point_2, min) {
		max-width: 62%;
	}
}

.treatment_list {
	@extend %global_flex_block_row_wrap_center_center;

	margin: 0 -1em 0 -1em;

	@include bp($point_4) {
		justify-content: flex-start;
	}
}

.treatment_item {
	@extend %global_flex_block_column_wrap_flex-start_center;

	width: 20%;
	padding: 0 1em 3em 1em;

	@include bp($point_1) {
		width: 25%;
	}

	@include bp($point_3) {
		width: 33.33%;
	}

	@include bp($point_4) {
		width: 50%;
		flex-flow: row nowrap;
	}

	@include bp($point_6) {
		width: 100%;
	}
}


.treatment_description {
	@extend %global_flex_block_row_wrap_space_between_flex-start;

	padding-top: 2.5em;

	border-top: .2em solid $accent_color_3;

	@include bp($point_4) {
		flex-direction: column;
	}
}

.treatment_half {
	width: 50%;

	@include bp($point_4) {
		width: 100%;
	}

	&:first-child {
		border-right: .2em solid $accent_color_3;

		@include bp($point_4) {
			border-right: none;
			margin: 0 0 2.5em 0;
		}
	}

	&.half_left_mod {
		padding: 0 6em 0 0;

		@include bp($point_4) {
			padding: 0;
		}
	}

	&.half_right_mod {
		padding: 0 0 0 6em;

		@include bp($point_4) {
			padding: 0;
		}
	}
}

.img_wrap_treatment {
	width: 17.5em;
	height: 17.5em;
	flex: 0 0 auto;
	border: .2em solid $accent_color_3;
	border-radius: 50%;

	overflow: hidden;

	@include bp($point_4) {
		margin: 0 2em 0 0;
	}
}

.img_wrap_treatment_in {
	width: 100%;
	height: 100%;
	padding: 2.5em;
}

.treatment_pic {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: contain;
}
