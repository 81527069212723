.diseases_wrap {
	@extend %global_flex_block_row_nowrap_flex-start_center;

	overflow: hidden;

	@include bp($point_3) {
		flex-wrap: wrap;
		justify-content: center;
	}

	@include bp($point_5) {
		justify-content: center;
	}

}

.disease_item {
	display: block;

	&.flex_mod {
		@extend %global_flex_block_row_wrap_center_center;

		flex: 1 1 auto;

		@include bp($point_4) {
			width: 100%;
		}

		.disease_logo {
			width: 50%;
		}
	}

	&:hover, &:focus {
		text-decoration: none;
	}

	@include bp($point_2, min) {
		margin-right: 6.8em;

		&:last-child {
			margin-right: 0;
		}

	}

	@include bp($point_3) {
		width: 30%;
		padding: 0 1em 4em;
	}

	@include bp($point_4) {
		width: 50%;
	}
	
	@include bp($point_7 - 1) {
		width: 100%;
	}

}

.disease_item_pict_wrap {
	margin: 0 auto 2.4em;
	display: block;
	width: 4.8em;
	height: 4.4em;

	@include bp($point_3) {
		width: 100%;
	}
}

.disease_item_pict {
	margin: auto;
	display: block;
	max-width: 100%;
	max-height: 100%;
}

.disease_item_name {
	margin-bottom: 0;

	color: #3e6493;
	font-size: 1.8em;
	font-weight: 400;
	line-height: 1;

	text-align: center;
	
	transition: color .3s;

	.disease_item:hover & {
		color: $black;
	}
}

.disease_logo {
	@extend %global_flex_block_row_nowrap_flex-end_center;
	
	@include bp($point_2, min) {
		max-width: 15em;
		width: 100%;
		height: 9.6em;
		overflow: hidden;
	}

	@include bp($point_3) {
		width: 100%;
		justify-content: center;
		margin: 0 auto;
	}

}

.disease_logo_img {
	display: block;
	max-width: 100%;
	max-height: 100%;

	&.medapp_mod {
		width: 100%;
		max-width: 15em;
	}
	
	@include bp($point_3) {
		max-height: 9.6em;
	}

}

.disease_logos_wrap {
	margin: 0;
	display: flex;
	align-items: center;
	width: 30em;

	@include bp($point_4) {
		margin: 2em 0 0;
		width: 100%;
		flex-flow: column;
		align-items: center;
		align-content: center;
		justify-content: flex-start;
	}
}
