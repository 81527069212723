.section_map_w {
	z-index: -1;

	position: relative;

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	@include bp($point_3) {
		position: static;
		width: auto;
		margin: 3em (-$gl_indent) 0;
	}
}

.section_map_img_w {
	width: 100%;
	overflow: hidden;
}

.section_img_w {
	display: block;
	width: 100%;
	height: auto;
}
