
.quotes_block {
  @extend %global_flex_block_row_wrap_flex-start_center;

  position: relative;

  min-height: 11.2em;
  padding: 2em 4em 2em 7.4em;

  border-radius: .5em;
  background-color: #f6fafe;

  &:before {
    position: absolute;
    top: 2.6em;
    left: 2.4em;
  
    width: 2.8em;
    height: 3.2em;

    background: url('../images/atom_icon.png') no-repeat center center;
    background-size: contain;
  
    content: '';
  }

  &.offset_mod {
    margin-bottom: 3.4em;
  }

}

.quotes_block_in {
  color: $black_3;
  font-size: 1.4em;
  font-weight: 400;
  line-height: 1.7;
}