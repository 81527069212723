//---------------------------------------------fonts
$font_path: "../fonts/";
$font_1: 'Bariol', sans-serif;
$font_size_base: 1.6rem;
//---------------------------------------------fonts###

//---------------------------------------------colors
$white: #fff;
$white_2: #f2f5f8;
$white_3: #fefefe;
$white_4: #f7f9ff;

$black: #000;
$black_2: #0d0d0d;
$black_3: #191919;
$black_4: #373737;
$black_5: #1a1a1a;

$gray: #909090;
$gray_2: #eff3f6;
$gray_3:  #dbdbdb;
$gray_4:  #b2b2b2;
$gray_5:  #f8f9ff;

$green: #1fc927;

$dark_blue: #3575b6;

$blue: #4574af;

$accent_color: #0071be;
$accent_color_2: #6aade8;
$accent_color_3: #5b8fd0;
$accent_color_4: #4879b6;
$accent_color_5: #658ec4;

$accent_gradient: linear-gradient(-211deg, #0071be 0%, $accent_color 100%);
$accent_gradient_2: linear-gradient(31deg, #0071be 0%, $accent_color 100%);

$color_text_base: $black;
$color_link_base:#4aa3df;
$body_bg: $white;
//---------------------------------------------colors###

//---------------------------------------------z-index
$z-index: (
	popap : 9999,
	header : 100,
	footer : 100
);
//---------------------------------------------z-index###

//---------------------------------------------layout
$height_footer: 10rem;
$height_header: 10.2rem;
$page_width: 130.8rem;

//---------------------------------------------layout###

//---------------------------------------------global values
$gl_indent: 3rem;
$gl_mobile_indent: 2rem;
$gl_radius:.5rem;
//---------------------------------------------global values###

//---------------------------------------------media points
$point_1: 1200px;
$point_2: 1024px;
$point_3: 1023px;
$point_4: 768px;
$point_5: 640px;
$point_6: 480px;
$point_7: 375px;
$point_8: 320px;
//---------------------------------------------media points

//---------------------------------------------forms
$input_height:4rem;
$input_offset:0 1.4rem;
$input_font_size:$font_size_base;
$input_border_color:#cfcfcf;
$input_border_color_active:$black;
$input_placeholder_color: $gray;
$input_text_color:$black;
$input_radius:$gl_radius;
//---------------------------------------------forms###
