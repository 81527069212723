//-------------------------------------------------------inputs and selects
%form_field_default {
	display: block;
	width: 100%;
	height: $input_height;
	padding: $input_offset;
	font-size: $input_font_size;
	font-family: $font_1;
	color: $input_text_color;
	box-shadow: inset 0 0 0 .2rem $input_border_color;
	background-color: transparent;
	border:none;
	border-radius: 0;

	&::-webkit-input-placeholder {
		color: $input_placeholder_color;
	}

	&::-moz-placeholder {
		color: $input_placeholder_color;
	}

	&:-ms-input-placeholder {
		color: $input_placeholder_color;
	}

	@include bp($point_3) {
		box-shadow: inset 0 0 0 .2rem $accent_color;
	}
}

%form_field_default_hover {
}

%form_field_default_active {
	box-shadow: 0 0 0 .2rem #aaa;
	@include bp($point_3) {
		box-shadow: inset 0 0 0 .2rem $accent_color_2;
	}
}

.form_cell {
	&.hidden_field {
		display: none;
	}

	&.form_cell_v1_mod {
	}
}

.form_cell_title {

	&.hline_hide_mod {
		display: none;
	}

	&.form_v1_mod {
	}
}

.form_field_wrap {
	position: relative;

	&.form_v1_mod {
	}
}

.form_field {
	&.default_mod {
		@extend %form_field_default !optional;

		&:focus {
			@extend %form_field_default_active !optional;
		}

		&:hover {
			@extend %form_field_default_hover !optional;
		}
	}

	&.textarea_mod_1 {
		height: 8rem;
		padding-top: 1rem;
	}

	&.form_v2_mod {
		padding-right: 4.8rem;
		height: 5.2rem;
		font-style: italic;
		background-color: transparent;
	}
}

//---------------------------------------------inputs and selects###

//---------------------------------------------checkboxes and radio-buttons

// copy/paste from helpers/components/forms/_form.scss

//---------------------------------------------checkboxes and radio-buttons###