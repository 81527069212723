.section_cols {
	@extend %global_flex_block_row_nowrap_flex-end_stretch;

	&.center_mod {
		align-items: center;
	}

	&.reverse_mod {
		display: flex;
		flex-direction: row-reverse;
	}

	@include bp($point_3) {
		flex-direction: column;
	}
}

.section_col_block_wrap {
	position: relative;
	width: 50%;
	margin-right: 4em;
	flex-shrink: 0;
	&.right_mod {
		margin-left: auto;
	}

	&.sm_mod {
		width: 42%;

		@include bp($point_3) {
		    width: 100%;
		}
	}

	@include bp($point_3) {
		width: 100%;
		margin: 0 0 4em;
		text-align: center;
	}
}

.form_block {
	@extend %global_flex_block_row_wrap_flex-start;
	margin: 2em -.8em -1.2em;
}

.section_cols_img_wrap {
	position: absolute;
	top: 0;
	left: 0;
	width: 50%;
	height: 100%;
	z-index: -1;

	&.full_mod {
		width: 100%;
	}

	&.dark_mod {
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($black, .55);
		}
	}

	&.reverse_mod {
		left: unset;
		right: 0;
	}

	&.video_mod {
		z-index: 1;
		@include bp($point_3) {
			position: relative;
			margin-bottom: 2em;
			&:after {
				background-color: rgba($black, .3);
			}
		}
	}

	@include bp($point_3) {
		width: 100%;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($white, 1);
		}
	}
}

.section_cols_img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

.form_controller {
	padding: 0 .8em 1.2em;
	width: 100%;

	&.half_mod {
		width: 50%;
		@include bp($point_7) {
			width: 100%;
		}
	}

	&.btn_mod {

		@include bp($point_3) {
			width: 100%;
		}

	}

}


.half_lg_img  {
	max-width: 100%;
}

.half_logo_list {
	@extend %global_flex_block_column_nowrap_center_center;
	@include bp($point_3) {
		margin-bottom: 2em;
	}
}

.half_logo_list_item {
	max-width: 15em;
	max-height: 9.4em;
	margin-bottom: 3.4em;
	&:last-child {
		margin-bottom: 0;
	}
	@include bp($point_3) {
		$sh: rgba($accent_color, .4);
		filter:
			drop-shadow(0 .6rem .6rem $sh)
			drop-shadow(0 -.6rem .6rem $sh)
			drop-shadow(.6rem 0 .6rem $sh)
			drop-shadow(-.6rem 0 .6rem $sh);
	}
}

.half_logo_list_img {
	display: block;
	max-width: 15em;
	max-height: 9.4em;
	object-fit: contain;
}

.half_logos {
	@extend %global_flex_block_row_wrap_flex-start_center;
	margin: 2.6em -2.6em -2.6em;
	@include bp($point_4 - 1) {
		justify-content: center;
	}
}

.half_logo {
	width: 25%;
	padding: 0 2.6em 2.6em;

	@include bp($point_5) {
		display: flex;
		width: 33.33%;
		max-height: 8.4em;
		max-width: 16em;
	}
}

.half_logo_img {
	width: 100%;
	max-height: 100%;
	object-fit: contain;
	object-position: center;
}

.extraDescr_container {
	display: flex;
	align-items: center;
	margin-top: 1em;
	padding: 1em 1em;
}

.icon_img {
	width: 4em;
	margin-right: 1em;
}

.half_extra_descr {
	font-size: 1.2em;
}

.video_btn {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 6.2em;
	height: 6.2em;
	margin-top: -3.1em;
	margin-left: -3.1em;
	background: url('../images/play_white.png') no-repeat center center;
	background-size: contain;
	cursor: pointer;
	z-index: 2;
}
