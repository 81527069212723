.footer {
	width: 100%;
	min-height: $height_footer;
	padding: 3.6em 0 4em;

	flex: 0 0 auto;

	grid-area: footer;

	font-size: 1rem;

	will-change: transform;

	background: #0071be;
	// background-image: $accent_gradient;
}

// .footer_in {
// 	max-width: $page_width;
// 	padding: 0 $gl_indent;
// 	margin: 0 auto;
// }

.footer_top {
	@extend %global_flex_block_row_wrap_flex-start;
	.footer.v2_mod & {
		justify-content: center;
		@include bp($point_3) {
			justify-content: flex-start;
		}
	}
	@include bp($point_3) {
		margin: 0 -1em;
	}
}

.footer_menu_block {
	margin-right: auto;
	padding-bottom: 4.6em;
	&:last-child {
		margin-right: 0;
	}
	@include bp($point_3) {
		margin-right: 0;
		width: 33.33%;
		padding: 0 1em 4.6em;
	}
	@include bp($point_6) {
		width: 50%;
	}
	@include bp($point_7 - 1) {
		width: 100%;
	}
}

.footer_menu_title {
	margin-bottom: (2em/1.2);
	height: (1.5em/1.2);

	font-size: 1.2em;
	color: $white;
	letter-spacing: 1px;
	text-transform: uppercase;
	@include bp($point_7 - 1) {
		text-align: center;
	}
}

.footer_menu {
	&.double_mod {
		@include bp($point_2, min) {
			column-count: 2;
			column-gap: 4em;
		}
	}
}

.footer_menu_item {
	margin-bottom: 1.5em;
	&:last-child {
		margin-bottom: 0;
	}
	@include bp($point_7 - 1) {
		text-align: center;
	}
}

.footer_menu_link {
	font-size: 1.4em;
	font-weight: 700;
	color: $white;
}

.footer_copyright {
	font-size: 1.2em;
	color: $white;

	.footer.v2_mod & {
		text-align: center;
	}

	@include bp($point_7 - 1) {
		text-align: center;
	}
}

.footer_social_list {
	@extend %global_flex_block_row_nowrap_flex-start_center;
	margin-top: -.4em;
	margin-bottom: 1.4em;

	@include bp($point_7 - 1) {
		justify-content: center;
	}
}

.footer_social_item {
	width: 2.4em;
	height: 2.4em;
	margin-right: .6em;
	&:last-child {
		margin-right: 0;
	}
}

.footer_social_img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center;
}
