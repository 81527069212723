.about_line_w {
	position: relative;

	width: 100%;
	height: 33.2em;

	@include bp($point_1) {
		height: 60em;
	}

	@include bp($point_5 - 1) {
		font-size: 1.94vw;
	}
}

.about_line_decor {
	z-index: 1;

	position: absolute;

	top: 50%;
	left: 50%;

	display: block;
	width: 100vw;
	height: 4px;

	transform: translate(-50%, -50%);

	background-color: $white;

	@include bp($point_1) {
		width: 2px;
		height: 100%;
	}
}

.about_line_items_w {
	z-index: 2;

	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}

.about_line_item {
	position: absolute;
	top: 50%;

	width: 4.4em;
	height: 4.4em;

	margin: -2.2em 0 0 -2.2em;

	border-radius: 50%;
	border: 4px solid $white;
	background-color: #4777b3;

	white-space: nowrap;

	&:before {
		position: absolute;
		top: 50%;
		left: 50%;

		content: '';

		width: 1.8em;
		height: 1.8em;
		margin: -.9em 0 0 -.9em;

		border-radius: 50%;

		background-color: $white;

		@include bp($point_1) {
			width: .8em;
			height: .8em;
			margin: -.4em 0 0 -.4em;
		}
	}


	&:first-child {
		@include bp($point_1 + 1, min) {
			left: 0;
		}
		@include bp($point_1) {
			top: 0;
		}
	}

	&:nth-child(2) {
		@include bp($point_1 + 1, min) {
			left: 20%;
		}
		@include bp($point_1) {
			top: 20%;
		}
	}

	&:nth-child(3) {
		@include bp($point_1 + 1, min) {
			left: 20% * 2;
		}

		@include bp($point_1) {
			top: 20% * 2;
		}
	}

	&:nth-child(4) {
		@include bp($point_1 + 1, min) {
			left: 20% * 3;
		}

		@include bp($point_1) {
			top: 20% * 3;
		}
	}

	&:nth-child(5) {
		@include bp($point_1 + 1, min) {
			left: 20% * 4;
		}

		@include bp($point_1) {
			top: 20% * 4;
		}
	}

	&:last-child {
		@include bp($point_1 + 1, min) {
			right: 0;
			margin-left: 0;
			margin-right: -2.2em;
		}

		@include bp($point_1) {
			top: auto;
			bottom: 0;
			margin: 0 0 -1.5em -1.5em;
		}
	}

	@include bp($point_1) {
		left: 50%;

		width: 3em;
		height: 3em;
		margin: -1.5em 0 0 -1.5em;

		border-width: 2px;
	}

}

.about_line_item_in {
	position: absolute;

	@include bp($point_1 + 1, min) {
		left: 50%;

		height: 15em;
		width: 4px;
		margin-left: -2px;
	}

	@include bp($point_1) {
		top: 50%;

		width: calc(50vw - 6em);
		height: 2px;

		margin-top: -1px;
	}

	white-space: nowrap;

	&:before {
		position: absolute;
		top: 0;
		left: 0;

		content: '';

		width: 100%;
		height: 100%;

		background-color: $white;
	}

	&:after {
		position: absolute;

		content: '';

		@include bp($point_1 + 1, min) {
			left: 50%;

			width: 1.8em;
			height: 1.8em;
			margin-left: -.9em;
		}

		@include bp($point_1) {
			top: 50%;

			width: .8em;
			height: .8em;
			margin-top: -.4em;
		}

		border-radius: 50%;

		background-color: $white;
	}

	.about_line_item:nth-child(odd) & {

		@include bp($point_1 + 1, min) {
			bottom: 100%;

			&:after {
				top: 0;
			}
		}
		@include bp($point_1) {
			right: 100%;

			&:after {
				left: -.6em;
			}
		}

	}

	.about_line_item:nth-child(even) & {
		@include bp($point_1 + 1, min) {
			top: 100%;

			&:after {
				bottom: 0;
			}
		}

		@include bp($point_1) {
			left: 100%;

			&:after {
				left: 100%;
			}
		}
	}

}

.about_item_text_w {
	position: absolute;

	width: 18.8em;

	white-space: normal;

	.about_line_item:nth-child(odd) & {
		@include bp($point_1 + 1, min) {
			top: 0;
			margin-top: -4px;
		}
		@include bp($point_1) {
			top: 1em;
			left: -.6em;
			margin: 0;
		}
	}

	.about_line_item:nth-child(even) & {
		@include bp($point_1 + 1, min) {
			top: 100%;
			margin-top: -2.2em;
		}
		@include bp($point_1) {
			top: 1em;
			right: 0;
			margin: 0;
		}
	}

	.about_line_item.right_text & {
		@include bp($point_1 + 1, min) {
			left: 3em;
		}
	}

	.about_line_item.left_text & {
		@include bp($point_1 + 1, min) {
			right: 3em;
			text-align: right;
		}
	}
}

.about_item_title {
	width: 100%;
	color: $white_3;
	font-size: 1.6em;
	font-weight: 700;
	line-height: (2.7/1.6);
}

.about_item_text {
	color: $white_3;
	font-size: 1.4em;
	font-weight: 400;
	line-height: (2.7/1.4);
}
