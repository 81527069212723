.reviews_w_nav {
	@extend %global_flex_block_row_wrap_flex-start_center;

	padding-bottom: 1.4em;
	margin-bottom: 2.4em;

	border-bottom: 1px solid $accent_color;

	@include bp($point_4 - 1) {
		align-items: flex-start;
	}

}

.reviews_nav {
	@extend %global_flex_block_row_wrap_flex-start;

	&.lang_mod {
		margin-left: auto;
	}

	@include bp($point_4 - 1) {
		display: block;
		max-width: 50%;
	}

}

.reviews_tabs {
	display: none;

	&.active_state {
		display: block;
	}

}

.reviews_nav_item {

	@include bp($point_4, min) {
		margin-right: 5em;

		&:last-of-type {
			margin-right: 0;
		}

	}

	@include bp($point_4 - 1) {
		margin-bottom: .6em;
	}

}

.reviews_nav_link {
	color: $accent_color;
	font-size: 1.6em;
	font-weight: 700;

	&.active_state {
		color: $black_5;
	}

	&.lang_mod {
		color: $black_5;

		border-bottom: 1px solid transparent;

		&:hover,
		&:focus {
			border-bottom: 1px solid $black_5;
		}

	}

	&:hover,
	&:focus {
		color: $black_5;
		text-decoration: none;
	}

}

.reviews_list {
	@extend %global_flex_block_row_wrap_flex-start;

	margin-right: -2em;
	margin-bottom: -2em;
}

.reviews_list_item {
	padding-right: 2em;
	padding-bottom: 2em;

	@include bp($point_2, min) {
		width: 33.33%;
	}

	@media (min-width: $point_4) and (max-width: $point_3) {
		width: 50%;
	}

	@include bp($point_4 - 1) {
		width: 100%;
	}

}

.reviews_block {
	@extend %global_flex_block_row_wrap_flex-start_flex-end;

	position: relative;

	height: 18em;
	box-shadow: none;
	transition: box-shadow .3s;

	&:hover,
	&:focus {
		text-decoration: none;
		box-shadow: .6rem .6rem 2rem rgba($accent_color, .3);
	}

}

.reviews_block_w_img {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}

.reviews_block_img {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: cover;
}

.reviews_block_info {
	position: relative;
	z-index: 10;

	padding: 1em;
	width: 100%;

	background-color: rgba($gray_2, .9);
}

$fns: 1.6;

.reviews_block_info_in {
	position: relative;

	display: flex;
	justify-content: flex-start;
	align-items: center;
	align-content: center;
	width: 100%;
	padding-left: (3.8em/$fns);

	color: #101010;
	font-size: $fns + em;
	font-weight: 400;

	&:before {
		position: absolute;
		top: 50%;
		left: 2px;

		width: (2.4em/$fns);
		height: (2.4em/$fns);
		margin-top: -(1.2em/$fns);

		background: url('../images/play_icon_black.png') no-repeat center center;
		background-size: contain;

		content: '';
	}

}
