.circle_item_list {
	@extend %global_flex_block_row_wrap_center;

	padding: 0;

	list-style: none;

	&.v1_mod {
		margin: 0 -5.4em -4em 0;

		@include bp($point_1) {
			margin-right: -2em;
		}

		@include bp($point_3) {
			justify-content: flex-start;
		}
	}

	&.v2_mod {
		margin: 0 -5em -5em 0;

		@include bp($point_3) {
			margin-right: -2em;
		}
	}
}

.circle_item {
	width: 16.66%;

	&.v1_mod {
		padding: 0 5.4em 4em 0;
		width: 20%;

		@include bp($point_1) {
			width: 20%;
		}

		@include bp($point_2) {
			width: 33.33%;
			padding-right: 2em;
		}

		@include bp($point_3) {
			width: 50%;
		}

		@include bp($point_6) {
			width: 100%;
		}
	}

	&.v2_mod {
		padding: 0 5em 5em 0;
		width: 16.66%;

		@include bp($point_1) {
			width: 20%;
		}

		@include bp($point_3) {
			width: 25%;
			padding-right: 2em;
		}

		@include bp($point_5) {
			width: 33.33%;
		}

		@include bp($point_6) {
			width: 50%;
		}

	}
}

.circle_item_in {
	@extend %global_flex_block_column_nowrap_flex-start_center;

	width: 100%;

	&:hover,
	&:focus {
		text-decoration: none;
	}

	//@include bp($point_3) {
	//	flex-flow: row nowrap;
	//}

	@include bp($point_3) {
		flex-flow: column nowrap;
	}

	&.v1_mod {

		@include bp($point_6) {
			flex-direction: row;
		}
	}

}

.circle_item_img_w {
	position: relative;
	width: 20.6em;
	height: 20.6em;

	border-radius: 50%;

	overflow: hidden;

	&:after {
		content: '';
		display: block;
		padding-bottom: 100%;
	}

	@include bp($point_2) {
		width: 15em;
		height: 15em;
	}

	@include bp($point_6) {
		width: 12em;
		height: 12em;
	}

	&.v1_mod {
		margin-bottom: 2.2em;

		@include bp($point_3) {
			margin: 0 2em 0 0;
			flex: 0 0 auto;
		}
	}

	&.v2_mod {
		margin-bottom: 2.8em;
	}
}

.circle_item_img {
	position: absolute;
	top: 0;
	left: 0;

	display: block;
	width: 100%;
	height: 100%;

	object-fit: cover;
}

.circle_item_info_w {
	position: relative;

	padding-left: 3.2em;

	//@include bp($point_6) {
	//	padding: 4em 0 0;
	//	text-align: center;
	//}
}

.item_info_icon_w {
	position: absolute;
	top: .4em;
	left: 0;

	width: 2em;
	height: 2em;

	//@include bp($point_6) {
	//	right: 0;
	//	margin: auto;
	//}
}

.item_info_icon {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: contain;
}

.circle_item_title {
	color: $white;
	font-size: 1.6em;
	font-weight: 700;
	line-height: (2.8/1.6);

	&.center_text_mod {
		text-align: center;
	}

	&.color_mod {
		color: #1c1c1c;
	}

	@include bp($point_7) {
		font-size: 1.4em;
	}
}

.info_items_w {}

.info_item {
	color: $white;
	font-size: 1.6em;
	font-weight: 400;
	line-height: (2.8/1.6);

	@include bp($point_7) {
		font-size: 1.4em;
	}
}
