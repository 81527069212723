.section_content_w {
	width: 100%;

	p {
		width: 100%;
		margin-bottom: (3em/2);

		color: $black_3;
		font-family: $font_1;
		font-size: 2em;
		font-weight: 400;
		line-height: 1.6;

		&:last-child {
			margin-bottom: 0;
		}
	}

	ul {
		margin: 0;
		padding: 0;

		list-style: none;
	}

	li {
		color: $black_3;
		font-family: $font_1;
		font-size: 2em;
		font-weight: 400;
		line-height: 1.8;
	}

	a {
		color: $black;
	}
}

.policy_page_links {
	padding: 1em 0 3.3em;
}

.policy_page_link {
	font-size: 2.2em;
	font-style: italic;
	font-weight: 600;
	cursor: pointer;

	&.policy_page_links_mod {
		color: #ccc;
	}
}

.policy_page_link_span {
	padding: 0 1.8em;
	min-height: 100%;
	font-size: 2em;
	color: #aaa;
}