.info_entry {
	@extend %global_flex_block_row_nowrap_flex-start_flex-end;

	position: relative;
	padding: 0;
	overflow: hidden;

	@include bp($point_3) {
		display: block;

		&.img_left_mod {
			display: flex;
			flex-direction: column-reverse;
		}
	}
}

.info_entry_img_wrap {
	position: relative;
	width: 38.6em;

	&.size_two_mod {
		width: 41.2em;
	}

	&:nth-child(1) {
		margin-right: 8em;
		margin-left: 9.4em;

		@include bp($point_3) {
			margin: 1em auto 0;
		}

		@include bp($point_6) {
			width: 100%;
		}
	}

	&:nth-child(2) {
		margin-left: 4.4em;

		@include bp($point_3) {
			margin: 1em auto 0;
		}

		@include bp($point_6) {
			width: 100%;
		}
	}
}

.info_entry_img {
	display: block;
	width: 100%;
	height: 100%;
}

.info_entry_content_wrap {
	display: flex;
	width: 50em;

	@include bp($point_3) {
    display: block;
    width: 100%;

    //text-align: center;

    // .btn_info_entry {
    //     margin: 0 auto;
    // }
	}
}

.info_entry_content_in {
	@extend %global_flex_block_column_nowrap_center_stretch;

	width: 100%;
	min-height: 36.4em;
	padding: 1.4em 0;

	@include bp($point_3) {
		min-height: auto;
		align-content: center;
	}
}

.info_entry_title {
	margin: 0 0.8em 2px 0;

	font-size: 3.5em;
	font-weight: 300;
	font-style: italic;
	line-height: 1.3;
	color: $white;

	letter-spacing: -0.005em;

	@include bp($point_3) {
		margin-right: 0;
	}
}

.info_entry_text {
	margin: 0 0 1.6em;

	font-size: 1.6em;
	font-weight: 400;
	line-height: 2.15;
	color: $white;
}
