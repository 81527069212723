.hero_content {
	max-width: 56.6em;
}

.hero_news_block_title {
	display: inline-flex;
	padding-right: (1.2em/1.6);
	padding-left: (1.2em/1.6);
	margin-bottom: (1em/1.6);

	font-size: 1.6em;
	line-height: 2.8rem;
	color: $white;
	text-transform: uppercase;

	background-color: $green;
	border-radius: .5rem;
}

.hero_news_block {
	padding-top: 3em;
	margin-top: 4.4em;
	border-top: 1px solid $gray_3;
	@include bp($point_4 - 1) {
		padding-top: 2em;
		margin-top: 2em;
	}
}

.hero_news_link {
	display: block;
	margin-bottom: .2em;
	//max-width: (28em/1.9);

	font-size: 1.9em;
	line-height: 1.5;
	color: $dark_blue;

	&.color_mod {
		color: black;
	}
}

.hero_social_block {
	position: relative;
	margin-top: 1.4em;
	padding-bottom: 1.8em;
	&:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 5.4em;
		height: .8em;
		background: url('../images/dots.svg') no-repeat;
		background-size: 100% 100%;
	}
}

.hero_social_link {
	font-size: 1.4em;
	color: $gray;
}

.hero_download_list {
	@extend %global_flex_block_row_nowrap_flex-start;
	margin-top: 2.4em;
}

.hero_download_item {
	width: 6em;
	height: 6em;
	margin-right: 2em;
	&:last-child {
		margin-right: 0;
	}
}

.hero_download_img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center;
}

.hero_btn_wrap {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.hero_download_title {
	margin-top: (5em/1.6);

	font-size: 1.6em;
	font-weight: 700;
}

.hero_logos_list {
	@extend %global_flex_block_row_wrap_flex-start_center;
	margin-bottom: 3.6em;
	@include bp($point_3) {
		margin-bottom: 2.6em;
	}
	@include bp($point_6) {
		flex-direction: column;
		align-items: flex-end;
		align-content: flex-end;
	}
}

.hero_logos_list_v2 {
	@extend %global_flex_block_row_wrap_flex-start_center;
	margin-bottom: 6.6em;
	@include bp($point_5) {
		margin-bottom: 2.6em;
		justify-content: center;
	}
}

.hero_logos_item {
	position: relative;

	height: 6em;
	max-width: 21.2em;
	padding-right: 2.2em;
	margin-right: 3em;

	&:after {
		content: '';
		position: absolute;
		right: 0;
		top: 50%;

		width: 1px;
		height: 2.2em;
		margin-top: -1.1em;

		background-color: #a8a8a8;
		@include bp($point_6) {
			display: none;
		}
	}

	&:last-child {
		margin-right: 0;
		padding-right: 0;
		&:after {
			display: none;
		}
	}
	@include bp($point_4) {
		height: 4em;
		max-width: 12.6em;
		margin-bottom: 1em;
	}
	@include bp($point_6) {
		margin-right: 0;
		padding-right: 0;
	}
}

.hero_logos_item_v2 {
	position: relative;

	height: 6em;
	max-width: 21.2em;
	padding-right: 2.2em;
	margin-right: 2em;

	&:after {
		content: '';
		position: absolute;
		right: 0;
		top: 50%;

		width: 1px;
		height: 2.2em;
		margin-top: -1.1em;

		background-color: #a8a8a8;
		// @include bp($point_6) {
		// 	display: none;
		// }
	}

	&:last-child {
		margin-right: 0;
		padding-right: 0;
		&:after {
			display: none;
		}
	}
	@include bp($point_4) {
		height: 5em;
		max-width: 12.6em;
		margin-bottom: 1em;
	}
	// @include bp($point_6) {
	// 	margin-right: 0;
	// 	padding-right: 0;
	// }
}

.hero_logos_img {
	height: 100%;
	max-width: 100%;
	object-fit: contain;
}

.btn_icon_wrap {
	display: inline-flex;
	width: 2.2rem;
	height: 2.2rem;
	margin-right: 1rem;
}

.btn_icon {
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center;
}

.hero_news_item {
	margin-right: 1em;
}

.hero_news_slider_dots {
	margin-top: 1em;

	@include bp($point_5) {
		width: 100%;
		display: flex;
		justify-content: center;
	}
}

.hero_news_slider {
	&.mobile_width_mod {
		@include bp($point_4) {
			max-width: 60vw;
		}
		@include bp($point_5) {
			max-width: unset;
		}
	}
}
