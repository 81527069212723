.about_healing_music {
	//@extend %global_flex_block_row_wrap_flex-start;
	padding-top: 5.2em;


	@include bp($point_4 - 1) {
		padding-top: 0;
	};
}

.about_healing_music_col {
	@extend %global_flex_block_row_nowrap_flex-start_stretch;
	width: 100%;
	margin-bottom: 9em;

	&:last-child {
		margin-bottom: 0;
	}

	@include bp($point_1) {
		margin-bottom: 4em;
		flex-flow: wrap;
	}
}

.about_healing_music_sound_wave_wrap {
	position: relative;
	// margin-left: -2.4em;
	margin-top: -4em;
	width: 100%;
	max-width: 61.2em;

	&.right_mod {
		order: 2;
	}

	@include bp($point_1) {
		margin: 4em 0 0;
		order: 2;
	}

	@include bp($point_2) {
		max-width: none;
	}
}

.about_healing_music_sound_wave {
	width: 100%;
	max-width: 100%;
	margin: 0 auto;

	&.sm_mod {
		width: 39.4em;
	}

	&.size_2_mod {
		width: 44em;
	}
}

.about_healing_music_sound_wave_img {
	display: block;
	width: 100%;
	height: auto;
}

.about_healing_music_sound_wave_title {
	font-size: 1.6em;
	color: #38659e;
	font-weight: 700;

	&.color_mod {
		color: #2e79bd;
		text-align: center;
	}

	@include bp($point_4 - 1) {
		text-align: center;
	}
}

.about_healing_music_sound_wave_text {
	font-size: 1.4em;
	line-height: 1.8;
	color: #2d2d2d;

	@include bp($point_4 - 1) {
		text-align: center;
	}
}

.about_healing_music_sound_wave_bot {
	text-align: center;
}

.about_healing_music_sound_wave_top_item {
	width: 13em;
	& + & {
		margin-left: auto;
	}

	@include bp($point_4 - 1) {
		width: 100%;

		&.color_mod {
			order: 2;
		}

		& + & {
			margin-top: 2em;
		}
	}
}

.about_healing_music_sound_wave_top {
	@extend %global_flex_block_row_wrap_flex-start;

	@include bp($point_4 - 1) {
		margin-bottom: 2em;
	}
}

.about_healing_music_content_block {
	&.left_mod {
		width: 100%;
		max-width: 61em;
		padding-left: .2em;
		margin-left: auto;

		@include bp($point_1) {
			max-width: none;
			margin: 0 0 4em;
			padding-left: 0;
		}
	}

	&.right_mod {
		width: 100%;
		max-width: 64.4em;
		margin-right: 2em;

		@include bp($point_1) {
			max-width: none;
			margin-left: 0;
			padding-left: 0;
		}
	}

	&.music_mod {
		max-width: 61em;
	}
}

.content_block_in {
	width: 100%;
	max-width: 54.2em;

	@include bp($point_1) {
		max-width: none;
	}
}

.about_healing_music_musiciants_wrap {
	@extend %global_flex_block_row_nowrap_center_baseline;

	width: 100%;

	@include bp($point_1) {
		margin: 4em -.8em 0;
	}

	@include bp($point_4) {
		flex-wrap: wrap;
	}

	@include bp(1280px, min) {
		max-width: 64.4em;
		margin-left: auto;
	}
}

.musiciant_item {
	display: block;
	width: 25%;
	padding: 0.8em;

	@include bp($point_4) {
		width: 50%;
	}

	@include bp($point_7) {
		width: 100%;
	}
}

.musiciant_item_photo_wrap {
	margin-bottom: 1.4em;
	display: block;
	width: 100%;
}

.musiciant_item_photo {
	display: block;
	width: 100%;
}

.musiciant_item_name {
	margin-bottom: 2px;

	font-size: 1.6em;
	font-weight: 700;
	color: $black_4;
}

.musiciant_item_descr {
	font-size: 1.3em;
	font-weight: 400;
	line-height: 2;
	color: $black_4;
}

//----------------- slider

.about_healing_slider_block {
	position: relative;

	margin-top: 6.6em;

	max-width: 114em;
	margin: 3em auto 0;
}

.about_healing_slider {
	position: relative;

	//&:after {
	//	content: '';
	//
	//	position: absolute;
	//	top: 100%;
	//	left: 50%;
	//
	//	height: 2.8em;
	//	width: calc(100% - 48em);
	//	transform: translateX(-50%);
	//
	//	background: url('../images/ipad_shadow.png') no-repeat top center;
	//	background-size: 100% auto;
	//}
}

.about_healing_slider_item {
	position: relative;
}

.about_healing_slider_img_wrap {
	position: relative;

	// width: 66em;
	// height: 50em;

	width: calc(100% - 44em);

	margin: 0 auto;

	//background-color: #393939;
	border-radius: 1.8em;

	&:after {
		content: '';
		display: block;
		padding-bottom: 65%;
	}

	@include bp($point_3) {
		width: 100%;
	}
}

.about_healing_slider_img_decors {
	//&:before {
	//	content: '';
	//
	//	position: absolute;
	//	top: 50%;
	//	left: .6em;
	//
	//	width: 1.2em;
	//	height: 1.2em;
	//	margin-top: -.6em;
	//
	//	border-radius: 50%;
	//	background-color: #686868;
	//	box-shadow: 0 0 0 .5px $black;
	//}
	//
	//&:after {
	//	content: '';
	//
	//	position: absolute;
	//	top: 50%;
	//	right: 1em;
	//
	//	width: 2.5em;
	//	height: 2.5em;
	//	margin-top: -1.25em;
	//
	//	border-radius: 50%;
	//	background-color: #4a4a4a;
	//}
}

.about_healing_slider_img {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: auto;

	//object-fit: cover;
	//object-position: top left;
}

.about_healing_slider_text_item {
	position: absolute;
	max-width: 24em;

	//&.line_mod {
	//	&:after {
	//		content: '';
	//
	//		position: absolute;
	//		top: 50%;
	//
	//		width: 3.6em;
	//		height: 1px;
	//
	//		box-shadow: 0 0 0 .5px $black;
	//		background-color: #3673b1;
	//
	//		@include bp($point_3) {
	//			display: none;
	//		}
	//	}
	//}

	&.left_mod {
		left: 0;
		padding-right: 4em;
		&:after {
			right: -.4em;
		}

		@include bp($point_3) {
			padding-right: 0;
		}
	}


	&.right_mod {
		right: 0;

		padding-left: 4em;

		&:after {
			width: 3.4em;
			left: 0;
		}

		@include bp($point_3) {
			padding-left: 0;
		}
	}

	&.top_mod {
		top: 15%;
	}

	&.top_0_mod {
		top: 0;
	}

	&.bot_mod {
		bottom: 13%;
	}

	&.center_mod {
		top: 50%;
		transform: translateY(-50%);
	}

	@include bp($point_3) {
		position: static;

		display: none;
		max-width: 100%;
		margin-bottom: 2em;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.about_healing_slider_info_img_wrap {
	width: 100%;
	max-width: 13.2em;
	height: 3.2em;
	margin-bottom: .2em;
}

.about_healing_slider_info_img {
	width: 100%;
	height: 100%;
}


.about_healing_slider_info {
	font-size: 1.4em;
	line-height: 1.8;
	font-weight: 700;
	color: #4675b0;
}

.about_healing_slider_dots {
	margin: 1em 0 2em;
}

.about_slider_download {
	@extend %global_flex_block_row_nowrap_center_center;

	position: absolute;
	bottom: 3.4em;
	right: 0;

	width: 24em;
	padding: 0 4em;

	@include bp($point_3) {
		position: static;
		width: 100%;
		margin-top: 3em;
	}
}

.about_slider_download_item {
	& + & {
		margin-left: 2.2em;
	}
}

.about_slider_download_link {
	display: block;
	width: 3.2em;
}

.about_slider_download_img {
	width: 100%;
}
