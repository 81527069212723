.about_treatment {

}

.about_treatment_in {
	@extend %global_flex_block_row_wrap_flex-start;
}

.about_treatment_col {

	@include bp($point_2, min) {
		width: 50%;
	}

	&.info_mod {

		@include bp($point_2, min) {
			padding-right: 3em;
		}

		@include bp($point_3) {
			padding-bottom: 3em;
		}

		.about_treatment_in.reverse_mod & {
			order: 2;

			@include bp($point_2, min) {
				padding: 1.8em 4em 0 2em;
			}

			@include bp($point_3) {
				padding: 0;
			}
		}

	}

	&.graph_mod {

		@include bp($point_2, min) {
			padding: 1.8em 4em 0 2em;
		}

		.about_treatment_in.reverse_mod & {
			order: 1;
			@include bp($point_2, min) {
				padding: 0 3em 0 0;
			}

			@include bp($point_3) {
				padding-bottom: 3em;
			}
		}

	}


}

.about_treatment_graph_w_img {
	max-width: 34.4em;
	width: 100%;
	height: 30.6em;
	margin-bottom: 5.6em;

	&.full_size_mod {
		max-width: 100%;
		height: auto;
		margin-left: 0;
	}

	@include bp($point_2, min) {
		margin-left: 5.8em;
	}

	@include bp($point_3) {
		margin-left: auto;
		margin-right: auto;
	}

	@include bp($point_4 - 1) {
		height: auto;
	}

}

.about_treatment_graph_img {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: contain;
}

.about_treatment_w_logo {
	display: block;
	width: 15.8em;
	height: 4.4em;

	@include bp($point_2, min) {
		margin: 0 -4em 3em auto;
	}

	@include bp($point_3) {
		margin: 0 auto 4em;
	}

}

.about_treatment_logo {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: cover;
}
