.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	fill: currentColor;
}

.icon-check {
	font-size:(32/10)*1rem;
	width:(32/32)*1em;
}
.icon-empty {
	font-size:(45.44/10)*1rem;
	width:(45.44/45.44)*1em;
}
.icon-facebook {
	font-size:(49.65/10)*1rem;
	width:(49.65/49.65)*1em;
}
.icon-twitter {
	font-size:(49.65/10)*1rem;
	width:(49.65/49.65)*1em;
}
.icon-youtube {
	font-size:(97.75/10)*1rem;
	width:(97.75/97.75)*1em;
}
