@charset "UTF-8";
.social_list.footer_mod, .footer_top, .form_block, .about_treatment_in, .studies_table_row, .studies_table_info_w_block, .about_healing_music_sound_wave_top, .reviews_nav, .reviews_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start; }

.plans_list, .company_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch; }

.section.center_level_mod, .hero_logos_list, .hero_logos_list_v2, .half_logos, .quotes_block, .studies_table_nav, .reviews_w_nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center; }

.reviews_block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-end;
  align-items: flex-end; }

.clinical_studies_logos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-content: center;
  align-items: center; }

.cookies_btns, .award_list, .circle_item_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start; }

.disease_item.flex_mod, .award_img_wrap, .banner_logos_list, .treatment_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center; }

.treatment_description {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: flex-start; }

.menu_wrap, .main_menu, .system_menu, .hero_download_list, .slick-dots {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start; }

.about_healing_music_col {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch; }

.header, .header_in, .menu_in, .footer_social_list, .section.hero_mod, .section_in.flex_mod, .diseases_wrap, .plans_price_container, .section_col.logos_mod, .healing_properties_row, .healing_properties_info_block, .healing_properties_info_block_v2 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center; }

.info_entry {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-end; }

.disease_logo {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-content: center;
  align-items: center; }

.section_cols {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-content: flex-start;
  align-items: stretch; }

.btn_base.flex_mod, .popup_video_wrap, .show, .about_slider_download, .popup {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center; }

.about_healing_music_musiciants_wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: flex-start;
  align-items: baseline; }

.healing_properties_charts_block {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: flex-start; }

.award_item, .treatment_item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center; }

.section.banner_mod {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center; }

.company_list_item {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch; }

.section_in.banner_mod, .plans_button, .plans_multi_button, .plans_bottom, .banner_link, .circle_item_in {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center; }

.section.info_entry_mod {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-content: flex-start;
  align-items: flex-start; }

.half_logo_list {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: flex-start;
  align-items: center; }

.info_entry_content_in {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: flex-start;
  align-items: stretch; }

.bgImg {
  transition: opacity .3s ease;
  will-change: opacity; }

.btn_base, .btn_plan, .btn_form, .btn_sanofi_blue {
  font-weight: 700;
  color: #fff;
  background-color: #0071be;
  transition: .3s ease;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  text-align: center; }
  .btn_base:hover, .btn_plan:hover, .btn_form:hover, .btn_sanofi_blue:hover, .btn_base:focus, .btn_plan:focus, .btn_form:focus, .btn_sanofi_blue:focus {
    text-decoration: none; }
  .btn_base:hover, .btn_plan:hover, .btn_form:hover, .btn_sanofi_blue:hover {
    background-color: #00538b; }

.btn_v2_base, .btn_lg, .btn_info_entry {
  font-weight: 700;
  color: #fff;
  background: #6aade8;
  transition: .3s ease;
  display: inline-block;
  white-space: nowrap;
  text-align: center; }
  .btn_v2_base:hover, .btn_lg:hover, .btn_info_entry:hover, .btn_v2_base:focus, .btn_lg:focus, .btn_info_entry:focus {
    text-decoration: none; }
  .btn_v2_base:hover, .btn_lg:hover, .btn_info_entry:hover {
    background: #3e95e1; }

.btn_video {
  font-weight: 700;
  color: #fff;
  background-color: #658ec4;
  transition: .3s ease;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  text-align: center; }
  .btn_video:hover, .btn_video:focus {
    text-decoration: none; }
  .btn_video:hover {
    background-color: #00538b; }

.btn_video_white, .btn_plan_popup, .btn_sanofi_white, .btn_v2 {
  font-weight: 700;
  color: #4879b7;
  background: transparent;
  transition: .3s ease;
  border: 1px solid #4879b7;
  display: inline-block;
  white-space: nowrap;
  text-align: center; }
  .btn_video_white:hover, .btn_plan_popup:hover, .btn_sanofi_white:hover, .btn_v2:hover, .btn_video_white:focus, .btn_plan_popup:focus, .btn_sanofi_white:focus, .btn_v2:focus {
    text-decoration: none; }
  .btn_video_white:hover, .btn_plan_popup:hover, .btn_sanofi_white:hover, .btn_v2:hover {
    background: #4879b7;
    color: #fff; }

.btn_v3 {
  font-weight: 700;
  color: #fff;
  background: transparent;
  transition: .3s ease;
  border: 1px solid #fff;
  display: inline-block;
  white-space: nowrap;
  text-align: center; }
  .btn_v3:hover, .btn_v3:focus {
    text-decoration: none; }
  .btn_v3:hover {
    background: #fff;
    color: #4879b7; }

.btn_base, .btn_info_entry {
  height: 4.8rem;
  width: 18.4rem;
  padding: 0 1.2rem;
  line-height: 4.8rem;
  font-size: 1.6rem;
  border-radius: 2.4rem; }

.btn_form {
  height: 4rem;
  width: 100%;
  padding: 0 1.2rem;
  line-height: 4rem;
  font-size: 1.6rem;
  border-radius: 0; }

.btn_lg {
  height: 5.2rem;
  width: 100%;
  max-width: 31.4rem;
  padding: 0 1.2rem;
  line-height: 5.2rem;
  font-size: 1.6rem;
  border-radius: 0; }

.btn_v2_base, .btn_video, .btn_video_white, .btn_sanofi_white {
  height: 4.8rem;
  width: 20rem;
  padding: 0 1.2rem;
  line-height: 4.5rem;
  font-size: 1.6rem;
  border-radius: 2.4rem; }

.btn_v2 {
  height: 3.8rem;
  width: 16.4rem;
  padding: 0 1.2rem;
  line-height: 3.4rem;
  font-size: 1.6rem;
  border-radius: 1.9rem; }

.btn_v3 {
  height: 2.8rem;
  width: auto;
  padding: 0 2.4rem;
  line-height: 2.6rem;
  font-size: 1.4rem;
  border-radius: 1.4rem; }

.btn_plan {
  height: 5.2rem;
  width: 100%;
  max-width: 31.4rem;
  padding: 0 1.2rem;
  line-height: 5.2rem;
  font-size: 1.6rem;
  border-radius: 1rem; }

.btn_plan_popup {
  height: 5.2rem;
  width: 100%;
  max-width: 31.4rem;
  padding: 0 1.2rem;
  line-height: 5.2rem;
  font-size: 1.6rem;
  border-radius: 15px; }

.btn_sanofi_blue {
  height: 4.8rem;
  width: 30rem;
  padding: 0 1.2rem;
  line-height: 4.5rem;
  font-size: 1.6rem;
  border-radius: 2.4rem; }

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video, button {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box; }
  html:before, html:after, body:before, body:after, div:before, div:after, span:before, span:after, object:before, object:after, iframe:before, iframe:after,
  h1:before,
  h1:after, h2:before, h2:after, h3:before, h3:after, h4:before, h4:after, h5:before, h5:after, h6:before, h6:after, p:before, p:after, blockquote:before, blockquote:after, pre:before, pre:after,
  abbr:before,
  abbr:after, address:before, address:after, cite:before, cite:after, code:before, code:after,
  del:before,
  del:after, dfn:before, dfn:after, em:before, em:after, img:before, img:after, ins:before, ins:after, kbd:before, kbd:after, q:before, q:after, samp:before, samp:after,
  small:before,
  small:after, strong:before, strong:after, var:before, var:after,
  b:before,
  b:after, i:before, i:after,
  dl:before,
  dl:after, dt:before, dt:after, dd:before, dd:after, ol:before, ol:after, ul:before, ul:after, li:before, li:after,
  fieldset:before,
  fieldset:after, form:before, form:after, label:before, label:after, legend:before, legend:after,
  article:before,
  article:after, aside:before, aside:after, canvas:before, canvas:after, details:before, details:after, figcaption:before, figcaption:after, figure:before, figure:after,
  footer:before,
  footer:after, header:before, header:after, hgroup:before, hgroup:after, menu:before, menu:after, nav:before, nav:after, section:before, section:after, summary:before, summary:after,
  time:before,
  time:after, mark:before, mark:after, audio:before, audio:after, video:before, video:after, button:before, button:after {
    box-sizing: border-box; }

sub,
sup {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  background: transparent;
  box-sizing: border-box; }
  sub:before, sub:after,
  sup:before,
  sup:after {
    box-sizing: border-box; }

a:before, a:after {
  box-sizing: border-box; }

table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  background: transparent; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

ol, ul, li {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: none; }

q:before, q:after {
  content: none; }

:focus {
  outline: 0; }

input[type="text"], textarea {
  -webkit-appearance: none; }
  input[type="text"]::-ms-clear, textarea::-ms-clear {
    display: none; }

input,
textarea {
  border-radius: 0;
  box-sizing: border-box; }
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #909090; }
  input::-moz-placeholder,
  textarea::-moz-placeholder {
    color: #909090; }
  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: #909090; }
  input:focus::-webkit-input-placeholder,
  textarea:focus::-webkit-input-placeholder {
    color: transparent; }
  input:focus::-moz-placeholder,
  textarea:focus::-moz-placeholder {
    color: transparent; }
  input:focus:-ms-input-placeholder,
  textarea:focus:-ms-input-placeholder {
    color: transparent; }

textarea {
  resize: none; }

ins {
  text-decoration: none; }

del {
  text-decoration: line-through; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box;
  word-wrap: break-word; }

@font-face {
  font-family: "Bariol";
  src: url("../fonts/Bariol-Thin.woff2") format("woff2"), url("../fonts/Bariol-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: "Bariol";
  src: url("../fonts/Bariol-Light.woff2") format("woff2"), url("../fonts/Bariol-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Bariol";
  src: url("../fonts/Bariol-Regular.woff2") format("woff2"), url("../fonts/Bariol-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Bariol";
  src: url("../fonts/Bariol-Bold.woff2") format("woff2"), url("../fonts/Bariol-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Bariol";
  src: url("../fonts/BariolThin-Italic.woff2") format("woff2"), url("../fonts/BariolThin-Italic.woff") format("woff");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: "Bariol";
  src: url("../fonts/BariolLight-Italic.woff2") format("woff2"), url("../fonts/BariolLight-Italic.woff") format("woff");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: "Bariol";
  src: url("../fonts/BariolRegular-Italic.woff2") format("woff2"), url("../fonts/BariolRegular-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "Bariol";
  src: url("../fonts/BariolBold-Italic.woff2") format("woff2"), url("../fonts/BariolBold-Italic.woff") format("woff");
  font-weight: 700;
  font-style: italic; }

html {
  font: 400 10px/1.33 "Bariol", sans-serif; }

body {
  position: relative;
  display: flex;
  font-size: 1.6rem;
  color: #000;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff; }
  @supports (display: grid) {
    body {
      display: block; } }
  body.popup_open, body.menu_open, body.popup_mod {
    overflow: hidden; }

.wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto !important;
  overflow: hidden; }

.base {
  width: 100%;
  position: relative;
  padding: 0 0 0;
  flex: 1 0 auto;
  grid-area: base; }
  .base.no_offset_mod {
    padding-top: 0; }

a {
  text-decoration: none;
  color: #4aa3df; }
  a:hover, a:focus {
    text-decoration: underline; }

.elements_list {
  padding: 3rem; }

.social_list.footer_mod {
  margin: -.2em -1em 1em 0; }
  @media screen and (max-width: 374px) {
    .social_list.footer_mod {
      justify-content: center; } }

.social_item {
  padding: 0 .8em 0 0; }

.social_link {
  transition: opacity .3s ease; }
  .social_link:hover, .social_link:focus {
    opacity: .6;
    text-decoration: none; }

.section_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: -1; }
  @media screen and (max-width: 1023px) {
    .section_bg.banner_mod::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      opacity: 0.5;
      background-color: #fff;
      z-index: 1; }
    .banner_v2 .section_bg:before {
      display: none; } }

.section_bg_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; }

.btn_v2_base.offset_mod {
  margin-top: 1.875em; }

.btn_video {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center; }
  .btn_video.offset_mod {
    margin-top: 0.625em; }
  .btn_video.remove_italic {
    font-style: normal; }

.btn_video_white {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center; }
  .btn_video_white.offset_mod {
    margin-top: 0.625em; }

.btn_img {
  width: 1.5em;
  height: 1.5em;
  margin-right: 8px; }
  .btn_img.filter_mod {
    filter: invert(44%) sepia(6%) saturate(4835%) hue-rotate(175deg) brightness(91%) contrast(79%); }

.btn_plan_popup {
  background-color: #e5eaee;
  cursor: pointer; }
  .btn_plan_popup.top {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .btn_plan_popup.middle {
    border-top: none;
    border-radius: 0; }
  .btn_plan_popup.bottom {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn_lg.center_mod {
  margin: 0 auto; }

.btn_info_entry.signup_mod {
  margin-left: 1em; }

.btn_v2.offset_top_mod {
  margin-top: .6em; }
  @media screen and (max-width: 1200px) {
    .btn_v2.offset_top_mod {
      margin-top: 1.2em; } }

.btn_v3 + .btn_v3 {
  margin-left: 3.2rem; }
  @media screen and (max-width: 480px) {
    .btn_v3 + .btn_v3 {
      margin-left: 0;
      margin-top: 1em; } }

.btn_watch {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-left: 3.69231em;
  height: 2.76923em;
  color: #0071be;
  font-size: 1.3em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: .12rem;
  opacity: 1;
  transition: opacity .3s;
  cursor: pointer; }
  .btn_watch:before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 2.76923em;
    height: 2.76923em;
    margin-top: -1.38462em;
    background: url("../images/play_icon.png") no-repeat center center;
    background-size: contain;
    content: ''; }
  .btn_watch.lg_mod {
    margin-top: 2rem;
    width: 6.2rem;
    height: 6.2rem;
    padding: 0; }
    .btn_watch.lg_mod:before {
      width: 6.2rem;
      height: 6.2rem;
      margin-top: -3.1rem;
      background: url("../images/play_white.png") no-repeat center center;
      background-size: contain; }
  .btn_watch.no_title_mod {
    width: 2.92308em;
    height: 2.92308em;
    padding-left: 0; }
    .btn_watch.no_title_mod:before {
      width: 100%;
      height: 100%; }
  .btn_watch:hover, .btn_watch:focus {
    opacity: .8; }

.video_preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; }
  @media screen and (max-width: 1023px) {
    .video_preview:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7);
      content: '';
      z-index: 1; } }

.video_preview_in {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 0% 50%; }

.video_preview_image {
  width: 45em;
  margin: 0 2em;
  display: block;
  max-width: 100vw; }
  .video_preview_image.width_mod {
    width: 42%; }
    @media screen and (max-width: 480px) {
      .video_preview_image.width_mod {
        width: 80%; } }
    @media screen and (max-width: 375px) {
      .video_preview_image.width_mod {
        width: 90%; } }

.video_block_container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  color: white; }
  @media screen and (max-width: 768px) {
    .video_block_container {
      flex-direction: column;
      text-align: center; } }

.video_info_block {
  position: relative;
  max-width: 51.4em; }
  @media screen and (min-width: 1024px) {
    .video_info_block {
      margin-left: 600px; } }
  @media screen and (min-width: 1024px) {
    .video_info_block.center_mod {
      text-align: center;
      margin: 0 auto; } }
  @media screen and (max-width: 1023px) {
    .video_info_block {
      margin: 0 auto; } }
  .video_info_block.left_level_mod {
    margin-left: 0; }

.video_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 3s;
  z-index: 10; }
  .video_wrapper.opacity_mod {
    opacity: 0; }

.video_player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.popup_video_wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 4em;
  font-size: 1rem;
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s; }
  body.popup_mod .popup_video_wrap {
    opacity: 1;
    pointer-events: initial; }
  .popup_video_wrap.hide {
    display: none; }

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: -1; }

.popup_video {
  position: relative;
  background-color: #fff;
  width: 112.8em;
  max-width: 100%; }

.popup_video_close {
  position: absolute;
  right: 0;
  bottom: calc(100% + 1em);
  width: 2em;
  height: 2em;
  background: url("../images/close.svg") no-repeat center center;
  background-size: contain;
  z-index: 2; }
  .popup_video_close:hover, .popup_video_close:focus {
    opacity: .8; }

.bgImg {
  opacity: 0; }
  .bgImg.loading, .bgImg.loaded {
    opacity: 1; }

.cookies_block {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2em;
  font-size: 1rem;
  color: #fff;
  text-align: center;
  opacity: 1;
  transform: translateY(0);
  transition: all .3s;
  z-index: 1003; }
  .cookies_block:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0071be;
    z-index: -1;
    opacity: .8; }
  .cookies_block.hidden_mod {
    opacity: 0;
    transform: translateY(100%); }

.cookies_block_in {
  max-width: 104.8em;
  margin: 0 auto; }

.cookies_text {
  font-size: 1.4em;
  line-height: 1.64286; }

.cookies_descr {
  margin-top: 0.14286em;
  font-size: 1.4em;
  color: #b9d4fe; }

.cookies_btns {
  margin-top: 1.4em; }
  @media screen and (max-width: 480px) {
    .cookies_btns {
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center; } }

.header {
  position: fixed;
  top: 0;
  left: 0;
  height: 10.2rem;
  width: 100%;
  font-size: 1rem;
  will-change: transform;
  z-index: 100;
  background: transparent;
  transition: background-color .3s ease; }
  .header.scroll_mod {
    background-color: rgba(255, 255, 255, 0.9); }
  @media screen and (max-width: 1200px) {
    .header {
      height: 6.2rem; } }

.header_in {
  max-width: 130.8rem;
  width: 100%;
  margin: 0 auto; }
  @media screen and (min-width: 1024px) {
    .header_in {
      padding: 0 3rem; } }
  @media screen and (max-width: 1023px) {
    .header_in {
      padding: 0 2em; } }

.logo {
  width: 12em;
  height: 6.2em;
  flex-shrink: 0; }
  @media screen and (max-width: 1200px) {
    .logo {
      height: 4.2em;
      width: 8.2em; } }

.logo_img {
  display: block;
  max-width: 100%;
  max-height: 100%; }
  .header.scroll_mod .logo_img {
    display: none; }

.logo_img_scroll {
  display: none;
  max-width: 100%;
  max-height: 100%; }
  .header.scroll_mod .logo_img_scroll {
    display: block; }

.menu_wrap {
  flex-grow: 1;
  margin-left: 12.4em; }
  @media screen and (max-width: 1280px) {
    .menu_wrap {
      margin-left: 5em; } }
  @media screen and (max-width: 1200px) {
    .menu_wrap {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      margin-left: 0;
      width: 100%;
      height: 100vh;
      padding: 7.6em 2em 4em 3.2em;
      background-image: linear-gradient(-211deg, #0071be 0%, #0071be 100%);
      transform: translateY(-100%);
      transition: transform .3s; }
      body.menu_open .menu_wrap {
        transform: translateY(0); } }

.menu_wrap_in {
  width: 100%; }
  @media screen and (max-width: 1200px) {
    .menu_wrap_in {
      display: flex;
      height: 100%;
      width: 100%;
      overflow-y: auto; } }

.menu_in {
  width: 100%; }
  @media screen and (max-width: 1200px) {
    .menu_in {
      display: block;
      margin: auto; } }

@media screen and (max-width: 1200px) {
  .main_menu {
    flex-direction: column;
    width: 100%; } }

.main_menu_item {
  position: relative;
  margin-right: 2.8em; }
  .main_menu_item:last-child {
    margin-right: 0; }
  @media screen and (max-width: 1200px) {
    .main_menu_item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin: 0 0 .8em;
      text-align: center; } }
  @media screen and (max-width: 1023px) {
    .main_menu_item {
      justify-content: flex-start; } }

.main_menu_caret {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1rem;
  height: .6rem;
  margin-top: -1px;
  background: url("../images/caret_2.png") no-repeat center center;
  background-size: contain;
  transform: rotate(0);
  transform-origin: 50% 50%;
  transition: transform .3s; }
  .main_menu_caret.login_mod {
    right: -35%; }
  .header.white_mod:not(.scroll_mod) .main_menu_caret {
    background: url("../images/caret.png") no-repeat center center;
    background-size: contain; }
    .header.white_mod:not(.scroll_mod) .main_menu_caret.dark_mod {
      background: url("../images/caret_2.png") no-repeat center center;
      background-size: contain; }
  .main_menu_link.active_mod .main_menu_caret {
    transform: rotate(180deg); }
  @media screen and (max-width: 1200px) {
    .main_menu_caret {
      background: url("../images/caret.png") no-repeat center center;
      background-size: contain;
      display: none; } }

.main_menu_link {
  position: relative;
  display: block;
  padding-right: 1.25em;
  font-size: 1.6em;
  color: #0d0d0d; }
  .header.white_mod:not(.scroll_mod) .main_menu_link {
    color: #fff; }
    .header.white_mod:not(.scroll_mod) .main_menu_link.dark_mod {
      color: black; }
    .header.white_mod:not(.scroll_mod) .main_menu_link:hover, .header.white_mod:not(.scroll_mod) .main_menu_link:focus {
      color: #6aade8; }
      @media screen and (max-width: 1200px) {
        .header.white_mod:not(.scroll_mod) .main_menu_link:hover, .header.white_mod:not(.scroll_mod) .main_menu_link:focus {
          color: #fff; } }
  .main_menu_link.active_link {
    color: #6aade8 !important; }
  .main_menu_link:hover, .main_menu_link:focus {
    color: #0071be;
    text-decoration: none; }
    @media screen and (max-width: 1200px) {
      .main_menu_link:hover, .main_menu_link:focus {
        color: #fff; } }
  @media screen and (max-width: 1200px) {
    .main_menu_link {
      font-size: 3.7em;
      font-weight: 300;
      line-height: 1;
      text-align: left;
      color: #fff; } }

.system_menu {
  margin-left: auto; }
  @media screen and (max-width: 1200px) {
    .system_menu {
      margin-left: 0;
      width: 100%;
      flex-direction: column;
      margin-top: 9em; } }

.system_menu_item {
  margin-left: 1.8em; }
  .system_menu_item:first-child {
    margin-left: 0; }
  .system_menu_item:after {
    margin-left: 1em;
    color: #434343;
    font-size: 1.6em;
    content: 'I'; }
    .header.white_mod:not(.scroll_mod) .system_menu_item:after {
      color: #fff; }
    @media screen and (max-width: 1200px) {
      .system_menu_item:after {
        color: #fff;
        display: none; } }
  .system_menu_item:last-child:after {
    content: '';
    display: none; }
  @media screen and (max-width: 1200px) {
    .system_menu_item {
      margin-bottom: .4em;
      margin-left: 0; }
      .system_menu_item:last-child {
        margin-bottom: 0; } }

.system_menu_link {
  font-size: 1.6em;
  font-weight: 700;
  color: #434343;
  border-bottom: 1px solid transparent; }
  .header.white_mod:not(.scroll_mod) .system_menu_link {
    color: #fff; }
    .header.white_mod:not(.scroll_mod) .system_menu_link:hover, .header.white_mod:not(.scroll_mod) .system_menu_link:focus {
      border-color: #fff; }
  .system_menu_link.dark_mod {
    color: black !important; }
  .system_menu_link:hover, .system_menu_link:focus, .system_menu_link:active {
    text-decoration: none;
    border-color: #434343; }
  @media screen and (max-width: 1200px) {
    .system_menu_link {
      font-size: 2.5em;
      font-weight: 300;
      color: #fff; } }

.lang_wrap {
  margin-left: 2.6em; }
  @media screen and (max-width: 1200px) {
    .lang_wrap {
      display: flex;
      justify-content: center;
      margin: 2em 0 0; } }

.lang_link {
  display: block;
  width: 2.8em;
  height: 1.8em; }

.lang_img {
  width: 100%;
  height: 100%;
  object-fit: contain; }

.main_submenu {
  position: absolute;
  top: 100%;
  left: -1.4em;
  min-width: calc(100% + 2.8em);
  padding: 1.4em;
  background-color: #0071be;
  z-index: 20; }
  @media (min-width: 1024px) {
    .main_menu_item:hover .main_submenu {
      transform: translateY(0);
      opacity: 1;
      pointer-events: initial; } }
  @media (min-width: 1024px) {
    .main_submenu {
      transform: translateY(20%);
      opacity: 0;
      pointer-events: none;
      transition: .3s; } }
  @media screen and (max-width: 1023px) {
    .main_submenu.login_mod {
      align-items: flex-start;
      justify-items: flex-start;
      display: flex;
      flex-direction: column;
      position: relative;
      margin: -0.8em 0 -1em 1em;
      font-weight: 700; } }
  @media screen and (max-width: 1200px) {
    .main_submenu {
      position: static;
      display: none; } }

.main_submenu_item {
  margin-bottom: 1em; }
  .main_submenu_item:last-child {
    margin-bottom: 0; }

.main_submenu_link {
  font-size: 1.4em;
  color: #fff;
  white-space: nowrap; }

.menu_trigger {
  z-index: 210;
  position: absolute;
  top: 50%;
  right: 2rem;
  display: none;
  height: 1.6rem;
  width: 2.5rem;
  margin-top: -.8rem;
  text-transform: uppercase;
  color: transparent;
  cursor: pointer; }
  @media screen and (max-width: 1200px) {
    .menu_trigger {
      display: block; } }
  .menu_trigger:before {
    content: '';
    transform: translate(0, -0.8rem);
    transition: all .3s ease;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: .2rem;
    margin-top: -.1rem;
    background: #000; }
    .header.white_mod:not(.scroll_mod) .menu_trigger:before {
      background: #fff; }
  .menu_trigger:after {
    content: '';
    transform: translate(0, 0.8rem);
    transition: all .3s ease;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: .2rem;
    margin-top: -.1rem;
    background: #000; }
    .header.white_mod:not(.scroll_mod) .menu_trigger:after {
      background: #fff; }
  .menu_trigger.active_mod:before {
    transform: rotate(45deg) translate(0, 0);
    background-color: #fff; }
  .menu_trigger.active_mod:after {
    transform: rotate(-45deg) translate(0, 0);
    background-color: #fff; }

.menu_trigger_decor {
  transition: all .3s ease;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: .2rem;
  margin-top: -.1rem;
  background: #000; }
  .active_mod .menu_trigger_decor {
    opacity: 0;
    transform: translate(100%, 0); }
  .header.white_mod:not(.scroll_mod) .menu_trigger_decor {
    background: #fff; }

.footer {
  width: 100%;
  min-height: 10rem;
  padding: 3.6em 0 4em;
  flex: 0 0 auto;
  grid-area: footer;
  font-size: 1rem;
  will-change: transform;
  background: #0071be; }

.footer.v2_mod .footer_top {
  justify-content: center; }
  @media screen and (max-width: 1023px) {
    .footer.v2_mod .footer_top {
      justify-content: flex-start; } }

@media screen and (max-width: 1023px) {
  .footer_top {
    margin: 0 -1em; } }

.footer_menu_block {
  margin-right: auto;
  padding-bottom: 4.6em; }
  .footer_menu_block:last-child {
    margin-right: 0; }
  @media screen and (max-width: 1023px) {
    .footer_menu_block {
      margin-right: 0;
      width: 33.33%;
      padding: 0 1em 4.6em; } }
  @media screen and (max-width: 480px) {
    .footer_menu_block {
      width: 50%; } }
  @media screen and (max-width: 374px) {
    .footer_menu_block {
      width: 100%; } }

.footer_menu_title {
  margin-bottom: 1.66667em;
  height: 1.25em;
  font-size: 1.2em;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase; }
  @media screen and (max-width: 374px) {
    .footer_menu_title {
      text-align: center; } }

@media screen and (min-width: 1024px) {
  .footer_menu.double_mod {
    column-count: 2;
    column-gap: 4em; } }

.footer_menu_item {
  margin-bottom: 1.5em; }
  .footer_menu_item:last-child {
    margin-bottom: 0; }
  @media screen and (max-width: 374px) {
    .footer_menu_item {
      text-align: center; } }

.footer_menu_link {
  font-size: 1.4em;
  font-weight: 700;
  color: #fff; }

.footer_copyright {
  font-size: 1.2em;
  color: #fff; }
  .footer.v2_mod .footer_copyright {
    text-align: center; }
  @media screen and (max-width: 374px) {
    .footer_copyright {
      text-align: center; } }

.footer_social_list {
  margin-top: -.4em;
  margin-bottom: 1.4em; }
  @media screen and (max-width: 374px) {
    .footer_social_list {
      justify-content: center; } }

.footer_social_item {
  width: 2.4em;
  height: 2.4em;
  margin-right: .6em; }
  .footer_social_item:last-child {
    margin-right: 0; }

.footer_social_img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center; }

.form_field.default_mod {
  display: block;
  width: 100%;
  height: 4rem;
  padding: 0 1.4rem;
  font-size: 1.6rem;
  font-family: "Bariol", sans-serif;
  color: #000;
  box-shadow: inset 0 0 0 0.2rem #cfcfcf;
  background-color: transparent;
  border: none;
  border-radius: 0; }
  .form_field.default_mod::-webkit-input-placeholder {
    color: #909090; }
  .form_field.default_mod::-moz-placeholder {
    color: #909090; }
  .form_field.default_mod:-ms-input-placeholder {
    color: #909090; }
  @media screen and (max-width: 1023px) {
    .form_field.default_mod {
      box-shadow: inset 0 0 0 0.2rem #0071be; } }

.form_field.default_mod:focus {
  box-shadow: 0 0 0 .2rem #aaa; }
  @media screen and (max-width: 1023px) {
    .form_field.default_mod:focus {
      box-shadow: inset 0 0 0 0.2rem #6aade8; } }

.form_cell.hidden_field {
  display: none; }

.form_cell_title.hline_hide_mod {
  display: none; }

.form_field_wrap {
  position: relative; }

.form_field.textarea_mod_1 {
  height: 8rem;
  padding-top: 1rem; }

.form_field.form_v2_mod {
  padding-right: 4.8rem;
  height: 5.2rem;
  font-style: italic;
  background-color: transparent; }

.section {
  position: relative;
  width: 100%;
  padding: 4em 0;
  font-size: 1rem;
  z-index: 1; }
  .section.border_mod:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 92em;
    height: 1px;
    margin-left: -46em;
    background-color: #365985; }
  .section.hero_mod {
    height: 80vh;
    padding-top: 12em;
    padding-bottom: 4em; }
    @media screen and (max-width: 1023px) {
      .section.hero_mod {
        padding: 11em 0 6em; } }
    @media (max-height: 850px) {
      .section.hero_mod {
        height: auto; } }
  .section.download_mod {
    padding-top: 7.8em;
    padding-bottom: 11em; }
  .section.hero_title_mod {
    padding: 13.2em 0 5.4em; }
    @media screen and (max-width: 1023px) {
      .section.hero_title_mod {
        padding: 8.2em 0 3.2em; } }
  .section.sanofi_hero_title_mod {
    padding: 13.2em 0 5.4em;
    background: url("../images/sanofi_bg.jpg") no-repeat center center;
    background-size: cover; }
    @media screen and (max-width: 1023px) {
      .section.sanofi_hero_title_mod {
        padding: 8.2em 0 3.2em; } }
  .section.sanofi_hero_title_mod_v2 {
    padding: 2.2em 0em 10.4em 4em;
    background: url("../images/sanofi_bg.jpg") no-repeat center center;
    background-size: cover; }
    @media screen and (max-width: 1023px) {
      .section.sanofi_hero_title_mod_v2 {
        padding: 4.2em 0 3.2em; } }
  @media screen and (min-width: 768px) {
    .section.about_line_mod {
      padding-bottom: 15em; } }
  @media screen and (max-width: 767px) {
    .section.about_line_mod {
      padding-bottom: 8em; } }
  .section.award_mod {
    background-color: #eff3f6; }
  .section.diseases_mod {
    height: 22.8em; }
    @media screen and (max-width: 1023px) {
      .section.diseases_mod {
        height: auto; } }
  @media screen and (min-width: 1024px) {
    .section.video_mod {
      height: 36.4em; } }
  @media screen and (max-width: 1023px) {
    .section.video_mod {
      min-height: 36.4em; } }
  .section.bg_mod {
    background-color: #fff; }
  .section.bg_v1_mod {
    background-color: #eff3f6; }
  .section.bg_v2_mod {
    background-color: #f7f9ff; }
  .section.bg_v3_mod {
    background-color: #f8f9ff; }
  .section.info_entry_mod {
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden; }
    @media screen and (max-width: 1023px) {
      .section.info_entry_mod {
        padding-top: 4em; } }
  .section.bg_accent_gradient_mod {
    background: linear-gradient(-211deg, #0071be 0%, #0071be 100%); }
  .section.bg_accent_none_mod {
    background: transparent; }
  .section.bg_blue_mod {
    background-image: linear-gradient(11deg, #0071be 0%, #0071be 100%); }
  .section.bg_blue_mod_v2 {
    background: #0071be; }
  .section.banner_mod {
    height: 36.4em; }
    @media screen and (max-width: 1023px) {
      .section.banner_mod {
        height: auto; } }
  .section.about_healing_music_mod {
    background-color: #f7f9ff; }
  .section.no_offset_top {
    padding-top: 0; }
  .section.healing_properties_mod {
    padding-bottom: 4.4em; }
    .section.healing_properties_mod.color_mod {
      background-color: white; }
  .section.no_offset_bottom {
    padding-bottom: 0; }
  .section.center_mod {
    display: flex;
    align-items: center; }
  .section.map_mod {
    min-height: 46.8vw; }
    @media screen and (max-width: 768px) {
      .section.map_mod {
        min-height: 52vw; } }
    @media screen and (max-width: 767px) {
      .section.map_mod {
        min-height: 55vw; } }

.section_in {
  width: 100%;
  max-width: 130.8rem;
  padding: 0 3rem;
  margin: 0 auto; }
  .section_in.info_entry_mod {
    overflow: hidden; }
  .section_in.banner_mod {
    text-align: center; }
  .section_in.full_mod {
    max-width: none; }
  .section_in.v2_mod {
    max-width: 98rem; }
  .section_in.v3_mod {
    max-width: 98rem;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .section_in.about_healing_music_mod {
    padding: 0 5em;
    max-width: 136em; }
    @media screen and (max-width: 1200px) {
      .section_in.about_healing_music_mod {
        padding: 0 5em;
        max-width: 100%; } }
    @media screen and (max-width: 1023px) {
      .section_in.about_healing_music_mod {
        padding: 0 3em; } }
  @media screen and (max-width: 1023px) {
    .section_in.flex_mod {
      flex-flow: wrap; } }
  @media screen and (max-width: 1023px) {
    .section_in {
      padding: 0 2rem;
      text-align: center; } }

.section_title {
  font-size: 3.4em;
  font-weight: 300;
  font-style: italic;
  letter-spacing: -.02rem; }
  .section_title.hero_mod {
    margin-bottom: 0.3em;
    font-size: 6em;
    line-height: 1;
    font-weight: 300;
    font-style: normal;
    color: #0d0d0d;
    text-align: left; }
    @media screen and (max-width: 1023px) {
      .section_title.hero_mod {
        font-size: 4.8em; } }
    @media screen and (max-width: 767px) {
      .section_title.hero_mod {
        font-size: 4.2em; } }
  .section_title.hero_v2_mod {
    margin-bottom: 0.36364em;
    font-size: 5.5em;
    line-height: 1.1; }
    @media screen and (max-width: 1023px) {
      .section_title.hero_v2_mod {
        font-size: 4.2em; } }
    @media screen and (max-width: 767px) {
      .section_title.hero_v2_mod {
        font-size: 3.8em; } }
  .section_title.award_mod {
    margin-bottom: 0.14286em;
    text-align: center;
    color: #0d0d0d;
    font-size: 3.5em;
    font-family: "Bariol", sans-serif;
    font-style: italic; }
  .section_title.hero_title_mod {
    margin-bottom: 0.2em;
    font-size: 6em;
    font-weight: 300;
    line-height: 1;
    font-style: normal; }
    @media screen and (max-width: 1023px) {
      .section_title.hero_title_mod {
        font-size: 4em; } }
  .section_title.hero_title_mod_v2 {
    margin-bottom: 0.2em;
    font-size: 5em;
    font-weight: 300;
    line-height: 1;
    font-style: normal; }
    @media screen and (max-width: 1023px) {
      .section_title.hero_title_mod_v2 {
        font-size: 4em; } }
  .section_title.sanofi_hero_title_mod {
    padding: 13.2em 0 5.4em;
    background: url("../images/sanofi_bg.jpg") no-repeat center center; }
    @media screen and (max-width: 1023px) {
      .section_title.sanofi_hero_title_mod {
        padding: 8.2em 0 3.2em; } }
  .section_title.limit_mod {
    max-width: 68.8rem;
    margin: 0 auto; }
  .section_title.color_mod {
    color: #0071be; }
  .section_title.center_mod {
    text-align: center; }
  .section_title.width_mod {
    width: 50%; }
    @media screen and (max-width: 768px) {
      .section_title.width_mod {
        width: 100%; } }
  .section_title.padding_right_mod {
    padding-right: 3em; }
    @media screen and (max-width: 768px) {
      .section_title.padding_right_mod {
        padding-right: 0em; } }
  @media screen and (max-width: 768px) {
    .section_title.center_mobile {
      text-align: center; } }
  .section_title.center_mod {
    text-align: center; }
  .section_title.banner_mod {
    margin-bottom: 0.2em;
    letter-spacing: -0.005em; }
  .section_title.offset_mod {
    margin-bottom: 0.17143em; }
  .section_title.offset_v1_mod {
    margin-bottom: 1.25714em; }
  .section_title.offset_v2_mod {
    margin-bottom: 0.34286em; }
  .section_title.offset_v3_mod {
    margin-bottom: 0.28571em; }
  .section_title.mb_mod {
    margin-bottom: 0.4em; }
  .section_title.color_w_mod {
    color: #fff; }
  .section_title.about_healing_music_mod {
    margin-bottom: 0.22857em;
    color: #5b8fd0;
    letter-spacing: 0.002em; }
  .section_title.color_2_mod {
    color: #fefefe; }
  .section_title.color_3_mod {
    color: #0d0d0d; }
  .section_title.color_4_mod {
    color: #4879b6; }
  @media screen and (max-width: 768px) {
    .section_title.mobile_margin {
      margin-bottom: 1em; } }
  @media screen and (max-width: 768px) {
    .section_title.btn_center_mod {
      display: flex;
      flex-direction: column;
      align-items: center; } }
  .section_title.title_fit {
    margin-right: -25px; }
  @media screen and (max-width: 1023px) {
    .section_title br {
      display: none; } }
  @media screen and (max-width: 768px) {
    .section_title.mobile_width_mod {
      max-width: 60vw; } }
  @media screen and (max-width: 640px) {
    .section_title.mobile_width_mod {
      max-width: unset; } }
  .section_title.max_width_mod {
    max-width: 90%; }
    @media screen and (max-width: 640px) {
      .section_title.max_width_mod {
        max-width: 100%; } }
  .section_title.font_xl_mod {
    font-size: 3.9em; }

.section_descr {
  font-size: 2em;
  line-height: 1.8;
  color: #0d0d0d; }
  .section_descr.center_mod {
    text-align: center;
    margin: 0 auto; }
  .section_descr.offset_bottom {
    margin-bottom: 0.8em; }
  .section_descr.offset_mod {
    margin-bottom: 2em; }
  .section_descr.offset_v1_mod {
    margin-bottom: 0.75em; }
  .section_descr.offset_v2_mod {
    margin-bottom: 0em; }
  .section_descr.offset_v3_mod {
    margin-bottom: 3em; }
  .section_descr.offset_v4_mod {
    margin-bottom: 1.85714em; }
  .section_descr.offset_v5_mod {
    margin-bottom: 2em; }
    @media screen and (max-width: 768px) {
      .section_descr.offset_v5_mod {
        margin-bottom: 4em; } }
  .section_descr.offset_v6_mod {
    margin-bottom: 0.4em; }
  .section_descr.sm_mod {
    font-size: 1.6em; }
  @media screen and (max-width: 768px) {
    .section_descr.center_mobile {
      text-align: center; } }
  .section_descr.xs_mod {
    font-size: 1.4em; }
  .section_descr.xxs_mod {
    font-size: 0.47em;
    font-style: normal;
    font-weight: 500; }
  .section_descr.color_mod {
    color: #0071be; }
  .section_descr.treatment_mod {
    margin-bottom: 1.875em;
    line-height: 2.3; }
    .section_descr.treatment_mod:last-of-type {
      margin-bottom: 0; }
  .section_descr.limit_mod {
    max-width: 25.5em;
    margin-left: auto;
    margin-right: auto; }
  .section_descr.limit_v1_mod {
    max-width: 43.75em;
    width: 100%; }
  .section_descr.color_2_mod {
    color: #fefefe; }
  .section_descr.color_3_mod {
    color: #0d0d0d; }
  .section_descr.color_w_mod {
    color: #fff; }
  .section_descr.small_mod {
    font-size: 1.6em; }
  .section_descr.hero_mod {
    text-align: left; }
  @media screen and (max-width: 1023px) {
    .section_descr {
      font-size: 1.6em; } }
  @media screen and (max-width: 768px) {
    .section_descr.mobile_width_mod {
      max-width: 60vw; } }
  @media screen and (max-width: 640px) {
    .section_descr.mobile_width_mod {
      max-width: unset; } }

.section_subtitle {
  font-size: 1.6em; }
  .section_subtitle.hero_title_mod {
    font-weight: 700;
    text-transform: uppercase;
    line-height: 2; }
  .section_subtitle.color_mod {
    color: #fff; }
  .section_subtitle.color_2_mod {
    color: #0d0d0d; }
  @media screen and (max-width: 1023px) {
    .section_subtitle {
      font-size: 1.4em; } }

.section_head_w {
  width: 100%; }
  .section_head_w.hero_title_mod {
    max-width: 72.7em; }
    @media screen and (max-width: 1023px) {
      .section_head_w.hero_title_mod {
        max-width: none; } }
  .section_head_w.hero_title_mod_v2 {
    max-width: 72.7em; }
    @media screen and (max-width: 1023px) {
      .section_head_w.hero_title_mod_v2 {
        max-width: 61.2em; } }
  .section_head_w.about_line_mod {
    max-width: 52.2em; }
    @media screen and (max-width: 1023px) {
      .section_head_w.about_line_mod {
        max-width: none; } }
  @media screen and (max-width: 640px) {
    .section_head_w.mobile_center_mod {
      text-align: center;
      align-items: center; } }
  .section_head_w.offset_bottom {
    margin-bottom: 8em; }
  .section_head_w.offset_2_bottom {
    margin-bottom: 2.4em; }
  .section_head_w.center_mod {
    text-align: center;
    margin-left: auto;
    margin-right: auto; }

.sections_wrap {
  margin: 0;
  padding: 0; }
  .sections_wrap.bg_accent_gradient_mod {
    background: linear-gradient(31deg, #0071be 0%, #0071be 100%); }

.accent_title_el {
  color: #1f1f1f;
  display: block; }

.icon.footer_mod {
  fill: #fff;
  font-size: 2.4em; }

.diseases_wrap {
  overflow: hidden; }
  @media screen and (max-width: 1023px) {
    .diseases_wrap {
      flex-wrap: wrap;
      justify-content: center; } }
  @media screen and (max-width: 640px) {
    .diseases_wrap {
      justify-content: center; } }

.disease_item {
  display: block; }
  .disease_item.flex_mod {
    flex: 1 1 auto; }
    @media screen and (max-width: 768px) {
      .disease_item.flex_mod {
        width: 100%; } }
    .disease_item.flex_mod .disease_logo {
      width: 50%; }
  .disease_item:hover, .disease_item:focus {
    text-decoration: none; }
  @media screen and (min-width: 1024px) {
    .disease_item {
      margin-right: 6.8em; }
      .disease_item:last-child {
        margin-right: 0; } }
  @media screen and (max-width: 1023px) {
    .disease_item {
      width: 30%;
      padding: 0 1em 4em; } }
  @media screen and (max-width: 768px) {
    .disease_item {
      width: 50%; } }
  @media screen and (max-width: 374px) {
    .disease_item {
      width: 100%; } }

.disease_item_pict_wrap {
  margin: 0 auto 2.4em;
  display: block;
  width: 4.8em;
  height: 4.4em; }
  @media screen and (max-width: 1023px) {
    .disease_item_pict_wrap {
      width: 100%; } }

.disease_item_pict {
  margin: auto;
  display: block;
  max-width: 100%;
  max-height: 100%; }

.disease_item_name {
  margin-bottom: 0;
  color: #3e6493;
  font-size: 1.8em;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  transition: color .3s; }
  .disease_item:hover .disease_item_name {
    color: #000; }

@media screen and (min-width: 1024px) {
  .disease_logo {
    max-width: 15em;
    width: 100%;
    height: 9.6em;
    overflow: hidden; } }

@media screen and (max-width: 1023px) {
  .disease_logo {
    width: 100%;
    justify-content: center;
    margin: 0 auto; } }

.disease_logo_img {
  display: block;
  max-width: 100%;
  max-height: 100%; }
  .disease_logo_img.medapp_mod {
    width: 100%;
    max-width: 15em; }
  @media screen and (max-width: 1023px) {
    .disease_logo_img {
      max-height: 9.6em; } }

.disease_logos_wrap {
  margin: 0;
  display: flex;
  align-items: center;
  width: 30em; }
  @media screen and (max-width: 768px) {
    .disease_logos_wrap {
      margin: 2em 0 0;
      width: 100%;
      flex-flow: column;
      align-items: center;
      align-content: center;
      justify-content: flex-start; } }

.hero_content {
  max-width: 56.6em; }

.hero_news_block_title {
  display: inline-flex;
  padding-right: 0.75em;
  padding-left: 0.75em;
  margin-bottom: 0.625em;
  font-size: 1.6em;
  line-height: 2.8rem;
  color: #fff;
  text-transform: uppercase;
  background-color: #1fc927;
  border-radius: .5rem; }

.hero_news_block {
  padding-top: 3em;
  margin-top: 4.4em;
  border-top: 1px solid #dbdbdb; }
  @media screen and (max-width: 767px) {
    .hero_news_block {
      padding-top: 2em;
      margin-top: 2em; } }

.hero_news_link {
  display: block;
  margin-bottom: .2em;
  font-size: 1.9em;
  line-height: 1.5;
  color: #3575b6; }
  .hero_news_link.color_mod {
    color: black; }

.hero_social_block {
  position: relative;
  margin-top: 1.4em;
  padding-bottom: 1.8em; }
  .hero_social_block:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 5.4em;
    height: .8em;
    background: url("../images/dots.svg") no-repeat;
    background-size: 100% 100%; }

.hero_social_link {
  font-size: 1.4em;
  color: #909090; }

.hero_download_list {
  margin-top: 2.4em; }

.hero_download_item {
  width: 6em;
  height: 6em;
  margin-right: 2em; }
  .hero_download_item:last-child {
    margin-right: 0; }

.hero_download_img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center; }

.hero_btn_wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; }

.hero_download_title {
  margin-top: 3.125em;
  font-size: 1.6em;
  font-weight: 700; }

.hero_logos_list {
  margin-bottom: 3.6em; }
  @media screen and (max-width: 1023px) {
    .hero_logos_list {
      margin-bottom: 2.6em; } }
  @media screen and (max-width: 480px) {
    .hero_logos_list {
      flex-direction: column;
      align-items: flex-end;
      align-content: flex-end; } }

.hero_logos_list_v2 {
  margin-bottom: 6.6em; }
  @media screen and (max-width: 640px) {
    .hero_logos_list_v2 {
      margin-bottom: 2.6em;
      justify-content: center; } }

.hero_logos_item {
  position: relative;
  height: 6em;
  max-width: 21.2em;
  padding-right: 2.2em;
  margin-right: 3em; }
  .hero_logos_item:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 1px;
    height: 2.2em;
    margin-top: -1.1em;
    background-color: #a8a8a8; }
    @media screen and (max-width: 480px) {
      .hero_logos_item:after {
        display: none; } }
  .hero_logos_item:last-child {
    margin-right: 0;
    padding-right: 0; }
    .hero_logos_item:last-child:after {
      display: none; }
  @media screen and (max-width: 768px) {
    .hero_logos_item {
      height: 4em;
      max-width: 12.6em;
      margin-bottom: 1em; } }
  @media screen and (max-width: 480px) {
    .hero_logos_item {
      margin-right: 0;
      padding-right: 0; } }

.hero_logos_item_v2 {
  position: relative;
  height: 6em;
  max-width: 21.2em;
  padding-right: 2.2em;
  margin-right: 2em; }
  .hero_logos_item_v2:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 1px;
    height: 2.2em;
    margin-top: -1.1em;
    background-color: #a8a8a8; }
  .hero_logos_item_v2:last-child {
    margin-right: 0;
    padding-right: 0; }
    .hero_logos_item_v2:last-child:after {
      display: none; }
  @media screen and (max-width: 768px) {
    .hero_logos_item_v2 {
      height: 5em;
      max-width: 12.6em;
      margin-bottom: 1em; } }

.hero_logos_img {
  height: 100%;
  max-width: 100%;
  object-fit: contain; }

.btn_icon_wrap {
  display: inline-flex;
  width: 2.2rem;
  height: 2.2rem;
  margin-right: 1rem; }

.btn_icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center; }

.hero_news_item {
  margin-right: 1em; }

.hero_news_slider_dots {
  margin-top: 1em; }
  @media screen and (max-width: 640px) {
    .hero_news_slider_dots {
      width: 100%;
      display: flex;
      justify-content: center; } }

@media screen and (max-width: 768px) {
  .hero_news_slider.mobile_width_mod {
    max-width: 60vw; } }

@media screen and (max-width: 640px) {
  .hero_news_slider.mobile_width_mod {
    max-width: unset; } }

.award_list {
  margin: 0 auto; }
  @media screen and (min-width: 768px) {
    .award_list {
      max-width: 92em; } }

.award_item {
  width: 25%;
  padding: 0 1em 3em 1em; }
  @media screen and (max-width: 1023px) {
    .award_item {
      width: 50%; } }
  @media screen and (max-width: 480px) {
    .award_item {
      width: 100%;
      margin: 0 0 3em 0; } }

.award_img_wrap {
  margin: 2em 0 1.6em;
  width: 12em;
  height: 8em; }
  .award_img_wrap.circle_mod {
    width: 15em;
    height: 15em;
    padding: 3.8em 2.2em;
    border-radius: 50%;
    background-color: #eff3f6; }

.award_img_inner {
  width: 100%; }

.award_pic {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain; }

.award_item_text {
  width: 100%;
  text-align: center;
  color: #0071be;
  font-size: 1.4em;
  font-weight: 400;
  line-height: 1.8em; }
  @media screen and (max-width: 768px) {
    .award_item_text {
      text-align: left; } }
  .award_item_text.color_w_mod {
    color: #fff; }
  .award_item_text.title_mod {
    font-weight: 700;
    font-size: 1.6em; }
  .award_item_text.treatment_mod {
    font-size: 1.6em; }

.section_cols.center_mod {
  align-items: center; }

.section_cols.reverse_mod {
  display: flex;
  flex-direction: row-reverse; }

@media screen and (max-width: 1023px) {
  .section_cols {
    flex-direction: column; } }

.section_col_block_wrap {
  position: relative;
  width: 50%;
  margin-right: 4em;
  flex-shrink: 0; }
  .section_col_block_wrap.right_mod {
    margin-left: auto; }
  .section_col_block_wrap.sm_mod {
    width: 42%; }
    @media screen and (max-width: 1023px) {
      .section_col_block_wrap.sm_mod {
        width: 100%; } }
  @media screen and (max-width: 1023px) {
    .section_col_block_wrap {
      width: 100%;
      margin: 0 0 4em;
      text-align: center; } }

.form_block {
  margin: 2em -.8em -1.2em; }

.section_cols_img_wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  z-index: -1; }
  .section_cols_img_wrap.full_mod {
    width: 100%; }
  .section_cols_img_wrap.dark_mod:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.55); }
  .section_cols_img_wrap.reverse_mod {
    left: unset;
    right: 0; }
  .section_cols_img_wrap.video_mod {
    z-index: 1; }
    @media screen and (max-width: 1023px) {
      .section_cols_img_wrap.video_mod {
        position: relative;
        margin-bottom: 2em; }
        .section_cols_img_wrap.video_mod:after {
          background-color: rgba(0, 0, 0, 0.3); } }
  @media screen and (max-width: 1023px) {
    .section_cols_img_wrap {
      width: 100%; }
      .section_cols_img_wrap:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white; } }

.section_cols_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; }

.form_controller {
  padding: 0 .8em 1.2em;
  width: 100%; }
  .form_controller.half_mod {
    width: 50%; }
    @media screen and (max-width: 375px) {
      .form_controller.half_mod {
        width: 100%; } }
  @media screen and (max-width: 1023px) {
    .form_controller.btn_mod {
      width: 100%; } }

.half_lg_img {
  max-width: 100%; }

@media screen and (max-width: 1023px) {
  .half_logo_list {
    margin-bottom: 2em; } }

.half_logo_list_item {
  max-width: 15em;
  max-height: 9.4em;
  margin-bottom: 3.4em; }
  .half_logo_list_item:last-child {
    margin-bottom: 0; }
  @media screen and (max-width: 1023px) {
    .half_logo_list_item {
      filter: drop-shadow(0 0.6rem 0.6rem rgba(0, 113, 190, 0.4)) drop-shadow(0 -0.6rem 0.6rem rgba(0, 113, 190, 0.4)) drop-shadow(0.6rem 0 0.6rem rgba(0, 113, 190, 0.4)) drop-shadow(-0.6rem 0 0.6rem rgba(0, 113, 190, 0.4)); } }

.half_logo_list_img {
  display: block;
  max-width: 15em;
  max-height: 9.4em;
  object-fit: contain; }

.half_logos {
  margin: 2.6em -2.6em -2.6em; }
  @media screen and (max-width: 767px) {
    .half_logos {
      justify-content: center; } }

.half_logo {
  width: 25%;
  padding: 0 2.6em 2.6em; }
  @media screen and (max-width: 640px) {
    .half_logo {
      display: flex;
      width: 33.33%;
      max-height: 8.4em;
      max-width: 16em; } }

.half_logo_img {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
  object-position: center; }

.extraDescr_container {
  display: flex;
  align-items: center;
  margin-top: 1em;
  padding: 1em 1em; }

.icon_img {
  width: 4em;
  margin-right: 1em; }

.half_extra_descr {
  font-size: 1.2em; }

.video_btn {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6.2em;
  height: 6.2em;
  margin-top: -3.1em;
  margin-left: -3.1em;
  background: url("../images/play_white.png") no-repeat center center;
  background-size: contain;
  cursor: pointer;
  z-index: 2; }

.plans_list {
  margin: 0 0em -3em;
  width: 120%;
  max-width: 100vw;
  justify-content: center;
  justify-self: center;
  align-content: center;
  align-self: center; }

.plans_item_wrap {
  width: 33%;
  padding: 0 0 3em; }
  .plans_item_wrap.full_mod {
    width: 100%; }
  @media screen and (max-width: 1023px) {
    .plans_item_wrap {
      width: 100%; } }

@media screen and (max-width: 768px) {
  .plans_price_container.center_mobile {
    justify-content: center; } }

.plans_price {
  font-size: 4.6em;
  color: #0071be;
  align-self: flex-start; }
  .plans_price.smaller {
    font-size: 1.6em; }
  .plans_price.smallest {
    font-size: 1em;
    padding-left: 2px; }

.plans_per {
  font-size: 2.6em;
  padding-top: .3em; }
  .plans_per.smaller {
    font-size: 1.6em;
    padding-top: 0em; }
  .plans_per.smallest {
    font-size: 1em;
    padding-top: 0em; }

.plans_item {
  height: 100%;
  padding: 2.2em 3.2em 2.8em;
  background-color: #eff3f6; }
  @media screen and (max-width: 768px) {
    .plans_item {
      padding-left: 2.2em;
      padding-right: 2.2em; } }
  .plans_item.color_first {
    background-color: #e5eaee;
    padding-top: 2.2em;
    height: 100%; }
  .plans_item.color_second {
    background-color: #ebf1f6; }
  .plans_item.color_third {
    background-color: #f6f7f9; }

.plans_button {
  margin: 10px 0;
  width: 100%;
  position: relative; }

.plans_multi_button {
  margin: 10px 0;
  width: 100%;
  z-index: 1; }

.plans_img_wrap {
  position: relative;
  margin: 0 -6.2em 3em;
  width: calc(100% + 12.4em); }
  .plans_img_wrap:after {
    content: '';
    display: block;
    padding-bottom: 41.4%; }
  @media screen and (max-width: 768px) {
    .plans_img_wrap {
      width: calc(100% + 4.4em);
      margin-left: -2.2em;
      margin-right: -2.2em; } }

.plans_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; }

.plans_checklist_wrap {
  width: 100%;
  margin-bottom: 3em;
  margin-top: 2.5em; }
  .plans_checklist_wrap.less_margin {
    margin-bottom: 1em;
    margin-top: 1.6em; }

.plans_checklist_block {
  margin-bottom: 2.8em; }
  .plans_checklist_block:last-child {
    margin-bottom: 0; }

.plans_checklist_title {
  margin-bottom: 0.375em;
  font-size: 1.6em;
  font-weight: 700;
  color: #6aade8;
  text-transform: uppercase; }

.plans_checklist_list.row {
  display: flex;
  flex-direction: row; }
  @media screen and (max-width: 768px) {
    .plans_checklist_list.row {
      flex-direction: column; } }

.plans_checklist_item {
  margin-bottom: 0.5em;
  padding-left: 0.375em;
  font-size: 1.6em;
  text-indent: -1.3em;
  margin-left: 1.3em; }
  .plans_checklist_item.row {
    min-width: 28%; }
    @media screen and (max-width: 768px) {
      .plans_checklist_item.row {
        width: 100%; } }
    .plans_checklist_item.row:last-child {
      margin-left: 2.2em;
      width: 31%; }
      @media screen and (max-width: 768px) {
        .plans_checklist_item.row:last-child {
          width: 100%;
          margin-left: 1.3em; } }
  .plans_checklist_item:last-child {
    margin-bottom: 0; }
  .plans_checklist_item:before {
    content: '';
    display: inline-flex;
    margin-right: 1rem;
    width: 1rem;
    height: 1rem;
    background: url("../images/check.png") no-repeat center center;
    background-size: contain; }

.plans_bottom {
  margin-top: auto;
  width: 100%; }

.plans_form_wrap {
  position: relative;
  width: 100%; }

.form_submit_arrow {
  position: absolute;
  top: 50%;
  right: 1.8em;
  width: 3em;
  height: 1.6em;
  margin-top: -.8em;
  background: url("../images/arrow.png") no-repeat center center;
  background-size: contain;
  cursor: pointer; }
  .form_submit_arrow:hover, .form_submit_arrow:focus {
    opacity: .8; }

.descr_wrap {
  display: flex;
  flex-direction: row;
  justify-content: center; }

.plans_text {
  max-width: 20.57143em;
  margin: 0 auto 2.28571em;
  font-size: 1.4em;
  color: #6a6a6a;
  text-align: center; }

.btn_link {
  margin-top: 1.25em;
  font-size: 1.6em;
  font-weight: 700;
  color: #0071be; }

.plans_hr {
  width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.5);
  margin-bottom: 1em; }
  .plans_hr.hr_mod {
    margin-bottom: 2em; }

.show {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2em;
  z-index: 1000;
  font-size: 1rem;
  pointer-events: none;
  opacity: 0;
  transition: opacity .3s; }
  .show.active_mod {
    opacity: 1;
    pointer-events: initial; }

.show_in {
  position: relative;
  width: 54.2em;
  max-width: 100%;
  max-height: 100%;
  overflow-y: auto;
  background-color: #fff;
  color: #1e1e1e; }

.show_top {
  padding: 4.2em 5.8em 5.6em;
  border-bottom: 2px solid #3575b6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  @media screen and (max-width: 767px) {
    .show_top {
      padding: 2em; } }

.plans_price_descr {
  color: #2ea3f7;
  padding-left: 1em;
  font-size: 1.3rem; }
  @media screen and (max-width: 768px) {
    .plans_price_descr {
      max-width: 10em; } }

.company_list_wrap {
  margin-bottom: 5.2em; }
  .company_list_wrap:last-child {
    margin-bottom: 0; }

.company_list {
  margin: 2.6em -1.2em -2em 0; }
  .company_list.lg_mod {
    margin-right: -2em;
    margin-bottom: -2em; }

.company_list_item {
  width: 16.66%;
  padding: 0 1.2em 2em 0; }
  .company_list.lg_mod .company_list_item {
    width: 33.33%;
    padding: 0 2em 2em 0; }
    @media screen and (max-width: 1023px) {
      .company_list.lg_mod .company_list_item {
        max-width: 50em;
        margin: 0 auto;
        width: 100%; } }
  @media screen and (max-width: 1023px) {
    .company_list_item {
      width: 33.33%; } }
  @media screen and (max-width: 767px) {
    .company_list_item {
      width: 50%; } }
  @media screen and (max-width: 375px) {
    .company_list_item {
      width: 100%; } }

.company_list_img_wrap {
  position: relative;
  background-color: #000; }
  .company_list_img_wrap:after {
    content: '';
    display: block;
    padding-bottom: 78%; }
    .company_list.lg_mod .company_list_img_wrap:after {
      padding-bottom: 49.2%; }

.company_list_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; }

.company_list_item_content {
  padding: 1em 0 .8em;
  background-color: #fff;
  flex-grow: 1; }
  .company_list.lg_mod .company_list_item_content {
    padding: 3.2em 4.2em 5.2em; }

.company_list_text {
  margin-bottom: 0.33333em;
  font-size: 1.2em;
  color: #222;
  text-align: center;
  line-height: 1.9; }
  .company_list.lg_mod .company_list_text {
    font-size: 1.6em;
    text-align: left; }
  .company_list.lg_mod .company_list_text.lg_mod {
    margin-bottom: 0;
    font-size: 2.4em;
    font-style: italic;
    color: #0071be;
    text-align: left;
    line-height: 1.7; }
  .company_list.white_mod .company_list_text {
    color: #fff; }

.accordeon_wrap {
  margin: 3.8em auto 0; }

.accordeon_item {
  padding: 2.4em 0 2.8em;
  border-bottom: 1px solid #fff; }
  .accordeon_item:first-child {
    border-top: 1px solid #fff; }

.accordeon_title {
  position: relative;
  padding-right: 2.625em;
  font-size: 1.6em;
  color: #fff;
  cursor: pointer; }
  .accordeon_title:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 1.6rem;
    height: 1.6rem;
    width: 2.6rem;
    margin-top: -.8rem;
    transform-origin: 50% 50%;
    transition: transform .3s;
    background: url("../images/caret.png") no-repeat center center;
    background-size: contain; }
    @media screen and (max-width: 768px) {
      .accordeon_title:after {
        right: 0; } }
  .accordeon_title.active_mod:after {
    transform: rotate(180deg); }
  @media screen and (max-width: 768px) {
    .accordeon_title {
      padding-right: 1.625em; } }

.accordeon_text {
  display: none;
  padding-top: 1em;
  font-size: 1.6em;
  color: #fff; }

.info_entry {
  position: relative;
  padding: 0;
  overflow: hidden; }
  @media screen and (max-width: 1023px) {
    .info_entry {
      display: block; }
      .info_entry.img_left_mod {
        display: flex;
        flex-direction: column-reverse; } }

.info_entry_img_wrap {
  position: relative;
  width: 38.6em; }
  .info_entry_img_wrap.size_two_mod {
    width: 41.2em; }
  .info_entry_img_wrap:nth-child(1) {
    margin-right: 8em;
    margin-left: 9.4em; }
    @media screen and (max-width: 1023px) {
      .info_entry_img_wrap:nth-child(1) {
        margin: 1em auto 0; } }
    @media screen and (max-width: 480px) {
      .info_entry_img_wrap:nth-child(1) {
        width: 100%; } }
  .info_entry_img_wrap:nth-child(2) {
    margin-left: 4.4em; }
    @media screen and (max-width: 1023px) {
      .info_entry_img_wrap:nth-child(2) {
        margin: 1em auto 0; } }
    @media screen and (max-width: 480px) {
      .info_entry_img_wrap:nth-child(2) {
        width: 100%; } }

.info_entry_img {
  display: block;
  width: 100%;
  height: 100%; }

.info_entry_content_wrap {
  display: flex;
  width: 50em; }
  @media screen and (max-width: 1023px) {
    .info_entry_content_wrap {
      display: block;
      width: 100%; } }

.info_entry_content_in {
  width: 100%;
  min-height: 36.4em;
  padding: 1.4em 0; }
  @media screen and (max-width: 1023px) {
    .info_entry_content_in {
      min-height: auto;
      align-content: center; } }

.info_entry_title {
  margin: 0 0.8em 2px 0;
  font-size: 3.5em;
  font-weight: 300;
  font-style: italic;
  line-height: 1.3;
  color: #fff;
  letter-spacing: -0.005em; }
  @media screen and (max-width: 1023px) {
    .info_entry_title {
      margin-right: 0; } }

.info_entry_text {
  margin: 0 0 1.6em;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 2.15;
  color: #fff; }

@media screen and (min-width: 1024px) {
  .about_treatment_col {
    width: 50%; } }

@media screen and (min-width: 1024px) {
  .about_treatment_col.info_mod {
    padding-right: 3em; } }

@media screen and (max-width: 1023px) {
  .about_treatment_col.info_mod {
    padding-bottom: 3em; } }

.about_treatment_in.reverse_mod .about_treatment_col.info_mod {
  order: 2; }
  @media screen and (min-width: 1024px) {
    .about_treatment_in.reverse_mod .about_treatment_col.info_mod {
      padding: 1.8em 4em 0 2em; } }
  @media screen and (max-width: 1023px) {
    .about_treatment_in.reverse_mod .about_treatment_col.info_mod {
      padding: 0; } }

@media screen and (min-width: 1024px) {
  .about_treatment_col.graph_mod {
    padding: 1.8em 4em 0 2em; } }

.about_treatment_in.reverse_mod .about_treatment_col.graph_mod {
  order: 1; }
  @media screen and (min-width: 1024px) {
    .about_treatment_in.reverse_mod .about_treatment_col.graph_mod {
      padding: 0 3em 0 0; } }
  @media screen and (max-width: 1023px) {
    .about_treatment_in.reverse_mod .about_treatment_col.graph_mod {
      padding-bottom: 3em; } }

.about_treatment_graph_w_img {
  max-width: 34.4em;
  width: 100%;
  height: 30.6em;
  margin-bottom: 5.6em; }
  .about_treatment_graph_w_img.full_size_mod {
    max-width: 100%;
    height: auto;
    margin-left: 0; }
  @media screen and (min-width: 1024px) {
    .about_treatment_graph_w_img {
      margin-left: 5.8em; } }
  @media screen and (max-width: 1023px) {
    .about_treatment_graph_w_img {
      margin-left: auto;
      margin-right: auto; } }
  @media screen and (max-width: 767px) {
    .about_treatment_graph_w_img {
      height: auto; } }

.about_treatment_graph_img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain; }

.about_treatment_w_logo {
  display: block;
  width: 15.8em;
  height: 4.4em; }
  @media screen and (min-width: 1024px) {
    .about_treatment_w_logo {
      margin: 0 -4em 3em auto; } }
  @media screen and (max-width: 1023px) {
    .about_treatment_w_logo {
      margin: 0 auto 4em; } }

.about_treatment_logo {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; }

.quotes_block {
  position: relative;
  min-height: 11.2em;
  padding: 2em 4em 2em 7.4em;
  border-radius: .5em;
  background-color: #f6fafe; }
  .quotes_block:before {
    position: absolute;
    top: 2.6em;
    left: 2.4em;
    width: 2.8em;
    height: 3.2em;
    background: url("../images/atom_icon.png") no-repeat center center;
    background-size: contain;
    content: ''; }
  .quotes_block.offset_mod {
    margin-bottom: 3.4em; }

.quotes_block_in {
  color: #191919;
  font-size: 1.4em;
  font-weight: 400;
  line-height: 1.7; }

.banner_link:hover, .banner_link:focus {
  text-decoration: none; }

.banner_icon_wrap {
  width: 8em;
  height: 8em;
  padding: 2.2em;
  margin-bottom: 1.6em;
  background-color: #0071be;
  border-radius: 50%;
  transition: .3s; }
  .banner_link:hover .banner_icon_wrap,
  .banner_link:focus .banner_icon_wrap {
    background-color: #4879b6; }

.banner_icon {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center; }

.banner_logos_list {
  margin-top: 2em; }

.banner_logo_item {
  position: relative;
  height: 3.4em;
  padding: 0 1.6em; }
  .banner_logo_item:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 3.4em;
    margin-top: -1.7em;
    background-color: #fff; }
  .banner_logo_item:last-child:after {
    display: none; }

.banner_logo {
  height: 100%; }

.banner_logos_title {
  margin-top: 1.71429em;
  font-size: 1.4em;
  color: #d3d3d3; }

.treatment_inner {
  width: 100%; }
  @media screen and (min-width: 1024px) {
    .treatment_inner {
      max-width: 62%; } }

.treatment_list {
  margin: 0 -1em 0 -1em; }
  @media screen and (max-width: 768px) {
    .treatment_list {
      justify-content: flex-start; } }

.treatment_item {
  width: 20%;
  padding: 0 1em 3em 1em; }
  @media screen and (max-width: 1200px) {
    .treatment_item {
      width: 25%; } }
  @media screen and (max-width: 1023px) {
    .treatment_item {
      width: 33.33%; } }
  @media screen and (max-width: 768px) {
    .treatment_item {
      width: 50%;
      flex-flow: row nowrap; } }
  @media screen and (max-width: 480px) {
    .treatment_item {
      width: 100%; } }

.treatment_description {
  padding-top: 2.5em;
  border-top: 0.2em solid #5b8fd0; }
  @media screen and (max-width: 768px) {
    .treatment_description {
      flex-direction: column; } }

.treatment_half {
  width: 50%; }
  @media screen and (max-width: 768px) {
    .treatment_half {
      width: 100%; } }
  .treatment_half:first-child {
    border-right: 0.2em solid #5b8fd0; }
    @media screen and (max-width: 768px) {
      .treatment_half:first-child {
        border-right: none;
        margin: 0 0 2.5em 0; } }
  .treatment_half.half_left_mod {
    padding: 0 6em 0 0; }
    @media screen and (max-width: 768px) {
      .treatment_half.half_left_mod {
        padding: 0; } }
  .treatment_half.half_right_mod {
    padding: 0 0 0 6em; }
    @media screen and (max-width: 768px) {
      .treatment_half.half_right_mod {
        padding: 0; } }

.img_wrap_treatment {
  width: 17.5em;
  height: 17.5em;
  flex: 0 0 auto;
  border: 0.2em solid #5b8fd0;
  border-radius: 50%;
  overflow: hidden; }
  @media screen and (max-width: 768px) {
    .img_wrap_treatment {
      margin: 0 2em 0 0; } }

.img_wrap_treatment_in {
  width: 100%;
  height: 100%;
  padding: 2.5em; }

.treatment_pic {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain; }

.clinical_studies {
  margin-bottom: 3em; }

@media screen and (min-width: 1024px) {
  .clinical_studies_in {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: center; } }

.clinical_studies_heading {
  max-width: 68.4em; }
  @media screen and (max-width: 1023px) {
    .clinical_studies_heading {
      max-width: none; } }

.clinical_studies_logos {
  max-width: 35em;
  width: 100%; }
  @media screen and (min-width: 1024px) {
    .clinical_studies_logos {
      margin-left: auto; } }
  @media screen and (max-width: 1023px) {
    .clinical_studies_logos {
      flex-wrap: nowrap;
      margin: 3em auto 0; } }

.clinical_studies_w_img {
  max-width: 12.6em;
  max-height: 4.6em;
  margin-right: 3.6em; }
  .clinical_studies_w_img:last-of-type {
    margin-right: 0; }

.clinical_studies_img {
  max-width: 100%;
  display: block;
  height: auto;
  object-fit: contain; }

.studies_table {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-x: auto; }
  @media screen and (max-width: 767px) {
    .studies_table {
      display: block; } }

.studies_table_row {
  width: 100%;
  padding-top: 1.4em;
  padding-bottom: 1.4em;
  min-height: 8.2em;
  border-bottom: 1px solid #d6d6d6; }
  .studies_table_row.heading_mod {
    width: 100%;
    align-items: center;
    padding: 1.4em 0;
    min-height: 1.6em;
    align-content: center;
    border-bottom: 1px solid #0071be; }
  @media screen and (max-width: 767px) {
    .studies_table_row {
      flex: 1; } }

.studies_table_info {
  color: #000;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.7; }
  .studies_table_row.heading_mod .studies_table_info {
    padding: 0;
    color: #b2b2b2;
    font-weight: 700; }
  .studies_table_info.mod_1 {
    padding-right: 1.875em; }
    @media screen and (min-width: 1024px) {
      .studies_table_info.mod_1 {
        width: 59%;
        padding-left: 1.375em; } }
    @media screen and (max-width: 1023px) {
      .studies_table_info.mod_1 {
        width: 100%;
        padding-right: 0; } }
    .studies_table_row.heading_mod .studies_table_info.mod_1 {
      color: #000; }
  .studies_table_info.mod_2 {
    padding-right: 1em; }
    @media screen and (min-width: 1024px) {
      .studies_table_info.mod_2 {
        width: 18%; } }
    @media screen and (max-width: 1023px) {
      .studies_table_info.mod_2 {
        width: 100%;
        padding-right: 0; } }
  @media screen and (min-width: 1024px) {
    .studies_table_info.mod_3 {
      width: 21%; } }
  @media screen and (max-width: 1023px) {
    .studies_table_info.mod_3 {
      width: 100%;
      padding-right: 0; } }
  .studies_table_info.mod_4 {
    width: 15%; }
    .studies_table_row .studies_table_info.mod_4 {
      color: #000;
      font-weight: 700; }
    .studies_table_row.heading_mod .studies_table_info.mod_4 {
      color: #b2b2b2; }
    @media screen and (min-width: 1024px) {
      .studies_table_info.mod_4 {
        padding-left: 1em; } }
    @media screen and (max-width: 1023px) {
      .studies_table_info.mod_4 {
        width: 100%; } }
  .studies_table_info.mod_5 {
    width: 44%; }
    @media screen and (min-width: 1024px) {
      .studies_table_info.mod_5 {
        padding-right: 7em; } }
    @media screen and (max-width: 1023px) {
      .studies_table_info.mod_5 {
        width: 100%; } }
  .studies_table_info.mod_6 {
    width: 41%; }
    @media screen and (min-width: 1024px) {
      .studies_table_info.mod_6 {
        padding-right: 3em; } }
    @media screen and (max-width: 1023px) {
      .studies_table_info.mod_6 {
        padding-right: 1.25em; } }
    @media screen and (max-width: 767px) {
      .studies_table_info.mod_6 {
        min-width: 20em; } }
  @media screen and (max-width: 1023px) {
    .studies_table_info {
      position: relative;
      padding-top: .6em; }
      .studies_table_row.heading_mod .studies_table_info:not(.nav_wrap_mod) {
        display: none; }
      .studies_table_row.heading_mod .studies_table_info:not(.nav_wrap_mod):first-child {
        display: block; } }

.studies_table_info_w_block {
  margin-right: -1.25em; }
  @media screen and (max-width: 1023px) {
    .studies_table_info_w_block {
      display: block;
      margin-right: 0; } }

.studies_table_info_block {
  width: 50%;
  padding-right: 1.25em;
  font-size: 0.8125em;
  line-height: 2.2; }
  @media screen and (max-width: 1023px) {
    .studies_table_info_block {
      width: 100%;
      padding: 0; } }

.studies_table_info_link {
  color: #0071be;
  font-weight: 700; }

.studies_table_w_row {
  width: 100%; }

@media screen and (max-width: 1023px) {
  .studies_table_nav {
    align-items: flex-start; } }

@media screen and (min-width: 1024px) {
  .studies_table_nav_item {
    margin-right: 3.2em; }
    .studies_table_nav_item:last-of-type {
      margin-right: 0; } }

@media screen and (max-width: 1023px) {
  .studies_table_nav_item {
    width: 34%;
    padding-right: 1em; } }

@media screen and (max-width: 767px) {
  .studies_table_nav_item {
    width: 50%; } }

.studies_table_tabs {
  display: none; }
  .studies_table_tabs.active_state {
    display: block; }

.studies_table_nav_link {
  color: #0071be;
  font-weight: 700; }
  .studies_table_nav_link.active_state {
    color: #000; }
  .studies_table_nav_link:hover, .studies_table_nav_link:focus {
    text-decoration: none; }

.about_line_w {
  position: relative;
  width: 100%;
  height: 33.2em; }
  @media screen and (max-width: 1200px) {
    .about_line_w {
      height: 60em; } }
  @media screen and (max-width: 639px) {
    .about_line_w {
      font-size: 1.94vw; } }

.about_line_decor {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 100vw;
  height: 4px;
  transform: translate(-50%, -50%);
  background-color: #fff; }
  @media screen and (max-width: 1200px) {
    .about_line_decor {
      width: 2px;
      height: 100%; } }

.about_line_items_w {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.about_line_item {
  position: absolute;
  top: 50%;
  width: 4.4em;
  height: 4.4em;
  margin: -2.2em 0 0 -2.2em;
  border-radius: 50%;
  border: 4px solid #fff;
  background-color: #4777b3;
  white-space: nowrap; }
  .about_line_item:before {
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    width: 1.8em;
    height: 1.8em;
    margin: -.9em 0 0 -.9em;
    border-radius: 50%;
    background-color: #fff; }
    @media screen and (max-width: 1200px) {
      .about_line_item:before {
        width: .8em;
        height: .8em;
        margin: -.4em 0 0 -.4em; } }
  @media screen and (min-width: 1201px) {
    .about_line_item:first-child {
      left: 0; } }
  @media screen and (max-width: 1200px) {
    .about_line_item:first-child {
      top: 0; } }
  @media screen and (min-width: 1201px) {
    .about_line_item:nth-child(2) {
      left: 20%; } }
  @media screen and (max-width: 1200px) {
    .about_line_item:nth-child(2) {
      top: 20%; } }
  @media screen and (min-width: 1201px) {
    .about_line_item:nth-child(3) {
      left: 40%; } }
  @media screen and (max-width: 1200px) {
    .about_line_item:nth-child(3) {
      top: 40%; } }
  @media screen and (min-width: 1201px) {
    .about_line_item:nth-child(4) {
      left: 60%; } }
  @media screen and (max-width: 1200px) {
    .about_line_item:nth-child(4) {
      top: 60%; } }
  @media screen and (min-width: 1201px) {
    .about_line_item:nth-child(5) {
      left: 80%; } }
  @media screen and (max-width: 1200px) {
    .about_line_item:nth-child(5) {
      top: 80%; } }
  @media screen and (min-width: 1201px) {
    .about_line_item:last-child {
      right: 0;
      margin-left: 0;
      margin-right: -2.2em; } }
  @media screen and (max-width: 1200px) {
    .about_line_item:last-child {
      top: auto;
      bottom: 0;
      margin: 0 0 -1.5em -1.5em; } }
  @media screen and (max-width: 1200px) {
    .about_line_item {
      left: 50%;
      width: 3em;
      height: 3em;
      margin: -1.5em 0 0 -1.5em;
      border-width: 2px; } }

.about_line_item_in {
  position: absolute;
  white-space: nowrap; }
  @media screen and (min-width: 1201px) {
    .about_line_item_in {
      left: 50%;
      height: 15em;
      width: 4px;
      margin-left: -2px; } }
  @media screen and (max-width: 1200px) {
    .about_line_item_in {
      top: 50%;
      width: calc(50vw - 6em);
      height: 2px;
      margin-top: -1px; } }
  .about_line_item_in:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: #fff; }
  .about_line_item_in:after {
    position: absolute;
    content: '';
    border-radius: 50%;
    background-color: #fff; }
    @media screen and (min-width: 1201px) {
      .about_line_item_in:after {
        left: 50%;
        width: 1.8em;
        height: 1.8em;
        margin-left: -.9em; } }
    @media screen and (max-width: 1200px) {
      .about_line_item_in:after {
        top: 50%;
        width: .8em;
        height: .8em;
        margin-top: -.4em; } }
  @media screen and (min-width: 1201px) {
    .about_line_item:nth-child(odd) .about_line_item_in {
      bottom: 100%; }
      .about_line_item:nth-child(odd) .about_line_item_in:after {
        top: 0; } }
  @media screen and (max-width: 1200px) {
    .about_line_item:nth-child(odd) .about_line_item_in {
      right: 100%; }
      .about_line_item:nth-child(odd) .about_line_item_in:after {
        left: -.6em; } }
  @media screen and (min-width: 1201px) {
    .about_line_item:nth-child(even) .about_line_item_in {
      top: 100%; }
      .about_line_item:nth-child(even) .about_line_item_in:after {
        bottom: 0; } }
  @media screen and (max-width: 1200px) {
    .about_line_item:nth-child(even) .about_line_item_in {
      left: 100%; }
      .about_line_item:nth-child(even) .about_line_item_in:after {
        left: 100%; } }

.about_item_text_w {
  position: absolute;
  width: 18.8em;
  white-space: normal; }
  @media screen and (min-width: 1201px) {
    .about_line_item:nth-child(odd) .about_item_text_w {
      top: 0;
      margin-top: -4px; } }
  @media screen and (max-width: 1200px) {
    .about_line_item:nth-child(odd) .about_item_text_w {
      top: 1em;
      left: -.6em;
      margin: 0; } }
  @media screen and (min-width: 1201px) {
    .about_line_item:nth-child(even) .about_item_text_w {
      top: 100%;
      margin-top: -2.2em; } }
  @media screen and (max-width: 1200px) {
    .about_line_item:nth-child(even) .about_item_text_w {
      top: 1em;
      right: 0;
      margin: 0; } }
  @media screen and (min-width: 1201px) {
    .about_line_item.right_text .about_item_text_w {
      left: 3em; } }
  @media screen and (min-width: 1201px) {
    .about_line_item.left_text .about_item_text_w {
      right: 3em;
      text-align: right; } }

.about_item_title {
  width: 100%;
  color: #fefefe;
  font-size: 1.6em;
  font-weight: 700;
  line-height: 1.6875; }

.about_item_text {
  color: #fefefe;
  font-size: 1.4em;
  font-weight: 400;
  line-height: 1.92857; }

.about_healing_music {
  padding-top: 5.2em; }
  @media screen and (max-width: 767px) {
    .about_healing_music {
      padding-top: 0; } }

.about_healing_music_col {
  width: 100%;
  margin-bottom: 9em; }
  .about_healing_music_col:last-child {
    margin-bottom: 0; }
  @media screen and (max-width: 1200px) {
    .about_healing_music_col {
      margin-bottom: 4em;
      flex-flow: wrap; } }

.about_healing_music_sound_wave_wrap {
  position: relative;
  margin-top: -4em;
  width: 100%;
  max-width: 61.2em; }
  .about_healing_music_sound_wave_wrap.right_mod {
    order: 2; }
  @media screen and (max-width: 1200px) {
    .about_healing_music_sound_wave_wrap {
      margin: 4em 0 0;
      order: 2; } }
  @media screen and (max-width: 1024px) {
    .about_healing_music_sound_wave_wrap {
      max-width: none; } }

.about_healing_music_sound_wave {
  width: 100%;
  max-width: 100%;
  margin: 0 auto; }
  .about_healing_music_sound_wave.sm_mod {
    width: 39.4em; }
  .about_healing_music_sound_wave.size_2_mod {
    width: 44em; }

.about_healing_music_sound_wave_img {
  display: block;
  width: 100%;
  height: auto; }

.about_healing_music_sound_wave_title {
  font-size: 1.6em;
  color: #38659e;
  font-weight: 700; }
  .about_healing_music_sound_wave_title.color_mod {
    color: #2e79bd;
    text-align: center; }
  @media screen and (max-width: 767px) {
    .about_healing_music_sound_wave_title {
      text-align: center; } }

.about_healing_music_sound_wave_text {
  font-size: 1.4em;
  line-height: 1.8;
  color: #2d2d2d; }
  @media screen and (max-width: 767px) {
    .about_healing_music_sound_wave_text {
      text-align: center; } }

.about_healing_music_sound_wave_bot {
  text-align: center; }

.about_healing_music_sound_wave_top_item {
  width: 13em; }
  .about_healing_music_sound_wave_top_item + .about_healing_music_sound_wave_top_item {
    margin-left: auto; }
  @media screen and (max-width: 767px) {
    .about_healing_music_sound_wave_top_item {
      width: 100%; }
      .about_healing_music_sound_wave_top_item.color_mod {
        order: 2; }
      .about_healing_music_sound_wave_top_item + .about_healing_music_sound_wave_top_item {
        margin-top: 2em; } }

@media screen and (max-width: 767px) {
  .about_healing_music_sound_wave_top {
    margin-bottom: 2em; } }

.about_healing_music_content_block.left_mod {
  width: 100%;
  max-width: 61em;
  padding-left: .2em;
  margin-left: auto; }
  @media screen and (max-width: 1200px) {
    .about_healing_music_content_block.left_mod {
      max-width: none;
      margin: 0 0 4em;
      padding-left: 0; } }

.about_healing_music_content_block.right_mod {
  width: 100%;
  max-width: 64.4em;
  margin-right: 2em; }
  @media screen and (max-width: 1200px) {
    .about_healing_music_content_block.right_mod {
      max-width: none;
      margin-left: 0;
      padding-left: 0; } }

.about_healing_music_content_block.music_mod {
  max-width: 61em; }

.content_block_in {
  width: 100%;
  max-width: 54.2em; }
  @media screen and (max-width: 1200px) {
    .content_block_in {
      max-width: none; } }

.about_healing_music_musiciants_wrap {
  width: 100%; }
  @media screen and (max-width: 1200px) {
    .about_healing_music_musiciants_wrap {
      margin: 4em -.8em 0; } }
  @media screen and (max-width: 768px) {
    .about_healing_music_musiciants_wrap {
      flex-wrap: wrap; } }
  @media screen and (min-width: 1280px) {
    .about_healing_music_musiciants_wrap {
      max-width: 64.4em;
      margin-left: auto; } }

.musiciant_item {
  display: block;
  width: 25%;
  padding: 0.8em; }
  @media screen and (max-width: 768px) {
    .musiciant_item {
      width: 50%; } }
  @media screen and (max-width: 375px) {
    .musiciant_item {
      width: 100%; } }

.musiciant_item_photo_wrap {
  margin-bottom: 1.4em;
  display: block;
  width: 100%; }

.musiciant_item_photo {
  display: block;
  width: 100%; }

.musiciant_item_name {
  margin-bottom: 2px;
  font-size: 1.6em;
  font-weight: 700;
  color: #373737; }

.musiciant_item_descr {
  font-size: 1.3em;
  font-weight: 400;
  line-height: 2;
  color: #373737; }

.about_healing_slider_block {
  position: relative;
  margin-top: 6.6em;
  max-width: 114em;
  margin: 3em auto 0; }

.about_healing_slider {
  position: relative; }

.about_healing_slider_item {
  position: relative; }

.about_healing_slider_img_wrap {
  position: relative;
  width: calc(100% - 44em);
  margin: 0 auto;
  border-radius: 1.8em; }
  .about_healing_slider_img_wrap:after {
    content: '';
    display: block;
    padding-bottom: 65%; }
  @media screen and (max-width: 1023px) {
    .about_healing_slider_img_wrap {
      width: 100%; } }

.about_healing_slider_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto; }

.about_healing_slider_text_item {
  position: absolute;
  max-width: 24em; }
  .about_healing_slider_text_item.left_mod {
    left: 0;
    padding-right: 4em; }
    .about_healing_slider_text_item.left_mod:after {
      right: -.4em; }
    @media screen and (max-width: 1023px) {
      .about_healing_slider_text_item.left_mod {
        padding-right: 0; } }
  .about_healing_slider_text_item.right_mod {
    right: 0;
    padding-left: 4em; }
    .about_healing_slider_text_item.right_mod:after {
      width: 3.4em;
      left: 0; }
    @media screen and (max-width: 1023px) {
      .about_healing_slider_text_item.right_mod {
        padding-left: 0; } }
  .about_healing_slider_text_item.top_mod {
    top: 15%; }
  .about_healing_slider_text_item.top_0_mod {
    top: 0; }
  .about_healing_slider_text_item.bot_mod {
    bottom: 13%; }
  .about_healing_slider_text_item.center_mod {
    top: 50%;
    transform: translateY(-50%); }
  @media screen and (max-width: 1023px) {
    .about_healing_slider_text_item {
      position: static;
      display: none;
      max-width: 100%;
      margin-bottom: 2em; }
      .about_healing_slider_text_item:last-child {
        margin-bottom: 0; } }

.about_healing_slider_info_img_wrap {
  width: 100%;
  max-width: 13.2em;
  height: 3.2em;
  margin-bottom: .2em; }

.about_healing_slider_info_img {
  width: 100%;
  height: 100%; }

.about_healing_slider_info {
  font-size: 1.4em;
  line-height: 1.8;
  font-weight: 700;
  color: #4675b0; }

.about_healing_slider_dots {
  margin: 1em 0 2em; }

.about_slider_download {
  position: absolute;
  bottom: 3.4em;
  right: 0;
  width: 24em;
  padding: 0 4em; }
  @media screen and (max-width: 1023px) {
    .about_slider_download {
      position: static;
      width: 100%;
      margin-top: 3em; } }

.about_slider_download_item + .about_slider_download_item {
  margin-left: 2.2em; }

.about_slider_download_link {
  display: block;
  width: 3.2em; }

.about_slider_download_img {
  width: 100%; }

.reviews_w_nav {
  padding-bottom: 1.4em;
  margin-bottom: 2.4em;
  border-bottom: 1px solid #0071be; }
  @media screen and (max-width: 767px) {
    .reviews_w_nav {
      align-items: flex-start; } }

.reviews_nav.lang_mod {
  margin-left: auto; }

@media screen and (max-width: 767px) {
  .reviews_nav {
    display: block;
    max-width: 50%; } }

.reviews_tabs {
  display: none; }
  .reviews_tabs.active_state {
    display: block; }

@media screen and (min-width: 768px) {
  .reviews_nav_item {
    margin-right: 5em; }
    .reviews_nav_item:last-of-type {
      margin-right: 0; } }

@media screen and (max-width: 767px) {
  .reviews_nav_item {
    margin-bottom: .6em; } }

.reviews_nav_link {
  color: #0071be;
  font-size: 1.6em;
  font-weight: 700; }
  .reviews_nav_link.active_state {
    color: #1a1a1a; }
  .reviews_nav_link.lang_mod {
    color: #1a1a1a;
    border-bottom: 1px solid transparent; }
    .reviews_nav_link.lang_mod:hover, .reviews_nav_link.lang_mod:focus {
      border-bottom: 1px solid #1a1a1a; }
  .reviews_nav_link:hover, .reviews_nav_link:focus {
    color: #1a1a1a;
    text-decoration: none; }

.reviews_list {
  margin-right: -2em;
  margin-bottom: -2em; }

.reviews_list_item {
  padding-right: 2em;
  padding-bottom: 2em; }
  @media screen and (min-width: 1024px) {
    .reviews_list_item {
      width: 33.33%; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .reviews_list_item {
      width: 50%; } }
  @media screen and (max-width: 767px) {
    .reviews_list_item {
      width: 100%; } }

.reviews_block {
  position: relative;
  height: 18em;
  box-shadow: none;
  transition: box-shadow .3s; }
  .reviews_block:hover, .reviews_block:focus {
    text-decoration: none;
    box-shadow: 0.6rem 0.6rem 2rem rgba(0, 113, 190, 0.3); }

.reviews_block_w_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.reviews_block_img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; }

.reviews_block_info {
  position: relative;
  z-index: 10;
  padding: 1em;
  width: 100%;
  background-color: rgba(239, 243, 246, 0.9); }

.reviews_block_info_in {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  width: 100%;
  padding-left: 2.375em;
  color: #101010;
  font-size: 1.6em;
  font-weight: 400; }
  .reviews_block_info_in:before {
    position: absolute;
    top: 50%;
    left: 2px;
    width: 1.5em;
    height: 1.5em;
    margin-top: -0.75em;
    background: url("../images/play_icon_black.png") no-repeat center center;
    background-size: contain;
    content: ''; }

.circle_item_list {
  padding: 0;
  list-style: none; }
  .circle_item_list.v1_mod {
    margin: 0 -5.4em -4em 0; }
    @media screen and (max-width: 1200px) {
      .circle_item_list.v1_mod {
        margin-right: -2em; } }
    @media screen and (max-width: 1023px) {
      .circle_item_list.v1_mod {
        justify-content: flex-start; } }
  .circle_item_list.v2_mod {
    margin: 0 -5em -5em 0; }
    @media screen and (max-width: 1023px) {
      .circle_item_list.v2_mod {
        margin-right: -2em; } }

.circle_item {
  width: 16.66%; }
  .circle_item.v1_mod {
    padding: 0 5.4em 4em 0;
    width: 20%; }
    @media screen and (max-width: 1200px) {
      .circle_item.v1_mod {
        width: 20%; } }
    @media screen and (max-width: 1024px) {
      .circle_item.v1_mod {
        width: 33.33%;
        padding-right: 2em; } }
    @media screen and (max-width: 1023px) {
      .circle_item.v1_mod {
        width: 50%; } }
    @media screen and (max-width: 480px) {
      .circle_item.v1_mod {
        width: 100%; } }
  .circle_item.v2_mod {
    padding: 0 5em 5em 0;
    width: 16.66%; }
    @media screen and (max-width: 1200px) {
      .circle_item.v2_mod {
        width: 20%; } }
    @media screen and (max-width: 1023px) {
      .circle_item.v2_mod {
        width: 25%;
        padding-right: 2em; } }
    @media screen and (max-width: 640px) {
      .circle_item.v2_mod {
        width: 33.33%; } }
    @media screen and (max-width: 480px) {
      .circle_item.v2_mod {
        width: 50%; } }

.circle_item_in {
  width: 100%; }
  .circle_item_in:hover, .circle_item_in:focus {
    text-decoration: none; }
  @media screen and (max-width: 1023px) {
    .circle_item_in {
      flex-flow: column nowrap; } }
  @media screen and (max-width: 480px) {
    .circle_item_in.v1_mod {
      flex-direction: row; } }

.circle_item_img_w {
  position: relative;
  width: 20.6em;
  height: 20.6em;
  border-radius: 50%;
  overflow: hidden; }
  .circle_item_img_w:after {
    content: '';
    display: block;
    padding-bottom: 100%; }
  @media screen and (max-width: 1024px) {
    .circle_item_img_w {
      width: 15em;
      height: 15em; } }
  @media screen and (max-width: 480px) {
    .circle_item_img_w {
      width: 12em;
      height: 12em; } }
  .circle_item_img_w.v1_mod {
    margin-bottom: 2.2em; }
    @media screen and (max-width: 1023px) {
      .circle_item_img_w.v1_mod {
        margin: 0 2em 0 0;
        flex: 0 0 auto; } }
  .circle_item_img_w.v2_mod {
    margin-bottom: 2.8em; }

.circle_item_img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; }

.circle_item_info_w {
  position: relative;
  padding-left: 3.2em; }

.item_info_icon_w {
  position: absolute;
  top: .4em;
  left: 0;
  width: 2em;
  height: 2em; }

.item_info_icon {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain; }

.circle_item_title {
  color: #fff;
  font-size: 1.6em;
  font-weight: 700;
  line-height: 1.75; }
  .circle_item_title.center_text_mod {
    text-align: center; }
  .circle_item_title.color_mod {
    color: #1c1c1c; }
  @media screen and (max-width: 375px) {
    .circle_item_title {
      font-size: 1.4em; } }

.info_item {
  color: #fff;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.75; }
  @media screen and (max-width: 375px) {
    .info_item {
      font-size: 1.4em; } }

.section_map_w {
  z-index: -1;
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  @media screen and (max-width: 1023px) {
    .section_map_w {
      position: static;
      width: auto;
      margin: 3em -3rem 0; } }

.section_map_img_w {
  width: 100%;
  overflow: hidden; }

.section_img_w {
  display: block;
  width: 100%;
  height: auto; }

.section_col {
  width: 100%; }
  .section_col.text_mod {
    max-width: 52.8em;
    margin-right: 2em; }
    @media screen and (max-width: 1023px) {
      .section_col.text_mod {
        max-width: none;
        margin: 0 0 2em; } }
  .section_col.logo_mod {
    max-width: 56.6em;
    margin-left: auto; }
    @media screen and (max-width: 1023px) {
      .section_col.logo_mod {
        max-width: none;
        margin-left: 0; } }
  .section_col.logos_mod {
    max-width: 100%;
    margin: 0em 0em 0; }
    @media screen and (max-width: 1023px) {
      .section_col.logos_mod {
        justify-content: center;
        flex-flow: wrap;
        max-width: none;
        margin: 2em 0 -4em; } }

.section_logo_banner {
  width: 30%;
  overflow: hidden;
  height: 8em; }
  @media screen and (max-width: 1023px) {
    .section_logo_banner {
      width: 33.33%;
      margin-bottom: 4em; } }
  @media screen and (max-width: 480px) {
    .section_logo_banner {
      width: 96%; } }

.section_logo_banner_img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center; }

.healing_properties_row {
  margin: 0 -4.6em; }
  .healing_properties_row + .healing_properties_row {
    margin-top: 1.2em; }
    @media screen and (max-width: 1023px) {
      .healing_properties_row + .healing_properties_row {
        margin-top: 8.4em; } }
  @media screen and (max-width: 1023px) {
    .healing_properties_row {
      flex-direction: column;
      height: fit-content; } }
  .healing_properties_row.overflow_mod {
    overflow: hidden; }
    @media screen and (max-width: 768px) {
      .healing_properties_row.overflow_mod {
        padding-bottom: 4em; } }
  @media screen and (max-width: 768px) {
    .healing_properties_row.mobile_align_mod {
      display: flex;
      align-items: flex-end; } }
  @media screen and (max-width: 768px) {
    .healing_properties_row.mobile_margin {
      margin-top: 2em; } }
  .healing_properties_row.height_mod {
    height: 52em; }
    @media screen and (max-width: 1024px) {
      .healing_properties_row.height_mod {
        height: fit-content; } }

.healing_properties_col {
  width: 50%;
  padding: 0 4.6em; }
  .healing_properties_row.reverse_mod .healing_properties_col.left_mod {
    order: 2; }
  .healing_properties_col.left_v2_mod {
    width: 53%; }
    @media screen and (max-width: 1023px) {
      .healing_properties_col.left_v2_mod {
        margin-top: -1em;
        margin-bottom: 0em; } }
    @media screen and (max-width: 768px) {
      .healing_properties_col.left_v2_mod {
        width: 100%; } }
    .healing_properties_row.reverse_mod .healing_properties_col.left_v2_mod {
      order: 2; }
  @media screen and (max-width: 1023px) {
    .healing_properties_col.right_mod {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 6.4em; } }
  .healing_properties_row.reverse_mod .healing_properties_col.right_mod {
    order: 1; }
    @media screen and (max-width: 1023px) {
      .healing_properties_row.reverse_mod .healing_properties_col.right_mod {
        order: 3; } }
  .healing_properties_col.right_v2_mod {
    width: 47%; }
    @media screen and (max-width: 1023px) {
      .healing_properties_col.right_v2_mod {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 0.6em;
        margin-bottom: -5em; } }
    @media screen and (max-width: 768px) {
      .healing_properties_col.right_v2_mod {
        width: 95%; } }
    .healing_properties_row.reverse_mod .healing_properties_col.right_v2_mod {
      order: 1; }
      @media screen and (max-width: 1023px) {
        .healing_properties_row.reverse_mod .healing_properties_col.right_v2_mod {
          order: 3; } }
  .healing_properties_col.mobile_margin_top {
    margin-top: 4em; }
  .healing_properties_col.mobile_margin_bottom {
    margin-bottom: 2em; }
  .healing_properties_col.offset_mod_right {
    padding-right: 0; }
    @media screen and (max-width: 640px) {
      .healing_properties_col.offset_mod_right {
        padding-right: 4.6em; } }
  .healing_properties_col.offset_mod_left {
    padding-left: 0; }
    @media screen and (max-width: 640px) {
      .healing_properties_col.offset_mod_left {
        padding-left: 4.6em; } }
  .healing_properties_col.height_mod {
    height: 100%; }
  @media screen and (max-width: 1023px) {
    .healing_properties_col {
      width: 100%; } }

.healing_properties_item {
  margin-bottom: 2.6em;
  display: block; }
  @media screen and (max-width: 768px) {
    .healing_properties_item.center_mobile_mod {
      margin-bottom: -2em;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center; } }

.healing_properties_info_block {
  position: relative;
  display: flex;
  width: 44em;
  font-size: 1.1em;
  font-weight: 400;
  line-height: 1.45;
  color: #fefefe;
  border: 2px solid #497fc1;
  border-radius: 0.5em; }
  .healing_properties_info_block::before {
    position: relative;
    display: block;
    width: 6.8em;
    height: 7em;
    content: '';
    background: url(../images/healing_properies/atom.png) no-repeat center center;
    background-size: 2.6em 2.6em; }
  @media screen and (max-width: 1200px) {
    .healing_properties_info_block {
      width: 100%;
      max-width: 44em; } }
  @media screen and (max-width: 1023px) {
    .healing_properties_info_block {
      justify-content: center;
      margin: 0 auto; } }

.healing_properties_info_block_v2 {
  position: relative;
  display: flex;
  width: 100%;
  padding: 2em 2em 2em 7em;
  font-size: 1.2em;
  font-weight: 400;
  line-height: 1.7em;
  color: black;
  text-align: left;
  background-color: #f4f8fe;
  border-radius: 0.5em; }
  .healing_properties_info_block_v2::before {
    position: absolute;
    display: block;
    top: 0em;
    left: 2.4em;
    width: 2.8em;
    height: 7em;
    content: '';
    background: url(../images/atom.png) no-repeat center center;
    background-size: 2.6em 2.6em; }
  .healing_properties_info_block_v2.offset_mod {
    margin-bottom: 2em; }
  @media screen and (max-width: 1200px) {
    .healing_properties_info_block_v2 {
      max-width: 44em; } }
  @media screen and (max-width: 1023px) {
    .healing_properties_info_block_v2 {
      width: 100%;
      justify-content: center;
      margin: 0 auto; } }

.healing_properties_info_block_in {
  display: block;
  width: 37.2em; }

.healing_properties_charts_block {
  width: 100%; }
  .healing_properties_charts_block.size_mod {
    height: 40em; }
  .healing_properties_charts_block.left_mod {
    margin-top: 4em; }
    @media screen and (max-width: 1023px) {
      .healing_properties_charts_block.left_mod {
        margin-right: auto; } }
  @media screen and (max-width: 1023px) {
    .healing_properties_charts_block.right_mod {
      margin: 0 auto; } }
  .healing_properties_charts_block.height_mod {
    height: 100%; }
  @media screen and (max-width: 1023px) {
    .healing_properties_charts_block {
      justify-content: center; } }

.healing_properties_img_wrap {
  position: relative; }
  .healing_properties_img_wrap.pie_charts_mod {
    width: 3.8em; }
    @media screen and (max-width: 1023px) {
      .healing_properties_img_wrap.pie_charts_mod {
        margin-right: 5em; } }
    @media screen and (max-width: 480px) {
      .healing_properties_img_wrap.pie_charts_mod {
        margin-right: 2.6em; } }
  .healing_properties_img_wrap.size_mod {
    height: 100%; }
  .healing_properties_img_wrap.chart_1_mod {
    width: 100%;
    display: flex;
    justify-content: center; }
  .healing_properties_img_wrap.chart_2_mod {
    width: 70.6%;
    display: flex;
    justify-content: center; }
    @media screen and (max-width: 1023px) {
      .healing_properties_img_wrap.chart_2_mod {
        margin-right: 4em; } }
    @media screen and (max-width: 480px) {
      .healing_properties_img_wrap.chart_2_mod {
        margin-right: 2em; } }

.healing_properties_img_wrap_v2 {
  position: relative; }
  .healing_properties_img_wrap_v2.pie_charts_mod {
    width: 3.8em; }
    @media screen and (max-width: 1023px) {
      .healing_properties_img_wrap_v2.pie_charts_mod {
        margin-right: 5em; } }
    @media screen and (max-width: 480px) {
      .healing_properties_img_wrap_v2.pie_charts_mod {
        margin-right: 2.6em; } }
  .healing_properties_img_wrap_v2.size_mod {
    height: 100%; }
  .healing_properties_img_wrap_v2.chart_1_mod {
    width: 100%;
    display: flex;
    justify-content: center; }
  .healing_properties_img_wrap_v2.chart_2_mod {
    width: 70.6%;
    display: flex;
    justify-content: center; }
    @media screen and (max-width: 1023px) {
      .healing_properties_img_wrap_v2.chart_2_mod {
        margin-right: 4em; } }
    @media screen and (max-width: 480px) {
      .healing_properties_img_wrap_v2.chart_2_mod {
        margin-right: 2em; } }

.healing_properties_chart_img {
  display: block;
  max-width: 100%;
  max-height: 26em;
  object-fit: cover; }
  .healing_properties_chart_img.pain_size_mod {
    height: 44em;
    max-height: unset; }
  .healing_properties_chart_img.u_size_mod {
    width: 36.5em;
    height: auto;
    max-height: unset; }
  .healing_properties_chart_img.u_size_mod_v2 {
    width: 41.7em;
    height: auto;
    max-height: unset; }
  .healing_properties_chart_img.phone_rate_mod {
    width: 36.5em;
    height: auto;
    max-height: unset;
    object-position: 100% 10%; }
  @media screen and (max-width: 768px) {
    .healing_properties_chart_img {
      object-fit: contain; } }

.healing_properties_logo {
  display: block;
  margin-top: -2.4em;
  width: 20.24%; }
  @media screen and (min-width: 1024px) {
    .healing_properties_logo {
      margin-left: 4em; } }
  @media screen and (max-width: 480px) {
    .healing_properties_logo {
      width: 8em; } }

.healing_properties_logo_title {
  margin-bottom: 0.8em;
  display: block;
  font-size: 1.4em;
  font-weight: 700;
  line-height: 2.1;
  color: #6aade8;
  text-align: right;
  letter-spacing: 0.07em;
  text-transform: uppercase; }

.healing_properties_logo_img_wrap {
  margin-right: 0.4em;
  display: block;
  width: 100%; }
  @media screen and (max-width: 480px) {
    .healing_properties_logo_img_wrap {
      width: 8em; } }

.healing_properties_logo_img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover; }

.half_lg_img_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%; }
  .half_lg_img_wrap.sm_mod {
    max-width: 40em;
    margin: 0 auto; }

.list_content {
  line-height: 3em; }

.list_icon_content {
  line-height: 3em; }

.list_icon_title {
  font-size: 1.8em;
  color: #4a6b9b;
  padding-left: 1em; }

.list_title {
  font-size: 1.6em;
  color: #4a6b9b;
  list-style-position: inside;
  text-indent: -1em;
  padding-left: 1em; }
  .list_title::before {
    content: '•';
    color: #a7c8f3;
    margin-right: 0.4em;
    font-size: 1.4em; }

.icon_content {
  display: flex;
  margin-top: 1em;
  justify-content: space-between;
  align-items: center;
  width: 7em; }

.icon_image {
  width: 40%;
  height: auto; }

.list_block {
  display: flex;
  align-items: flex-start;
  padding: 2em 0em;
  width: 32em;
  margin-left: -1em; }

.list_icon {
  width: 4em; }

.percentImageOne {
  position: absolute;
  top: 11.5em;
  right: 13.3em;
  width: 9em;
  border: 1px solid #4577b8;
  padding: 0.7em 0.7em;
  background-color: rgba(69, 119, 184, 0.1); }
  @media screen and (max-width: 1024px) {
    .percentImageOne {
      top: 9.5em;
      right: 7.3em; } }
  @media screen and (max-width: 768px) {
    .percentImageOne {
      top: 8.5em;
      right: 21.3em; } }
  @media screen and (max-width: 640px) {
    .percentImageOne {
      top: 8.5em;
      right: 10.3em; } }
  @media screen and (max-width: 480px) {
    .percentImageOne {
      top: 10.5em;
      right: 5.3em;
      width: 7em; } }
  @media screen and (max-width: 320px) {
    .percentImageOne {
      top: 12.5em;
      right: 4.3em;
      width: 6em; } }

.percentImageTwo {
  position: absolute;
  bottom: 9em;
  right: 13.3em;
  width: 9em;
  border: 1px solid #4577b8;
  padding: 0.7em 0.7em;
  background-color: rgba(69, 119, 184, 0.1); }
  @media screen and (max-width: 1024px) {
    .percentImageTwo {
      bottom: 9em;
      right: 7.3em; } }
  @media screen and (max-width: 768px) {
    .percentImageTwo {
      bottom: 9em;
      right: 21.3em; } }
  @media screen and (max-width: 640px) {
    .percentImageTwo {
      bottom: 9em;
      right: 10.3em; } }
  @media screen and (max-width: 480px) {
    .percentImageTwo {
      bottom: 10em;
      right: 5.3em;
      width: 7em; } }
  @media screen and (max-width: 320px) {
    .percentImageTwo {
      bottom: 12em;
      right: 4.3em;
      width: 6em; } }

.healing_properties_phone_img {
  display: block;
  z-index: 3;
  object-fit: cover;
  overflow: hidden;
  width: 36em;
  height: auto;
  max-height: unset;
  object-position: 100% 100%; }

.healing_properties_phonecontent_img {
  position: absolute;
  top: 2.2em;
  width: 30em;
  z-index: 2;
  object-fit: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  -webkit-animation-name: healfade;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 20s;
  animation-name: healfade;
  animation-iteration-count: infinite;
  animation-duration: 20s; }

.healing_properties_phonecontent2_img {
  position: absolute;
  top: 2.2em;
  width: 30em;
  z-index: 2;
  object-fit: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  -webkit-animation-name: healfade;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 20s;
  animation-name: healfade;
  animation-iteration-count: infinite;
  animation-duration: 20s; }

.healing_properties_phonecontent3_img {
  position: absolute;
  top: 2.2em;
  display: block;
  width: 30em;
  z-index: 2;
  object-fit: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  -webkit-animation-name: healfade;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 20s;
  animation-name: healfade;
  animation-iteration-count: infinite;
  animation-duration: 20s; }

.healing_properties_phonecontent4_img {
  position: absolute;
  top: 2.2em;
  display: block;
  width: 30em;
  z-index: 2;
  object-fit: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  -webkit-animation-name: healfade;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 20s;
  animation-name: healfade;
  animation-iteration-count: infinite;
  animation-duration: 20s; }

@-webkit-keyframes healfade {
  0% {
    opacity: 1; }
  20% {
    opacity: 1; }
  25% {
    opacity: 0; }
  95% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes healfade {
  0% {
    opacity: 1; }
  20% {
    opacity: 1; }
  25% {
    opacity: 0; }
  95% {
    opacity: 0; }
  100% {
    opacity: 1; } }

#healImage2 {
  -webkit-animation-delay: -15s; }

#healImage3 {
  -webkit-animation-delay: -10s; }

#healImage4 {
  -webkit-animation-delay: -5s; }

@media screen and (max-width: 1024px) {
  .phoneContentSize {
    width: 29em;
    top: 3.1em; } }

@media screen and (max-width: 480px) {
  .phoneContentSize {
    width: 24em;
    top: 3em; } }

@media screen and (max-width: 375px) {
  .phoneContentSize {
    width: 22em;
    top: 3em; } }

@media screen and (max-width: 320px) {
  .phoneContentSize {
    width: 18em;
    top: 2.4em; } }

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2em;
  z-index: 1000;
  font-size: 1rem;
  pointer-events: none;
  opacity: 0;
  transition: opacity .3s; }
  .popup.active_mod {
    opacity: 1;
    pointer-events: initial; }

.overlay_popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: .6;
  z-index: -1; }

.popup_in {
  position: relative;
  width: 54.2em;
  max-width: 100%;
  max-height: 100%;
  overflow-y: auto;
  background-color: #fff;
  color: #1e1e1e; }

.popup_top {
  padding: 4.2em 5.8em 5.6em;
  border-bottom: 2px solid #3575b6; }
  @media screen and (max-width: 767px) {
    .popup_top {
      padding: 2em; } }

.popup_title {
  margin-bottom: 0.17857em;
  font-size: 4.6em;
  font-weight: 300;
  font-style: italic; }
  @media screen and (max-width: 767px) {
    .popup_title {
      font-size: 3.2em; } }

.popup_text {
  font-size: 1.6em;
  line-height: 2; }
  @media screen and (max-width: 767px) {
    .popup_text {
      font-size: 1.6em; } }

.popup_descr {
  margin-bottom: 1.4em;
  font-size: 1.6em; }
  @media screen and (max-width: 767px) {
    .popup_descr {
      font-size: 1.4em; } }

.popup_bottom {
  padding: 2.2em 2em 2.8em;
  background-color: #eff3f6;
  text-align: center;
  padding: 2em; }

.popup_close {
  position: absolute;
  top: 2.4em;
  right: 2.4em;
  width: 1.4em;
  height: 1.4em;
  color: #3575b6; }

.close_popup_img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center; }

.movie_popup {
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  overflow: auto;
  z-index: 1001;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4); }
  .movie_popup.visible {
    display: block; }

.movie_popupB {
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  overflow: auto;
  z-index: 1001;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4); }
  .movie_popupB.visible {
    display: block; }

.movie_container {
  position: relative;
  padding-top: 25px; }
  @media screen and (max-width: 1024px) {
    .movie_container {
      padding-bottom: 56.25%;
      /* 16:9 */ } }

.movie_containerB {
  position: relative;
  padding-top: 25px; }
  @media screen and (max-width: 1024px) {
    .movie_containerB {
      padding-bottom: 56.25%;
      /* 16:9 */ } }

.popup_iframe {
  background-color: #FFFFFF;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  vertical-align: middle;
  width: 50em;
  height: 28em;
  margin: 15% auto 0; }
  @media screen and (min-width: 2000px) {
    .popup_iframe {
      margin: 5% auto 0; } }
  @media screen and (max-width: 1024px) {
    .popup_iframe {
      margin: 40% auto 0;
      width: 100vw;
      height: 90%; } }
  @media screen and (max-width: 480px) {
    .popup_iframe {
      margin: 60% auto 0; } }

.popup_iframeB {
  background-color: #FFFFFF;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  vertical-align: middle;
  width: 50em;
  height: 28em;
  margin: 15% auto 0;
  overflow: hidden; }
  @media screen and (max-width: 1024px) {
    .popup_iframeB {
      margin: 40% auto 0;
      width: 100vw;
      height: 90%; } }
  @media screen and (max-width: 480px) {
    .popup_iframeB {
      margin: 60% auto 0; } }

.popup_close_movie {
  position: absolute;
  cursor: pointer;
  width: 1.5em;
  height: auto;
  right: 5em;
  top: 5em;
  z-index: 1002;
  background: no-repeat top center; }
  @media screen and (max-width: 768px) {
    .popup_close_movie {
      right: 1em;
      top: 1em; } }

.section_content_w {
  width: 100%; }
  .section_content_w p {
    width: 100%;
    margin-bottom: 1.5em;
    color: #191919;
    font-family: "Bariol", sans-serif;
    font-size: 2em;
    font-weight: 400;
    line-height: 1.6; }
    .section_content_w p:last-child {
      margin-bottom: 0; }
  .section_content_w ul {
    margin: 0;
    padding: 0;
    list-style: none; }
  .section_content_w li {
    color: #191919;
    font-family: "Bariol", sans-serif;
    font-size: 2em;
    font-weight: 400;
    line-height: 1.8; }
  .section_content_w a {
    color: #000; }

.policy_page_links {
  padding: 1em 0 3.3em; }

.policy_page_link {
  font-size: 2.2em;
  font-style: italic;
  font-weight: 600;
  cursor: pointer; }
  .policy_page_link.policy_page_links_mod {
    color: #ccc; }

.policy_page_link_span {
  padding: 0 1.8em;
  min-height: 100%;
  font-size: 2em;
  color: #aaa; }

.cookies_manage {
  margin-bottom: 4.4em;
  padding-bottom: 3.8em;
  border-bottom: .7em solid #eee; }
  .cookies_manage:last-of-type {
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0; }

.cookies_manage_title {
  color: #474747;
  font-size: 4em;
  font-weight: 300;
  font-style: italic;
  line-height: 1.2; }
  .cookies_manage_title.offset_mod {
    margin-bottom: 0.15em; }

.cookies_manage_descr {
  color: #2e2e2e;
  font-size: 1.8em;
  font-weight: 400;
  letter-spacing: .01rem;
  line-height: 1.7; }
  .cookies_manage_descr.offset_mod {
    margin-bottom: 1.33333em; }
  @media screen and (min-width: 768px) {
    .cookies_manage_descr {
      max-width: 45em;
      width: 100%; } }

.cookies_manage_w_list {
  margin-bottom: 3.4em; }

@media screen and (min-width: 768px) {
  .cookies_manage_list.limit_mod {
    max-width: 52em;
    width: 100%; } }

.cookies_manage_list + .cookies_manage_list {
  margin-top: 1.8em; }

@media screen and (min-width: 768px) {
  .cookies_manage_list {
    display: table;
    margin-bottom: -1.8em; } }

@media screen and (max-width: 767px) {
  .cookies_manage_list {
    display: block; } }

@media screen and (min-width: 768px) {
  .cookies_manage_row {
    display: table-row; } }

@media screen and (max-width: 767px) {
  .cookies_manage_row {
    margin-bottom: 2em; }
    .cookies_manage_row:last-of-type {
      margin-bottom: 0; } }

@media screen and (min-width: 768px) {
  .cookies_manage_list_item {
    display: table-cell;
    padding-right: 1em;
    padding-bottom: 1.8em; } }

@media screen and (max-width: 767px) {
  .cookies_manage_list_item {
    margin-bottom: 1em; }
    .cookies_manage_list_item:last-of-type {
      margin-bottom: 0; } }

.cookies_manage_list_title {
  color: #4d4d4d;
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: .04rem; }

a.cookies_manage_list_title {
  color: #4574af;
  border-bottom: 1px solid transparent; }
  a.cookies_manage_list_title:hover, a.cookies_manage_list_title:focus {
    text-decoration: none;
    border-color: #4574af; }

.btn_switch {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 2.8em;
  cursor: pointer; }

.btn_switch_title {
  color: #4574af;
  font-size: 1.8em;
  font-weight: bold;
  text-transform: uppercase; }

.btn_switch_in {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 7.6em;
  height: 2.8em;
  border-radius: 1.4em;
  border: 2px solid #dedede;
  background-color: #f8f9ff;
  pointer-events: none; }
  .btn_switch_in.offset_mod {
    margin-right: 1em; }

.switch {
  position: relative;
  display: inline-block;
  width: 3em;
  height: 1.6em;
  line-height: 1.6; }

.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

.switch input:checked + .slider {
  background-color: rgba(25, 118, 210, 0.38); }
  .switch input:checked + .slider:before {
    transform: translateX(1.4em);
    opacity: 1;
    background-color: #4286f5; }

.slider {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: .8em;
  margin-top: -.4em;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 1em;
  background-color: rgba(0, 0, 0, 0.38); }
  .slider:before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 1.6em;
    height: 1.6em;
    margin-top: -.8em;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    background-color: #fafafa;
    transition: all .3s ease;
    content: ""; }

.sanofi_redbox {
  display: flex;
  flex-direction: row;
  padding: 1.5em 0 2em;
  text-align: center;
  border-radius: 10px; }

.sanofi_redbox_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%; }
  @media screen and (max-width: 640px) {
    .sanofi_redbox_container {
      width: 100%; } }

.sanofi_redbox_title {
  background-color: #e76e4e;
  color: white;
  width: 100%;
  height: 5rem;
  font-size: 1.3em;
  padding: .2em 1.2em; }
  .sanofi_redbox_title.border_mod_left {
    border-top-left-radius: 10px; }
  .sanofi_redbox_title.border_mod_right {
    border-top-right-radius: 10px; }
  @media screen and (max-width: 768px) {
    .sanofi_redbox_title {
      height: 5rem; } }
  @media screen and (max-width: 480px) {
    .sanofi_redbox_title {
      height: 6rem; } }
  @media screen and (max-width: 375px) {
    .sanofi_redbox_title {
      height: 8rem; } }

.sanofi_redbox_descr {
  width: 100%; }

.safoni_redbox_descr {
  background-color: #fdf3f5;
  color: #e66e4e;
  width: 100%;
  font-size: 1.2em;
  padding: 0.1em 2.5em; }
  .safoni_redbox_descr.border_mod_left {
    border-bottom-left-radius: 10px; }
  .safoni_redbox_descr.border_mod_right {
    border-bottom-right-radius: 10px; }
  .safoni_redbox_descr.middle {
    font-weight: bold; }

.sanofi_btn_container {
  display: flex;
  flex-direction: row; }
  @media screen and (max-width: 640px) {
    .sanofi_btn_container {
      flex-direction: column;
      align-items: center; } }

.safoni_button_descr {
  color: #848484;
  font-size: 0.8em; }
  @media screen and (max-width: 768px) {
    .safoni_button_descr {
      margin-top: -0.6em; } }

.sanofi_btn {
  padding-right: 2em; }
  @media screen and (max-width: 640px) {
    .sanofi_btn {
      margin-bottom: 2em;
      padding-right: 0em; } }

.statsbox_section {
  display: flex;
  padding: 1.5em 1em 3em 1em; }
  @media screen and (max-width: 640px) {
    .statsbox_section {
      flex-direction: column;
      align-items: center;
      padding: 1.5em 0em 3em 0em; } }

.statsbox_in {
  display: flex;
  padding-right: 1.5em;
  min-width: fit-content; }
  .statsbox_in:not(:last-child) {
    border-right: 1px solid #d0d0d0; }
    @media screen and (max-width: 768px) {
      .statsbox_in:not(:last-child) {
        border-right: none; } }
  .statsbox_in:not(:first-child) {
    padding-left: 1.5em; }
    @media screen and (max-width: 640px) {
      .statsbox_in:not(:first-child) {
        padding-top: 1.5em; } }
  @media screen and (max-width: 768px) {
    .statsbox_in:first-child {
      padding-left: 0em;
      margin-right: 6em; } }
  @media screen and (max-width: 640px) {
    .statsbox_in {
      padding: 0em; } }

.statsbox_text {
  display: flex;
  flex-direction: column; }

.statsbox_image_container {
  width: 4.2em;
  margin-right: 1.5em; }

.statsbox_image {
  width: 100%;
  margin-top: 1.3em; }

.statsbox_stat {
  font-weight: 350;
  font-size: 5em;
  position: relative; }
  @media screen and (max-width: 640px) {
    .statsbox_stat {
      text-align: left;
      padding-left: 1rem; } }

.statsbox_descr {
  font-size: 2em; }
  @media screen and (max-width: 768px) {
    .statsbox_descr {
      text-align: left; } }

.statsbox_disclaimer::before {
  position: absolute;
  top: 0.1em;
  left: 0.3em;
  content: "*";
  font-size: 0.5em; }

.sanofi_disclaimer {
  color: #848484;
  font-size: 1.3em;
  padding: 2em 1em 0; }

@font-face {
  font-family: "iconfont";
  src: url("../fonts/icons/iconfont.eot");
  src: url("../fonts/icons/iconfont.eot?#iefix") format("eot"), url("../fonts/icons/iconfont.woff") format("woff"), url("../fonts/icons/iconfont.ttf") format("truetype"), url("../fonts/icons/iconfont.svg#iconfont") format("svg"); }

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor; }

.icon-check {
  font-size: 3.2rem;
  width: 1em; }

.icon-empty {
  font-size: 4.544rem;
  width: 1em; }

.icon-facebook {
  font-size: 4.965rem;
  width: 1em; }

.icon-twitter {
  font-size: 4.965rem;
  width: 1em; }

.icon-youtube {
  font-size: 9.775rem;
  width: 1em; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-dots li {
  position: relative;
  margin: 0 1.2em 0 0; }
  .slick-dots li.slick-active button {
    background-color: #f4f5f7; }
    .about_healing_slider_dots .slick-dots li.slick-active button {
      background-color: #f4f5f7; }
  .about_healing_slider_dots .slick-dots li {
    margin-right: 1.4em; }
    .about_healing_slider_dots .slick-dots li:last-child {
      margin-right: 0; }

.slick-dots button {
  display: block;
  width: 1em;
  height: 1em;
  font-size: .8em;
  color: transparent;
  background-color: #c2c6c8;
  border-radius: 50%; }

.about_healing_slider_dots .slick-dots {
  justify-content: center; }
