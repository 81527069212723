.healing_properties_row {
	@extend %global_flex_block_row_nowrap_flex-start_center;
	margin: 0 -4.6em;

	& + & {
		margin-top: 1.2em;

		@include bp($point_3) {
			margin-top: 8.4em;
		}
	}

	@include bp($point_3) {
		flex-direction: column;
		height: fit-content;
	}

	&.overflow_mod {
		overflow: hidden;

		@include bp($point_4) {
			padding-bottom: 4em;
		}
	}

	&.mobile_align_mod {
		@include bp($point_4) {
			display: flex;
			align-items: flex-end;
		}
	}

	&.mobile_margin {
		@include bp($point_4) {
			margin-top: 2em;
		}
	}

	&.height_mod {
		height: 52em;

		@include bp($point_2) {
			height: fit-content;
		}
	}
}

.healing_properties_col {
	width: 50%;
	padding: 0 4.6em;

	&.left_mod {
		@include bp($point_3) {}

		.healing_properties_row.reverse_mod & {
			order: 2;
		}
	}

	&.left_v2_mod {
		width: 53%;
		@include bp($point_3) {
			margin-top: -1em;
			margin-bottom: 0em;
		}

		@include bp($point_4) {
			width: 100%;
		}

		.healing_properties_row.reverse_mod & {
			order: 2;
		}
	}

	&.right_mod {

		@include bp($point_3) {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			margin-top: 6.4em;
		}

		.healing_properties_row.reverse_mod & {
			order: 1;

			@include bp($point_3) {
				order: 3;
			}
		}
	}

	&.right_v2_mod {
		width: 47%;

		@include bp($point_3) {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			margin-top: 0.6em;
			margin-bottom: -5em;
		}

		@include bp($point_4) {
			width: 95%;
		}

		.healing_properties_row.reverse_mod & {
			order: 1;

			@include bp($point_3) {
				order: 3;
			}
		}
	}

	&.mobile_margin_top {
		margin-top: 4em;
	}

	&.mobile_margin_bottom {
		margin-bottom: 2em;
	}

	&.offset_mod_right {
		padding-right: 0;

		@include bp($point_5) {
			padding-right: 4.6em;
		}
	}

	&.offset_mod_left {
		padding-left: 0;
		@include bp($point_5) {
			padding-left: 4.6em;
		}
	}

	&.height_mod {
		height: 100%;
	}

	@include bp($point_3) {
		width: 100%;
	}
}

.healing_properties_item {
	margin-bottom: 2.6em;
	display: block;

	&.center_mobile_mod {
		@include bp($point_4) {
			margin-bottom: -2em;
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
		}
	}
}

.healing_properties_info_block {
	@extend %global_flex_block_row_nowrap_flex-start_center;

	position: relative;
	display: flex;
	width: 44em;

	font-size: 1.1em;
	font-weight: 400;
	line-height: 1.45;
	color: $white_3;

	border: 2px solid #497fc1;
	border-radius: 0.5em;

	&::before {
		position: relative;
		display: block;
		width: 6.8em;
		height: 7em;

		content: '';

		background: url(../images/healing_properies/atom.png) no-repeat center center;
		background-size: 2.6em 2.6em;
	}

	@include bp($point_1) {
		width: 100%;
		max-width: 44em;
	}

	@include bp($point_3) {
		justify-content: center;
		margin: 0 auto;
	}
}

.healing_properties_info_block_v2 {
	@extend %global_flex_block_row_nowrap_flex-start_center;

	position: relative;
	display: flex;
	width: 100%;
	padding: 2em 2em 2em 7em;

	font-size: 1.2em;
	font-weight: 400;
	line-height: 1.7em;
	color: black;
	text-align: left;

	background-color: #f4f8fe;
	border-radius: 0.5em;

	&::before {
		position: absolute;
		display: block;
		top: 0em;
		left: 2.4em;
		width: 2.8em;
		height: 7em;

		content: '';

		background: url(../images/atom.png) no-repeat center center;
		background-size: 2.6em 2.6em;
	}

	&.offset_mod {
		margin-bottom: 2em;
	}

	@include bp($point_1) {
		max-width: 44em;
	}

	@include bp($point_3) {
		width: 100%;
		justify-content: center;
		margin: 0 auto;
	}
}

.healing_properties_info_block_in {
	display: block;
	width: 37.2em;
}

.healing_properties_charts_block {
	@extend %global_flex_block_row_nowrap_space_between_flex-start;

	width: 100%;

	&.size_mod {
		height: 40em;
	}

	&.left_mod {
		// margin-right: 5em;
		margin-top: 4em;

		@include bp($point_3) {
			//margin-top: 6.4em;
			margin-right: auto;
		}

	}

	&.right_mod {

		@include bp($point_3) {
			//order: 3;
			margin: 0 auto;
		}

	}

	&.height_mod {
		height: 100%;
	}

	@include bp($point_3) {
		justify-content: center;
	}
}

.healing_properties_img_wrap {
	position: relative;
	&.pie_charts_mod {
		width: 3.8em;

		@include bp($point_3) {
			margin-right: 5em;
		}

		@include bp($point_6) {
			margin-right: 2.6em;
		}
	}

	&.size_mod {
		height: 100%;
	}

	&.chart_1_mod {
		width: 100%;

		display: flex;
		justify-content: center;
	}

	&.chart_2_mod {
		width: 70.6%;

		display: flex;
		justify-content: center;

		@include bp($point_3) {
			margin-right: 4em;
		}

		@include bp($point_6) {
			margin-right: 2em;
		}
	}
}

.healing_properties_img_wrap_v2 {
	position: relative;
	&.pie_charts_mod {
		width: 3.8em;

		@include bp($point_3) {
			margin-right: 5em;
		}

		@include bp($point_6) {
			margin-right: 2.6em;
		}
	}

	&.size_mod {
		height: 100%;
	}

	&.chart_1_mod {
		width: 100%;

		display: flex;
		justify-content: center;
	}

	&.chart_2_mod {
		width: 70.6%;

		display: flex;
		justify-content: center;

		@include bp($point_3) {
			margin-right: 4em;
		}

		@include bp($point_6) {
			margin-right: 2em;
		}
	}
}

.healing_properties_chart_img {
	display: block;
	max-width: 100%;
	max-height: 26em;

	object-fit: cover;
	&.pain_size_mod {
		height: 44em;
		max-height: unset;
	}
	&.u_size_mod {
		width: 36.5em;
		height: auto;
		max-height: unset;
	}
	&.u_size_mod_v2 {
		width: 41.7em;
		height: auto;
		max-height: unset;
	}
	&.phone_rate_mod {
		width: 36.5em;
		height: auto;
		max-height: unset;
		object-position: 100% 10%;
	}

	@include bp($point_4) {
		//max-height: unset;
		object-fit: contain;
	}
}

.healing_properties_logo {
	display: block;
	margin-top: -2.4em;
	width: 20.24%;

	@include bp($point_2, min) {
		margin-left: 4em;
	}

	@include bp($point_6) {
		width: 8em;
	}

}

.healing_properties_logo_title {
	margin-bottom: 0.8em;
	display: block;

	font-size: 1.4em;
	font-weight: 700;
	line-height: 2.1;
	color: $accent_color_2;

	text-align: right;
	letter-spacing: 0.07em;
	text-transform: uppercase;
}

.healing_properties_logo_img_wrap {
	margin-right: 0.4em;
	display: block;
	width: 100%;

	@include bp($point_6) {
		width: 8em;
	}
}

.healing_properties_logo_img {
	display: block;
	width: 100%;
	height: auto;

	object-fit: cover;
}

.half_lg_img_wrap {
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;

	width: 100%;

	&.sm_mod {
		max-width: 40em;
		margin: 0 auto;
	}
}

.list_content {
	line-height: 3em;
}

.list_icon_content {
	line-height: 3em;
}

.list_icon_title{
	font-size: 1.8em;
	color: #4a6b9b;
	padding-left: 1em;
}

.list_title {
	font-size: 1.6em;
	color: #4a6b9b;
	list-style-position: inside;
	text-indent: -1em;
	padding-left: 1em;

	&::before {
		content: '•';
		color: #a7c8f3;
		margin-right: 0.4em;
		font-size: 1.4em;
	}
}

.icon_content {
	display: flex;
	margin-top: 1em;
	justify-content: space-between;
	align-items: center;
	width: 7em;
}

.icon_image {
	width: 40%;
	height: auto;
}

.list_block {
	display: flex;
	align-items: flex-start;
	padding: 2em 0em;
	width: 32em;
	margin-left: -1em;
}

.list_icon {
	width: 4em;
}

.percentImageOne {
	position: absolute;
	top: 11.5em;
	right: 13.3em;
	width: 9em;
	border: 1px solid #4577b8;
	padding: 0.7em 0.7em;
	background-color: rgba(69, 119, 184, 0.1);

	@include bp($point_2) {
		top: 9.5em;
		right: 7.3em;
	}
	@include bp($point_4) {
		top: 8.5em;
		right: 21.3em;
	}
	@include bp($point_5) {
		top: 8.5em;
		right: 10.3em;
	}
	@include bp($point_6) {
		top: 10.5em;
		right: 5.3em;
		width: 7em;
	}
	@include bp($point_8) {
		top: 12.5em;
		right: 4.3em;
		width: 6em;
	}
}

.percentImageTwo {
	position: absolute;
	bottom: 9em;
	right: 13.3em;
	width: 9em;
	border: 1px solid #4577b8;
	padding: 0.7em 0.7em;
	background-color: rgba(69, 119, 184, 0.1);

	@include bp($point_2) {
		bottom: 9em;
		right: 7.3em;
	}
	@include bp($point_4) {
		bottom: 9em;
		right: 21.3em;
	}
	@include bp($point_5) {
		bottom: 9em;
		right: 10.3em;
	}
	@include bp($point_6) {
		bottom: 10em;
		right: 5.3em;
		width: 7em;
	}
	@include bp($point_8) {
		bottom: 12em;
		right: 4.3em;
		width: 6em;
	}
}

.healing_properties_phone_img {
	display: block;
	z-index: 3;
	object-fit: cover;
	overflow: hidden;

	width: 36em;
	height: auto;
	max-height: unset;
	object-position: 100% 100%;
}

.healing_properties_phonecontent_img {
	position: absolute;
	top: 2.2em;
	//display: block;
	//width: 53%;
	width: 30em;
	//max-width: 60%;
	//max-height: 26em;
	z-index: 2;
	object-fit: cover;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;

    -webkit-animation-name: healfade;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 20s;
    animation-name: healfade;
    animation-iteration-count: infinite;
    animation-duration: 20s;
}

.healing_properties_phonecontent2_img {
	position: absolute;
	top: 2.2em;
	//display: block;
	width: 30em;
	//max-width: 60%;
	//max-height: 26em;
	z-index: 2;
	object-fit: cover;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;

    -webkit-animation-name: healfade;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 20s;
    animation-name: healfade;
    animation-iteration-count: infinite;
    animation-duration: 20s;
}

.healing_properties_phonecontent3_img {
	position: absolute;
	top: 2.2em;
	display: block;
	width: 30em;
	//max-width: 60%;
	//max-height: 26em;
	z-index: 2;
	object-fit: cover;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;

    -webkit-animation-name: healfade;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 20s;
    animation-name: healfade;
    animation-iteration-count: infinite;
    animation-duration: 20s;
}

.healing_properties_phonecontent4_img {
	position: absolute;
	top: 2.2em;
	display: block;
	width: 30em;
	//max-width: 60%;
	//max-height: 26em;
	z-index: 2;
	object-fit: cover;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;

    -webkit-animation-name: healfade;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 20s;
    animation-name: healfade;
    animation-iteration-count: infinite;
    animation-duration: 20s;
}

@-webkit-keyframes healfade {
    0% {opacity: 1;}
    20% {opacity: 1;}
    25% {opacity: 0;}
    95% {opacity: 0;}
    100% {opacity: 1;}
}
@keyframes healfade {
    0% {opacity: 1;}
    20% {opacity: 1;}
    25% {opacity: 0;}
    95% {opacity: 0;}
    100% {opacity: 1;}
}

#healImage1 {

}

#healImage2 {
    -webkit-animation-delay: -15s;
}

#healImage3 {
    -webkit-animation-delay: -10s;
}

#healImage4 {
    -webkit-animation-delay: -5s;
}

.phoneContentSize {
	@include bp($point_2) {
		width: 29em;
		top: 3.1em;
	}
	@include bp($point_6) {
		width: 24em;
		top: 3em;
	}
	@include bp($point_7) {
		width: 22em;
		top: 3em;
	}
	@include bp($point_8) {
		width: 18em;
		top: 2.4em;
	}
}
