.banner_link {
	@extend %global_flex_block_column_nowrap_flex-start_center;
	&:hover, &:focus {
		text-decoration: none;
	}
}

.banner_icon_wrap {
	width: 8em;
	height: 8em;
	padding: 2.2em;
	margin-bottom: 1.6em;

	// border: 1px solid $black;
	background-color: $accent_color;
	border-radius: 50%;

	transition: .3s;

	.banner_link:hover &,
	.banner_link:focus & {
		background-color: $accent_color_4;
	}
}

.banner_icon {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center;
}

.banner_logos_list {
	@extend %global_flex_block_row_wrap_center_center;
	margin-top: 2em;
}

.banner_logo_item {
	position: relative;

	height: 3.4em;
	padding: 0 1.6em;

	&:after {
		content: '';
		position: absolute;
		top: 50%;
		right: 0;

		width: 1px;
		height: 3.4em;
		margin-top: -1.7em;

		background-color: $white;
	}

	&:last-child {
		&:after {
			display: none;
		}
	}
}

.banner_logo {
	height: 100%;
}

.banner_logos_title {
	margin-top: (2.4em/1.4);

	font-size: 1.4em;
	color: #d3d3d3;
}