.award_list {
	@extend %global_flex_block_row_wrap_center;

	margin: 0 auto;

	@include bp($point_4, min) {
		max-width: 92em;
	}
}

.award_item {
	@extend %global_flex_block_column_wrap_flex-start_center;

	width: 25%;
	padding: 0 1em 3em 1em;

	@include bp($point_3) {
		width: 50%;
	}

	@include bp($point_6) {
		width: 100%;
		margin: 0 0 3em 0;
	}
}

.award_img_wrap {
	@extend %global_flex_block_row_wrap_center_center;

	margin: 2em 0 1.6em;
	width: 12em;
	height: 8em;

	&.circle_mod {
		width: 15em;
		height: 15em;
		padding: 3.8em 2.2em;

		border-radius: 50%;
		background-color: $gray_2;
	}
}


.award_img_inner {
	width: 100%;
}

.award_pic {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: contain;
}


.award_item_text {
	width: 100%;

	text-align: center;
	color: $accent_color;
	font-size: 1.4em;
	font-weight: 400;
	line-height: 1.8em;

	@include bp($point_4) {
		text-align: left;
	}

	&.color_w_mod {
		color: $white;
	}

	&.title_mod {
		font-weight: 700;
		font-size: 1.6em;
	}

	&.treatment_mod {
		font-size: 1.6em;
	}
}