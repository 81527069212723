.accordeon_wrap {
	margin: 3.8em auto 0;
}

.accordeon_item {
	padding: 2.4em 0 2.8em;
	border-bottom: 1px solid $white;
	&:first-child {
		border-top: 1px solid $white;
	}
}

.accordeon_title {
	position: relative;

	padding-right: (4.2em/1.6);

	font-size: 1.6em;
	color: $white;

	cursor: pointer;

	&:after {
		content: '';
		position: absolute;
		top: 50%;
		right: 1.6rem;

		height: 1.6rem;
		width: 2.6rem;
		margin-top: -.8rem;

		transform-origin: 50% 50%;
		transition: transform .3s;

		background: url('../images/caret.png') no-repeat center center;
		background-size: contain;
		@include bp($point_4) {
			right: 0;
		}
	}

	&.active_mod {
		&:after {
			transform: rotate(180deg);
		}
	}

	@include bp($point_4) {
		padding-right: (2.6em/1.6);
	}

}

.accordeon_text {
	display: none;
	padding-top: 1em;

	font-size: 1.6em;
	color: $white;
}
