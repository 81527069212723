//------------------------------------------------------------layout
.header {
	@extend %global_flex_block_row_nowrap_flex-start_center;

	position: fixed;
	top: 0;
	left: 0;

	height: $height_header;
	width: 100%;

	font-size: 1rem;

	will-change: transform;

	@include z-index(header);

	background: transparent;
	transition: background-color .3s ease;

	&.scroll_mod {
		background-color: rgba($white, .9);
	}

	@include bp($point_1) {
		height: 6.2rem;
	}

}

.header_in {
	@extend %global_flex_block_row_nowrap_flex-start_center;
	max-width: 130.8rem;
	width: 100%;
	margin: 0 auto;

	@include bp($point_2, min) {
		padding: 0 $gl_indent;
	}

	@include bp($point_3) {
		padding: 0 2em;
	}

}

.logo {
	width: 12em;
	height: 6.2em;
	flex-shrink: 0;
	@include bp($point_1) {
		height: 4.2em;
		width: 8.2em;
	}
}

.logo_img {
	display: block;
	max-width: 100%;
	max-height: 100%;

	.header.scroll_mod & {
		display: none;
	}
}

.logo_img_scroll {
	display: none;
	max-width: 100%;
	max-height: 100%;

	.header.scroll_mod & {
		display: block;
	}
}

.menu_wrap {
	@extend %global_flex_block_row_nowrap_flex-start;
	flex-grow: 1;
	margin-left: 12.4em;
	@include bp(1280px) {
		margin-left: 5em;
	}
	@include bp($point_1) {
		position: absolute;
    top: 0;
		left: 0;

		display: block;
    margin-left: 0;
    width: 100%;
		height: 100vh;
		padding: 7.6em 2em 4em 3.2em;

		background-image: $accent_gradient;
		transform: translateY(-100%);
		transition: transform .3s;

		body.menu_open & {
			transform: translateY(0);
		}
	}
}

.menu_wrap_in {
	width: 100%;
	@include bp($point_1) {

		display: flex;
		height: 100%;
		width: 100%;
		overflow-y: auto;
	}
}

.menu_in {
	@extend %global_flex_block_row_nowrap_flex-start_center;
	width: 100%;
	@include bp($point_1) {
		display: block;
		margin: auto;
	}
}

.main_menu {
	@extend %global_flex_block_row_nowrap_flex-start;
	@include bp($point_1) {
		flex-direction: column;
		// align-items: center;
		width: 100%;
	}
}

.main_menu_item {
	position: relative;
	margin-right: 2.8em;
	&:last-child {
		margin-right: 0;
	}
	@include bp($point_1) {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		margin: 0 0 .8em;
		text-align: center;
	}
	@include bp($point_3) {
		justify-content: flex-start;
	}
}

.main_menu_caret {
	position: absolute;
	top: 50%;
	right: 0;

	&.login_mod{
		right: -35%
	}

	width: 1rem;
	height: .6rem;
	margin-top: -1px;
	background: url('../images/caret_2.png') no-repeat center center;
	background-size: contain;

	transform: rotate(0);
	transform-origin: 50% 50%;
	transition: transform .3s;

	.header.white_mod:not(.scroll_mod) & {
		background: url('../images/caret.png') no-repeat center center;
		background-size: contain;

		&.dark_mod {
			background: url('../images/caret_2.png') no-repeat center center;
			background-size: contain;
		}
	}

	.main_menu_link.active_mod & {
		transform: rotate(180deg);
	}

	@include bp($point_1) {
		background: url('../images/caret.png') no-repeat center center;
		background-size: contain;

		display: none;
	}
}

.main_menu_link {
	position: relative;

	display: block;
	padding-right: (2em/1.6);

	font-size: 1.6em;
	color: $black_2;

	.header.white_mod:not(.scroll_mod) & {
		color: $white;

		&.dark_mod {
			color: black;
		}

		&:hover, &:focus {
			color: $accent_color_2;
			@include bp($point_1) {
				color: $white;
			}
		}

	}

	&.active_link {
		color: $accent_color_2 !important;
	}

	&:hover, &:focus {
		color: $accent_color;
		text-decoration: none;
		@include bp($point_1) {
			color: $white;
		}
	}

	@include bp($point_1) {
		font-size: 3.7em;
		font-weight: 300;
		line-height: 1;
		text-align: left;
		color: $white;
	}



}

.system_menu {
	@extend %global_flex_block_row_nowrap_flex-start;
	margin-left: auto;
	@include bp($point_1) {
		margin-left: 0;
		width: 100%;
		flex-direction: column;
		margin-top: 9em;
	}
}

.system_menu_item {
	margin-left: 1.8em;

	&:first-child {
		margin-left: 0;
	}

	&:after {
		margin-left: (1.6em/1.6);

		color: #434343;
		font-size: 1.6em;

		content: 'I';

		.header.white_mod:not(.scroll_mod) & {
			color: $white;
		}

		@include bp($point_1) {
			color: $white;
			display: none;
		}

	}

	&:last-child {
		&:after {
			content: '';
			display: none;
		}
	}

	@include bp($point_1) {
		margin-bottom: .4em;
		margin-left: 0;

		&:last-child {
			margin-bottom: 0;
		}
	}

}

.system_menu_link {
	font-size: 1.6em;
	font-weight: 700;
	color: #434343;

	border-bottom: 1px solid transparent;

	.header.white_mod:not(.scroll_mod) & {
		color: $white;

		&:hover, &:focus {
			border-color: $white;
		}

	}

	&.dark_mod {
		color: black!important;
	}

	&:hover, &:focus, &:active {
		text-decoration: none;
		border-color: #434343;
	}

	@include bp($point_1) {
		font-size: 2.5em;
		font-weight: 300;
		color: $white;
	}

}

.lang_wrap {
	margin-left: 2.6em;

	@include bp($point_1) {
		display: flex;
		justify-content: center;
		margin: 2em 0 0;
	}

}

.lang_link {
	display: block;
	width: 2.8em;
	height: 1.8em;
}

.lang_img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}



.main_submenu {
	position: absolute;
	top: 100%;
	left: -1.4em;

	min-width: calc(100% + 2.8em);
	padding: 1.4em;

	background-color: $accent_color;

	z-index: 20;

	.main_menu_item:hover & {
		@media(min-width: $point_2) {
			transform: translateY(0);
			opacity: 1;
			pointer-events: initial;
		}
	}

	@media(min-width: $point_2) {
		transform: translateY(20%);
		opacity: 0;
		pointer-events: none;

		transition: .3s;
	}

	@include bp($point_3) {

		&.login_mod{
			align-items: flex-start;
			justify-items: flex-start;
			display: flex;
			flex-direction: column;
			position: relative;
			margin: -0.8em 0 -1em 1em;
			font-weight: 700;
		}

	}

	@include bp($point_1) {
		position: static;
		display: none;
	}
}

.main_submenu_item {
	margin-bottom: 1em;
	&:last-child {
		margin-bottom: 0;
	}
}

.main_submenu_link {
	font-size: 1.4em;
	color: $white;
	white-space: nowrap;
}

//------------------------------------------------------------layout###



//---------------------------------------------trigger
@mixin user_menu_butt_line {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	height: .2rem;
	margin-top: -.1rem;
	background: $black;

	.header.white_mod:not(.scroll_mod) & {
		background: $white;
	}
}

.menu_trigger {
	z-index: 210;
	position: absolute;
	top: 50%;
	right: 2rem;
	display: none; // hidden on wide screens
	height: 1.6rem;
	width: 2.5rem;
	margin-top: -.8rem;
	text-transform: uppercase;

	color: transparent;
	cursor: pointer;

	@include bp($point_1) {
		display: block; // appear on tablets
	}

	&:before {
		content: '';
		transform: translate(0, -.8rem);
		transition: all .3s ease;

		@include user_menu_butt_line;
	}
	&:after {
		content: '';
		transform: translate(0, .8rem);
		transition: all .3s ease;

		@include user_menu_butt_line;
	}
	&.active_mod {
		&:before {
			transform: (rotate(45deg) translate(0, 0));
			background-color: $white;
		}

		&:after {
			transform: (rotate(-45deg) translate(0, 0));
			background-color: $white;
		}
	}
}

.menu_trigger_decor {
	transition: all .3s ease;

	.active_mod & {
		opacity: 0;
		transform: translate(100%, 0);
	}

	@include user_menu_butt_line;
}

//---------------------------------------------trigger###
