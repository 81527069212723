@mixin st_btn() {
	display: inline-block;
	white-space: nowrap;
	text-align: center;

	&:hover, &:focus {
		text-decoration: none;
	}
}

//color extends
%blue_btn_1 {
	font-weight: 700;
	color: $white;

	background-color: $accent_color;
	transition: .3s ease;

	cursor: pointer;

	@include st_btn();

	&:hover {
		background-color: darken($accent_color, 10%);
	}

}

%blue_btn_2 {
	font-weight: 700;
	color: $white;
	background: $accent_color_2;
	transition: .3s ease;

	@include st_btn();

	&:hover {
		background: darken($accent_color_2, 10%);
	}
}

%blue_btn_3 {
	font-weight: 700;
	color: $white;

	background-color: $accent_color_5;
	transition: .3s ease;

	cursor: pointer;

	@include st_btn();

	&:hover {
		background-color: darken($accent_color, 10%);
	}

}

%transparent_btn_1 {
	font-weight: 700;
	color: #4879b7;
	background: transparent;
	transition: .3s ease;
	border: 1px solid #4879b7;

	@include st_btn();

	&:hover {
		background: #4879b7;
		color: $white;
	}
}

%transparent_btn_2 {
	font-weight: 700;
	color: $white;
	background: transparent;
	transition: .3s ease;
	border: 1px solid $white;

	@include st_btn();

	&:hover {
		background: $white;
		color: #4879b7;
	}
}

//size extends
%btn_size_1 {
	height: 4.8rem;
	width: 18.4rem;
	padding: 0 1.2rem;
	line-height: 4.8rem;
	font-size: 1.6rem;
	border-radius: 2.4rem;
}

%btn_size_2 {
	height: 4rem;
	width: 100%;
	padding: 0 1.2rem;
	line-height: 4rem;
	font-size: 1.6rem;
	border-radius: 0;
}

%btn_size_3 {
	height: 5.2rem;
	width: 100%;
	max-width: 31.4rem;
	padding: 0 1.2rem;
	line-height: 5.2rem;
	font-size: 1.6rem;
	border-radius: 0;
}

%btn_size_4 {
	height: 4.8rem;
	width: 20rem;
	padding: 0 1.2rem;
	line-height: 4.5rem;
	font-size: 1.6rem;
	border-radius: 2.4rem;
}

%btn_size_5 {
	height: 3.8rem;
	width: 16.4rem;
	padding: 0 1.2rem;
	line-height: 3.4rem;
	font-size: 1.6rem;
	border-radius: 1.9rem;
}

%btn_size_6 {
	height: 2.8rem;
	width: auto;
	padding: 0 2.4rem;
	line-height: 2.6rem;
	font-size: 1.4rem;
	border-radius: 1.4rem;
}

%btn_size_7 {
	height: 5.2rem;
	width: 100%;
	max-width: 31.4rem;
	padding: 0 1.2rem;
	line-height: 5.2rem;
	font-size: 1.6rem;
	border-radius: 1rem;
}

%btn_size_8 {
	height: 5.2rem;
	width: 100%;
	max-width: 31.4rem;
	padding: 0 1.2rem;
	line-height: 5.2rem;
	font-size: 1.6rem;
	border-radius: 15px;
}

%btn_size_11 {
	height: 4.8rem;
	width: 30rem;
	padding: 0 1.2rem;
	line-height: 4.5rem;
	font-size: 1.6rem;
	border-radius: 2.4rem;
}
