.plans_list {
	@extend %global_flex_block_row_wrap_flex-start_stretch;
	margin: 0 0em -3em;
	width: 120%;
	max-width: 100vw;
	//margin-left: -10%;
	justify-content: center;
	justify-self: center;
	align-content: center;
	align-self: center;
}

.plans_item_wrap {
	width: 33%;
	padding: 0 0 3em;
	&.full_mod {
		width: 100%;
	}
	@include bp($point_3) {
		width: 100%;
	}
}

.plans_price_container {
	@extend %global_flex_block_row_nowrap_flex-start_center;

	&.center_mobile {
		@include bp($point_4) {
			justify-content: center;
		}
	}
}

.plans_descrip {
	@include bp($point_4) {
	}
}

.plans_price {
	font-size: 4.6em;
	color: #0071be;
	align-self: flex-start;

	&.smaller {
		font-size: 1.6em;
	}

	&.smallest {
		font-size: 1em;
		padding-left: 2px;
	}
}

.plans_per {
	font-size: 2.6em;
	padding-top: .3em;

	&.smaller {
		font-size: 1.6em;
		padding-top: 0em;
	}

	&.smallest {
		font-size: 1em;
		padding-top: 0em;
	}
}

.plans_item {
	height: 100%;
	padding: 2.2em 3.2em 2.8em;
	background-color: $gray_2;
	@include bp($point_4) {
		padding-left: 2.2em;
		padding-right: 2.2em;
	}

	&.color_first {
		background-color: #e5eaee;
		padding-top: 2.2em;
		height: 100%;
	}
	&.color_second {
		background-color: #ebf1f6;
	}
	&.color_third {
		background-color: #f6f7f9;
	}
}

.plans_button {
	@extend %global_flex_block_column_nowrap_flex-start_center;
	margin: 10px 0;
	width: 100%;
	position: relative;
}

.plans_multi_button {
	@extend %global_flex_block_column_nowrap_flex-start_center;
	margin: 10px 0;
	width: 100%;
	z-index: 1;
}

.plans_img_wrap {
	position: relative;
	margin: 0 -6.2em 3em;
	width: calc(100% + 12.4em);
	&:after {
		content: '';
		display: block;
		padding-bottom: 41.4%;
	}
	@include bp($point_4) {
		width: calc(100% + 4.4em);
		margin-left: -2.2em;
		margin-right: -2.2em;
	}
}

.plans_img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

.plans_checklist_wrap {
	width: 100%;
	margin-bottom: 3em;
	margin-top: 2.5em;

	&.less_margin {
		margin-bottom: 1em;
		margin-top: 1.6em;
	}
}

.plans_checklist_block {
	margin-bottom: 2.8em;
	&:last-child {
		margin-bottom: 0;
	}
}

.plans_checklist_title {
	margin-bottom: (.6em/1.6);
	font-size: 1.6em;
	font-weight: 700;
	color: $accent_color_2;
	text-transform: uppercase;
}

.plans_checklist_list {

	&.row {
		display: flex;
		flex-direction: row;
		@include bp($point_4) {
			flex-direction: column;
		}
	}

}

.plans_checklist_item {
	margin-bottom: (.8em/1.6);
	padding-left: (.6em/1.6);
	font-size: 1.6em;
	text-indent: -1.3em;
	margin-left: 1.3em;

	&.row {
		min-width: 28%;
		@include bp($point_4) {
			width: 100%;
		}
		&:last-child {
			margin-left: 2.2em;
			width: 31%;
			@include bp($point_4) {
				width: 100%;
				margin-left: 1.3em;
			}
		}
	}

	&:last-child {
		margin-bottom: 0;
	}

	&:before {
		content: '';
		display: inline-flex;
		margin-right: 1rem;
		width: 1rem;
		height: 1rem;
		background: url('../images/check.png') no-repeat center center;
		background-size: contain;
	}
}

.plans_bottom {
	@extend %global_flex_block_column_nowrap_flex-start_center;
	margin-top: auto;
	width: 100%;
}

.plans_form_wrap {
	position: relative;
	width: 100%;
}

.form_submit_arrow {
	position: absolute;
	top: 50%;
	right: 1.8em;

	width: 3em;
	height: 1.6em;
	margin-top: -.8em;

	background: url('../images/arrow.png') no-repeat center center;
	background-size: contain;
	cursor: pointer;
	&:hover, &:focus {
		opacity: .8;
	}
}

.descr_wrap {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.plans_text {
	max-width: (28.8em/1.4);
	margin: 0 auto (3.2em/1.4);

	font-size: 1.4em;
	color: #6a6a6a;
	text-align: center;
}

.btn_link {
	margin-top: (2em/1.6);

	font-size: 1.6em;
	font-weight: 700;
	color: $accent_color;
}

.plans_hr {
	width: 100%;
	height: 2px;
	background-color: rgba(255, 255, 255, 0.5);
	border-color: rgba(255, 255, 255, 0.5);
	margin-bottom: 1em;

	&.hr_mod {
		margin-bottom: 2em;
	}
}

.show {
	@extend %global_flex_block_row_nowrap_center_center;

	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 2em;

	z-index: 1000;

	font-size: 1rem;

	pointer-events: none;
	opacity: 0;

	transition: opacity .3s;

	&.active_mod {
		opacity: 1;
		pointer-events: initial;
	}
}

.show_in {
	position: relative;
	width: 54.2em;
	max-width: 100%;
	max-height: 100%;
	overflow-y: auto;

	background-color: $white;
	color: #1e1e1e;
}

.show_top {
	padding: 4.2em 5.8em 5.6em;
	border-bottom: 2px solid $dark_blue;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@include bp($point_4 - 1) {
		padding: 2em;
	}
}

.plans_price_descr {
	color: #2ea3f7;
	padding-left: 1em;
	font-size: 1.3rem;

	@include bp($point_4) {
		max-width: 10em;
	}
}
