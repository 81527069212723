.section_col {
	width: 100%;

	&.text_mod {
		max-width: 52.8em;
		margin-right: 2em;

		@include bp($point_3) {
			max-width: none;
			margin: 0 0 2em;
		}
	}

	&.logo_mod {
		max-width: 56.6em;
		margin-left: auto;

		@include bp($point_3) {
			max-width: none;
			margin-left: 0;
		}
	}

	&.logos_mod {
		@extend %global_flex_block_row_nowrap_flex-start_center;

		max-width: 100%;
		margin: 0em 0em 0;

		@include bp($point_3) {
			justify-content: center;
			flex-flow: wrap;
			max-width: none;
			margin: 2em 0 -4em;
		}
	}
}


.section_logo_banner {
	width: 30%;
	overflow: hidden;
	height: 8em;

	@include bp($point_3) {
		width: 33.33%;
		margin-bottom: 4em;
	}

	@include bp($point_6) {
		width: 96%;
	}
}

.section_logo_banner_img {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: contain;
	object-position: center;
}
