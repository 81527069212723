//------------------------------------------------------------base styles
html {
	// height: 100%;
	// font: 400 calc(100vw / 1920 * 10)/1.33 $font_1;
	font: 400 10px/1.33 $font_1;
}

body {
	position: relative;

	display: flex;
	 //height: 100%;

	font-size: $font_size_base;
	color: $color_text_base;

	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	background: $body_bg;

	@supports (display: grid) {
		display: block;
	}

	&.popup_open,
	&.menu_open,
	&.popup_mod {
		overflow: hidden;
	}
}

.wrapper {
	position: relative;

	width: 100%;
	display: flex;
	flex-direction: column;
	min-height: 100%;
	height: auto !important;

	overflow: hidden;
	//background-image: linear-gradient(11deg, #0071be 0%, $accent_color 100%);


	// @supports (display: grid) {
	// 	display: grid;
	// 	grid-template-rows: 1fr auto;
	// 	grid-template-areas:
	// 	"base"
	// 	"footer";
	// }
}

.base {
	width: 100%;
	position: relative;
	padding: 0 0 0;

	flex: 1 0 auto;

	grid-area: base;

	&.no_offset_mod {
		padding-top: 0;
	}
}

a {
	text-decoration: none;
	color: $color_link_base;
	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.elements_list {
	padding: 3rem;
}

//------------------------------------------------------------base styles###
.social_list {
	&.footer_mod {
		@extend %global_flex_block_row_wrap_flex-start;

		margin: -.2em -1em 1em 0;
		@include bp($point_7 - 1) {
			justify-content: center;
		}
	}
}

.social_item {
	padding: 0 .8em 0 0;
}

.social_link {
	transition: opacity .3s ease;

	&:hover,
	&:focus {
		opacity: .6;
		text-decoration: none;
	}

}