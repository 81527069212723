//---------------------------------------------cookies_manage
.cookies_manage {
	margin-bottom: 4.4em;
	padding-bottom: 3.8em;

	border-bottom: .7em solid #eee;

	&:last-of-type {
		margin-bottom: 0;
		border-bottom: 0;
		padding-bottom: 0;
	}
	
}

.cookies_manage_title {
	color: #474747;
	font-size: 4em;
	font-weight: 300;
	font-style: italic;
	line-height: 1.2;

	&.offset_mod {
		margin-bottom: (.6em/4);
	}

}

.cookies_manage_descr {
	color: #2e2e2e;
	font-size: 1.8em;
	font-weight: 400;
	letter-spacing: .01rem;
	line-height: 1.7;

	&.offset_mod {
		margin-bottom: (2.4em/1.8);
	}
	
	@include bp($point_4, min) {
		max-width: (81em/1.8);
		width: 100%;
	}

}

.cookies_manage_w_list {
	margin-bottom: 3.4em;
}

.cookies_manage_list {
	&.limit_mod {
		@include bp($point_4, min) {
			max-width: 52em;
			width: 100%;
		}
	}

	& + & {
		margin-top: 1.8em;
	}

	@include bp($point_4, min) {
		display: table;
		margin-bottom: -1.8em;
	}
	
	@include bp($point_4 - 1) {
		display: block;
	}

}

.cookies_manage_row {
	
	@include bp($point_4, min) {
		display: table-row;
	}

	@include bp($point_4 - 1) {
		margin-bottom: 2em;

		&:last-of-type {
			margin-bottom: 0;
		}
		
	}

}

.cookies_manage_list_item {
	
	@include bp($point_4, min) {
		display: table-cell;
		padding-right: 1em;
		padding-bottom: 1.8em;
	}
	
	@include bp($point_4 - 1) {
		margin-bottom: 1em;

		&:last-of-type {
			margin-bottom: 0;
		}
		
	}
	
	
}

.cookies_manage_list_title {
	color: #4d4d4d;
	font-size: 1.8rem;
	font-weight: bold;
	letter-spacing: .04rem;
}

a.cookies_manage_list_title {
	color: $blue;
	border-bottom: 1px solid transparent;

	&:hover,
	&:focus {
		text-decoration: none;
		border-color: $blue;
	}

}

//---------------------------------------------cookies_manage###

//---------------------------------------------switch_btn
.btn_switch {
	display: inline-flex;
	align-items: center;
	justify-content: center;

	height: 2.8em;
	cursor: pointer;
}

.btn_switch_title {
	color: #4574af;
	font-size: 1.8em;
	font-weight: bold;
	text-transform: uppercase;
}

.btn_switch_in {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 7.6em;
	height: 2.8em;

	border-radius: 1.4em;
	border: 2px solid #dedede;
	background-color: #f8f9ff;
	pointer-events: none;

	&.offset_mod {
		margin-right: 1em;
	}

}

.switch {
  position: relative;
  display: inline-block;
  width: 3em;
	height: 1.6em;
	
	line-height: 1.6;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.switch input:checked + .slider {
	background-color: rgba(#1976d2, .38);

	&:before {
		transform: translateX(1.4em);
		opacity: 1;
		background-color: #4286f5;
	}
}

.slider {
  position: absolute;
  top: 50%;
	left: 0;
	
	width: 100%;
	height: .8em;
	margin-top: -.4em;

	transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
							background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	border-radius: 1em;
	background-color: rgba($black, .38);

	// .active_state & {
	// 	background-color: rgba(#1976d2, .38);
	// }

	&:before {
		position: absolute;
		top: 50%;
		left: 0;

		width: 1.6em;
		height: 1.6em;
		margin-top: -.8em;

		box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
		border-radius: 50%;
		background-color: #fafafa;
		transition: all .3s ease;

		content: "";

		// .active_state & {
		// 	transform: translateX(1.4em);
		// 	opacity: 1;
		// 	background-color: #4286f5;
		// }
	}

}
// .switch_input:checked + .slider {
// 	background-color: rgba(#1976d2, .38);
// }

// .switch_input:focus + .slider {
//   box-shadow: 0 0 1px #1976d2;
// }

// .switch_input:checked + .slider:before {
// 	transform: translateX(1.4em);
// 	opacity: 1;
// 	background-color: #4286f5;
// }
//---------------------------------------------switch_btn###

