.popup {
	@extend %global_flex_block_row_nowrap_center_center;

	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 2em;

	z-index: 1000;

	font-size: 1rem;

	pointer-events: none;
	opacity: 0;

	transition: opacity .3s;

	&.active_mod {
		opacity: 1;
		pointer-events: initial;
	}
}

.overlay_popup {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: $black;
	opacity: .6;
	z-index: -1;
}

.popup_in {
	position: relative;
	width: 54.2em;
	max-width: 100%;
	max-height: 100%;
	overflow-y: auto;

	background-color: $white;
	color: #1e1e1e;
}

.popup_top {
	padding: 4.2em 5.8em 5.6em;

	border-bottom: 2px solid $dark_blue;

	@include bp($point_4 - 1) {
		padding: 2em;
	}
}

.popup_title {
	margin-bottom: (1em/5.6);

	font-size: 4.6em;
	font-weight: 300;
	font-style: italic;


	@include bp($point_4 - 1) {
		font-size: 3.2em;
	}
}

.popup_text {
	font-size: 1.6em;
	line-height: 2;

	@include bp($point_4 - 1) {
		font-size: 1.6em;
	}
}

.popup_descr {
	margin-bottom: 1.4em;

	font-size: 1.6em;

	@include bp($point_4 - 1) {
		font-size: 1.4em;
	}
}

.popup_bottom {
	padding: 2.2em 2em 2.8em;
	background-color: #eff3f6;
	text-align: center;

	padding: 2em;
}

.popup_close {
	position: absolute;
	top: 2.4em;
	right: 2.4em;

	width: 1.4em;
	height: 1.4em;
	color: $dark_blue;

	@include bp($point_4 - 1) {

	}
}

.close_popup_img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center;
}

.movie_popup {
    display: none;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    overflow: auto;
    z-index: 1001;
    text-align: center;
    background-color: rgba(0,0,0,0.4);

	&.visible {
		display: block;
	}
}

.movie_popupB {
    display: none;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    overflow: auto;
    z-index: 1001;
    text-align: center;
    background-color: rgba(0,0,0,0.4);

	&.visible {
		display: block;
	}
}

.movie_container {
	position: relative;
	//padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	
	@include bp($point_2) {
		padding-bottom: 56.25%; /* 16:9 */
	}
}

.movie_containerB {
	position: relative;
	//padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;

	@include bp($point_2) {
		padding-bottom: 56.25%; /* 16:9 */
	}
}

.popup_iframe {
	background-color: #FFFFFF;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	vertical-align: middle;
	//max-width: 50vw;
	//max-height: 44vh;
	width: 50em;
	height: 28em;
	margin: 15% auto 0;

	@include bp(2000px, min) {
		margin: 5% auto 0;
	}
	@include bp($point_2) {
		margin: 40% auto 0;
		width: 100vw;
		height: 90%;
	}
	@include bp($point_6) {
		margin: 60% auto 0;
	}
}

.popup_iframeB {
	background-color: #FFFFFF;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	vertical-align: middle;
	//max-width: 50vw;
	//max-height: 44vh;
	width: 50em;
	height: 28em;
	margin: 15% auto 0;
	overflow: hidden;

	@include bp($point_2) {
		margin: 40% auto 0;
		width: 100vw;
		height: 90%;
	}
	@include bp($point_6) {
		margin: 60% auto 0;
	}
}

.popup_close_movie {
	position: absolute;
	cursor: pointer;
	width: 1.5em;
	height: auto;
	right: 5em;
	top: 5em;
	z-index: 1002;
	background: no-repeat top center;

	@include bp($point_4) {
		right: 1em;
		top: 1em;
	}
}