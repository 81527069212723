.section_bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $white;
	z-index: -1;

	@include bp($point_3) {
		&.banner_mod {
			&::before {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;

				content: '';

				opacity: 0.5;
				background-color: $white;

				z-index: 1;
			}
		}

		.banner_v2 & {
			&:before {
				display: none;
			}
		}
	}
}

.section_bg_img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

// ------- buttons

.btn_base {
	@extend %btn_size_1;
	@extend %blue_btn_1;
	&.flex_mod {
		@extend %global_flex_block_row_nowrap_center_center;
	}
}

.btn_v2_base {
	@extend %btn_size_4;
	@extend %blue_btn_2;
	&.offset_mod {
		margin-top: (3em/1.6);
	}
}

.btn_video {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: center;
	@extend %btn_size_4;
	@extend %blue_btn_3;
	&.offset_mod {
		margin-top: (1em/1.6);
	}
	&.remove_italic {
		font-style: normal;
	}
}

.btn_video_white {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: center;
	@extend %btn_size_4;
	@extend %transparent_btn_1;
	&.offset_mod {
		margin-top: (1em/1.6);
	}
}

.btn_img {
	width: 1.5em;
	height: 1.5em;
	margin-right: 8px;
	&.filter_mod {
		filter: invert(44%) sepia(6%) saturate(4835%) hue-rotate(175deg) brightness(91%) contrast(79%);
  }
}

.btn_plan {
	@extend %btn_size_7;
	@extend %blue_btn_1;
}

.btn_plan_popup {
	@extend %transparent_btn_1;
	@extend %btn_size_8;
	background-color: #e5eaee;
	cursor: pointer;

	&.top {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
	&.middle {
		border-top: none;
		border-radius: 0;
	}
	&.bottom {
		border-top: none;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}

.btn_lg {
	@extend %btn_size_3;
	@extend %blue_btn_2;
	&.center_mod {
		margin: 0 auto;
	}
}

.btn_form {
	@extend %btn_size_2;
	@extend %blue_btn_1;
}

.btn_sanofi_blue {
	@extend %btn_size_11;
	@extend %blue_btn_1;
}

.btn_sanofi_white {
	@extend %btn_size_4;
	@extend %transparent_btn_1;
}

.btn_info_entry {
	@extend %btn_size_1;
	@extend %blue_btn_2;

	&.signup_mod{
		margin-left: 1em;
	}

}

.btn_v2 {
	@extend %transparent_btn_1;
	@extend %btn_size_5;

	&.offset_top_mod {
		margin-top: .6em;

		@include bp($point_1) {
			margin-top: 1.2em;
		}
	}
}

.btn_v3 {
	@extend %transparent_btn_2;
	@extend %btn_size_6;

	& + & {
		margin-left: 3.2rem;

		@include bp($point_6) {
			margin-left: 0;
			margin-top: 1em;
		}
	}
}

$fns: 1.3;

.btn_watch {
	position: relative;

	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding-left: (4.8em/$fns);
	height: (3.6em/$fns);

	color: $accent_color;
	font-size: $fns + em;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: .12rem;

	opacity: 1;
	transition: opacity .3s;
	cursor: pointer;

	&:before {
		position: absolute;
		top: 50%;
		left: 0;

		width: (3.6em/$fns);
		height: (3.6em/$fns);
		margin-top: -(1.8em/$fns);

		background: url('../images/play_icon.png') no-repeat center center;
		background-size: contain;

		content: '';
	}

	&.lg_mod {
		margin-top: 2rem;
		width: 6.2rem;
		height: 6.2rem;
		padding: 0;
		&:before {
			width: 6.2rem;
			height: 6.2rem;
			margin-top: -3.1rem;
			background: url('../images/play_white.png') no-repeat center center;
			background-size: contain;
		}
	}

	&.no_title_mod {
		width: (3.8em/$fns);
		height: (3.8em/$fns);
		padding-left: 0;

		&:before {
			width: 100%;
			height: 100%;
		}
	}

	&:hover, &:focus {
		opacity: .8;
	}

}

//---------------------------------------------video_section
.video_preview {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	z-index: -1;

	@include bp($point_3) {

		&:before {
			position: absolute;
			top: 0;
			left: 0;

			width: 100%;
			height: 100%;

			background-color: rgba($white, .7);

			content: '';
			z-index: 1;
		}

	}

}

.video_preview_in {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: cover;
	object-position: 0% 50%;
}

.video_preview_image {
	width: 45em;
	margin: 0 2em;
	display: block;
	max-width: 100vw;

	&.width_mod {
		width: 42%;
		@include bp($point_6) {
			width: 80%;
		}
		@include bp($point_7) {
			width: 90%;
		}
	}
}

.video_block_container {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: space-between;
	color: white;
	@include bp($point_4) {
		flex-direction: column;
		text-align: center;
	}
}

.video_info_block {
	position: relative;

	max-width: 51.4em;

	@include bp($point_2, min) {
		margin-left: 600px;
	}

	&.center_mod {

		@include bp($point_2, min) {
			text-align: center;
			margin: 0 auto;
		}

	}

	@include bp($point_3) {
		margin: 0 auto;
	}

	&.left_level_mod {
		margin-left: 0;
	}

}

.video_wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 1;
	transition: opacity 3s;
	z-index: 10;
	&.opacity_mod {
		opacity: 0;
	}
}

.video_player {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
//---------------------------------------------video_section###


.popup_video_wrap {
	@extend %global_flex_block_row_nowrap_center_center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 4em;

	font-size: 1rem;

	z-index: 1000;

	opacity: 0;
	pointer-events: none;

	transition: opacity .3s;

	body.popup_mod & {
		opacity: 1;
		pointer-events: initial;
	}

	&.hide {
		display: none;
	}
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($black, .6);
	z-index: -1;
}

.popup_video {
	position: relative;
	background-color: $white;
	width: 112.8em;
	max-width: 100%;
	// &:after {
	// 	content: '';
	// 	display: block;
	// 	padding-bottom: 56.6%;
	// }
}

.popup_video_close {
	position: absolute;
	right: 0;
	bottom: calc(100% + 1em);

	width: 2em;
	height: 2em;
	background: url('../images/close.svg') no-repeat center center;
	background-size: contain;

	z-index: 2;

	&:hover, &:focus {
		opacity: .8;
	}
}

.bgImg {
	@extend %transition_opacity;

	opacity: 0;

	&.loading,
	&.loaded {
		opacity: 1;
	}
}


// cookies

.cookies_block {
	position: fixed;
	bottom: 0;
	left: 0;

	width: 100%;
	padding: 2em;


	font-size: 1rem;
	color: $white;
	text-align: center;

	opacity: 1;
	transform: translateY(0);

	transition: all .3s;

	z-index: 1003;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		background-color: #0071be;
		z-index: -1;
		opacity: .8;
	}

	&.hidden_mod {
		opacity: 0;
		transform: translateY(100%);
	}
}

.cookies_block_in {
	max-width: 104.8em;
	margin: 0 auto;
}

.cookies_text {
	font-size: 1.4em;
	line-height: (2.3/1.4);
}

.cookies_descr {
	margin-top: (.2em/1.4);

	font-size: 1.4em;
	color: #b9d4fe;
}

.cookies_btns {
	@extend %global_flex_block_row_wrap_center;
	margin-top: 1.4em;

	@include bp($point_6) {
		flex-direction: column;
		flex-wrap: nowrap;
		align-items: center;
	}
}
