.clinical_studies {
  margin-bottom: 3em;
}

.clinical_studies_in {

  @include bp($point_2, min) {
		@include flex_block(row, wrap, flex-start, center);
  }

}

.clinical_studies_heading {
  max-width: 68.4em;

	@include bp($point_3) {
		max-width: none;
	}
}

.clinical_studies_logos {
  @extend %global_flex_block_row_wrap_flex-end_center;

  max-width: 35em;
  width: 100%;

  @include bp($point_2, min) {
    margin-left: auto;
  }

  @include bp($point_3) {
    flex-wrap: nowrap;
    margin: 3em auto 0;
  }

}

.clinical_studies_w_img {
  max-width: 12.6em;
  max-height: 4.6em;
  margin-right: 3.6em;

  &:last-of-type {
    margin-right: 0;
  }

}

.clinical_studies_img {
  max-width: 100%;
  display: block;
  height: auto;
  object-fit: contain;
}
